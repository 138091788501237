/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { isNone } from '@ember/utils';
import { A } from '@ember/array';
import { get } from '@ember/object';
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';
import BrowserLocales from 'embercom/lib/browser-locales';
import pluralise from 'embercom/lib/inflector';
import { IOS_DEVICES, IOS_VERSIONS } from 'predicates/models/data/ios';
import { CONVERSATION_RATINGS } from 'predicates/models/data/conversation-ratings';
import { getApp, getEmberDataStore } from 'embercom/lib/container-lookup';
import Admin from 'embercom/models/admin';
import { captureException } from 'embercom/lib/sentry';
import toSentence from 'embercom/lib/to-sentence';
import { ConversationState, ConversationStarter } from 'embercom/objects/inbox/conversation';
import { getIntlService } from 'embercom/lib/container-lookup';
import { CHANNEL_NAME_TRANSLATION_KEYS } from 'embercom/models/data/inbox/channels';

const LABELS_BY_TYPE = {
  ios_version: IOS_VERSIONS,
  ios_device: IOS_DEVICES,
  last_conversation_rating_given: CONVERSATION_RATINGS,
};

export const USER_TYPES = {
  user_role: 'User',
  contact_role: 'Lead',
  visitor_role: 'Visitor',
};

export default {
  getPredicateDescription(predicate, customFilterMap, showNoEventPrefix) {
    try {
      let eventPrefix = showNoEventPrefix ? '' : this.getUserEventPrefix(predicate);
      return `${eventPrefix + this.getComparator(predicate, customFilterMap)} ${this.getValue(
        predicate,
        customFilterMap,
      )}`.trim();
    } catch (e) {
      captureException(e, {
        fingerprint: ['filters-to-text', 'getPredicateDescription'],
        extra: {
          predicate,
        },
      });
      return '-';
    }
  },

  isAnIncreaseDecrease(predicate) {
    if (
      get(predicate, 'comparison') === 'increase' ||
      get(predicate, 'comparison') === 'decrease'
    ) {
      return true;
    } else {
      return false;
    }
  },

  isADate(predicate) {
    if (
      get(predicate, 'type') === 'date' ||
      get(predicate, 'type') === 'user_event_date' ||
      get(predicate, 'attribute') === 'last_request_at'
    ) {
      return true;
    } else {
      return false;
    }
  },

  isAbsoluteDate(predicate) {
    if (get(predicate, 'type') === 'date' || get(predicate, 'type') === 'user_event_date') {
      return typeof get(predicate, 'value') === 'object';
    }
    return false;
  },

  getComparator(predicate, customFilterMap) {
    let filterMap = this.getFilterMap(customFilterMap);
    if (this.isADate(predicate)) {
      let date_type = this.isAbsoluteDate(predicate) ? 'absolute' : 'relative';
      let filterMapDateReference =
        filterMap.date !== undefined ? filterMap.date : filterMap.user_event_date;
      let value = filterMapDateReference.comparison.findBy(
        'value',
        `${get(predicate, 'comparison')}:${date_type}`,
      );
      if (value === undefined) {
        value = filterMapDateReference.comparison.findBy('value', get(predicate, 'comparison'));
      }
      return value.label;
    } else if (this._isVisitorRolePredicate(predicate)) {
      return 'is';
    } else if (
      get(predicate, 'type') === 'office_hours_schedule' ||
      get(predicate, 'type') === 'rules_filters_apply_conversation_sla' ||
      get(predicate, 'type') === 'rules_filters_mark_priority'
    ) {
      return '';
    } else if (get(predicate, 'comparison') === 'in') {
      return 'is';
    } else if (get(predicate, 'comparison') === 'nin') {
      return 'is not';
    } else {
      let options = filterMap[get(predicate, 'type')];
      if (options) {
        return options.comparison.findBy('value', get(predicate, 'comparison')).label;
      } else {
        let comparisonMap = {
          gt: 'greater than',
          lt: 'less than',
          eq: 'is',
          ne: 'is not',
          known: 'has any value',
          unknown: 'is unknown',
        };
        if (comparisonMap.hasOwnProperty(get(predicate, 'comparison'))) {
          return comparisonMap[get(predicate, 'comparison')];
        }
        return get(predicate, 'comparison'); //TODO: GJ: better support for support events
      }
    }
  },

  getUserEventPrefix(predicate) {
    if (get(predicate, 'attribute').indexOf('user_event') === 0) {
      if (/\.count$/.test(get(predicate, 'attribute'))) {
        return 'count ';
      } else if (get(predicate, 'attribute').endsWith('.first')) {
        return 'first occurred ';
      } else {
        return 'last occurred ';
      }
    } else {
      return '';
    }
  },

  labelAsValueTypes: A(['ios_device', 'ios_version', 'last_conversation_rating_given']),

  predicateHasCustomFilterToTextValue(filterMap, predicate) {
    return !isNone(this.getPredicateCustomFilterToTextValue(filterMap, predicate));
  },

  getPredicateCustomFilterToTextValue(filterMap, predicate) {
    return get(filterMap, `${get(predicate, 'type')}.value`);
  },

  getValue(predicate, customFilterMap) {
    let filterMap = this.getFilterMap(customFilterMap);
    if (get(predicate, 'comparison') === 'unknown' || get(predicate, 'comparison') === 'known') {
      return '';
    } else if (this.isADate(predicate) && !this.isAnIncreaseDecrease(predicate)) {
      if (this.isAbsoluteDate(predicate)) {
        let filterMapDateReference =
          filterMap.date !== undefined ? filterMap.date : filterMap.user_event_date;
        return `${
          filterMapDateReference.months.findBy('value', parseInt(get(predicate, 'value').month, 10))
            .label
        } ${get(predicate, 'value').day}, ${get(predicate, 'value').year}`;
      } else {
        return parseInt(get(predicate, 'value'), 10) === 1
          ? `${get(predicate, 'value')} day ago`
          : `${get(predicate, 'value')} days ago`;
      }
    } else if (this._isVisitorRolePredicate(predicate)) {
      return 'Visitor';
    } else if (get(predicate, 'type') === 'anonymous') {
      return get(predicate, 'comparison') === 'true' ? 'Lead' : 'User';
    } else if (get(predicate, 'type') === 'role') {
      let roleValue = get(predicate, 'value');
      if (get(predicate, 'comparison') === 'in') {
        let roleLabels = roleValue.map((value) => {
          return USER_TYPES[value] || roleValue;
        });
        return toSentence(roleLabels, 'or');
      } else {
        return USER_TYPES[roleValue] || roleValue;
      }
    } else if (this.labelAsValueTypes.includes(get(predicate, 'type'))) {
      return LABELS_BY_TYPE[get(predicate, 'type')].findBy('value', get(predicate, 'value')).label;
    } else if (
      get(predicate, 'type') === 'manual_tag' ||
      get(predicate, 'type') === 'optional_manual_tag' ||
      get(predicate, 'type') === 'rules_filters_manual_tag'
    ) {
      let tag = getEmberDataStore().peekRecord('tag', predicate.value);
      return tag ? tag.name : '-';
    } else if (get(predicate, 'type') === 'subscription_type') {
      let subscriptionType = getEmberDataStore().peekRecord(
        'outbound-subscriptions/subscription-type',
        predicate.value,
      );
      return subscriptionType?.translationForTeammate?.name || '-';
    } else if (
      get(predicate, 'type') === 'owner_id' ||
      get(predicate, 'type') === 'rules_filters_assign_to' ||
      get(predicate, 'type') === 'team_assignee_id' ||
      get(predicate, 'type') === 'admin_assignee_id' ||
      get(predicate, 'type') === 'created_by_id' ||
      get(predicate, 'type') === 'team_capacity' ||
      get(predicate, 'type') === 'admin_id'
    ) {
      let admin = Admin.peekAndMaybeLoad(getEmberDataStore(), get(predicate, 'value'));
      if (getApp().canAssignToTeamAndTeammate && admin.isUnassignedAdmin) {
        return 'None';
      } else {
        return admin.get('name');
      }
    } else if (get(predicate, 'type') === 'account_id') {
      return getEmberDataStore().peekRecord('account', get(predicate, 'value')).get('name');
    } else if (get(predicate, 'type') === 'url_visit') {
      return get(predicate, 'value.count');
    } else if (get(predicate, 'type') === 'plan') {
      return getApp().plans.findBy('id', get(predicate, 'value')).name;
    } else if (get(predicate, 'type') === 'tag' || get(predicate, 'type') === 'company_tag') {
      return getApp().segments.findBy('id', get(predicate, 'value')).name;
    } else if (
      get(predicate, 'comparison') === 'increase' ||
      get(predicate, 'comparison') === 'decrease'
    ) {
      return '';
    } else if (
      get(predicate, 'type') === 'boolean' ||
      get(predicate, 'value') === undefined ||
      get(predicate, 'value') === null
    ) {
      return '';
    } else if (get(predicate, 'type') === 'browser_locale') {
      return BrowserLocales[get(predicate, 'value')];
    } else if (get(predicate, 'type') === 'duration_integer') {
      return `${get(predicate, 'value')} ${pluralise('second', get(predicate, 'value'))}`;
    } else if (get(predicate, 'type') === 'office_hours_schedule') {
      let scheduleId = get(predicate, 'value');
      // we assume that the Ember data store has already loaded the relevant office hours schedules.
      let schedules = getEmberDataStore().peekAll('office-hours-schedule').toArray();
      let schedule = schedules.findBy('id', scheduleId);
      if (schedule) {
        return `${schedule.name} office hours`;
      }
      return '-';
    } else if (get(predicate, 'type') === 'rules_filters_apply_conversation_sla') {
      let slaId = get(predicate, 'value');
      let slas = getEmberDataStore().peekAll('inbox/conversation-sla').toArray();
      let sla = slas.findBy('id', slaId);
      if (sla) {
        return sla.name;
      }
      return '-';
    } else if (
      ['conversation_attribute_list', 'attribute_descriptor_list'].includes(get(predicate, 'type'))
    ) {
      let attributeListOption = getEmberDataStore().peekRecord(
        'conversation-attributes/list-option',
        get(predicate, 'value'),
      );
      return attributeListOption.label;
    } else if (get(predicate, 'type') === 'ticket_type_id') {
      // Assume types have already been loaded for the predicate selector
      let ticketTypes = getEmberDataStore().peekAll('inbox/ticket-type').toArray();
      let ticketTypeId = get(predicate, 'value');
      let ticketType = ticketTypes.findBy('id', ticketTypeId);
      return ticketType ? ticketType.name : '-';
    } else if (get(predicate, 'type') === 'custom_state_id') {
      let item = get(predicate, 'options')
        .flatMap((group) => group.items)
        .find((item) => item.value === get(predicate, 'value'));
      return item ? item.text : '-';
    } else if (get(predicate, 'type') === 'whatsapp_integration_id') {
      // we assume that the Ember data store has already loaded the whatsapp integrations.
      let whatsappIntegration = getEmberDataStore().peekRecord(
        'whatsapp/integration',
        get(predicate, 'value'),
      );
      return whatsappIntegration ? whatsappIntegration.displayString : '-';
    } else if (get(predicate, 'type') === 'instagram_integration_id') {
      // we assume that the Ember data store has already loaded the integrations.
      let instagramIntegration = getEmberDataStore().peekRecord(
        'instagram/integration',
        get(predicate, 'value'),
      );
      return instagramIntegration ? instagramIntegration.displayString : '-';
    } else if (this.predicateHasCustomFilterToTextValue(filterMap, predicate)) {
      return this.getPredicateCustomFilterToTextValue(filterMap, predicate);
    } else if (get(predicate, 'type') === 'ticket_state') {
      let value = get(predicate, 'value');
      switch (value) {
        case 'submitted':
          return 'Submitted';
        case 'in_progress':
          return 'In Progress';
        case 'waiting_on_customer':
          return 'Waiting on customer';
        case 'resolved':
          return 'Resolved';
      }
    } else if (get(predicate, 'type') === 'conversation_state') {
      let value = get(predicate, 'value');
      return Object.keys(ConversationState).find(
        (key) => value === `state_${ConversationState[key]}`,
      );
    } else if (get(predicate, 'type') === 'conversation_started_by') {
      switch (get(predicate, 'value')) {
        case ConversationStarter.User:
          return 'Customer message';
        case ConversationStarter.AdminDirectMessageToOne:
          return 'Teammate sending direct message';
        case ConversationStarter.CustomBot:
          return 'Workflow';
        case ConversationStarter.AdminDirectMessageToMany:
          return 'Teammate sending to many customers';
        case ConversationStarter.OtherOutbound:
          return 'Outbound message';
      }
    } else if (get(predicate, 'type') === 'conversation_topic') {
      let topics = getEmberDataStore()
        .peekAll('conversational-insights/conversation-topic')
        .toArray();
      let topic = topics.findBy('id', get(predicate, 'value'));
      return topic ? topic.name : '-';
    } else if (get(predicate, 'type') === 'sentiment') {
      switch (get(predicate, 'value')) {
        case 'positive':
          return 'Positive';
        case 'neutral':
          return 'Neutral';
        case 'negative':
          return 'Negative';
      }
    } else if (get(predicate, 'type') === 'channel') {
      let intl = getIntlService();
      let channelId = get(predicate, 'value');
      return intl.t(CHANNEL_NAME_TRANSLATION_KEYS[channelId]);
    } else if (get(predicate, 'type') === 'brand_name') {
      let brands = getEmberDataStore()
        .peekAll('messenger-settings/look-and-feel')
        .firstObject.brands.toArray();
      let brand = brands.findBy('brandName', get(predicate, 'value'));
      return brand ? brand.brandName : '-';
    } else {
      return get(predicate, 'value');
    }
  },

  getFilterMap(customFilterMap) {
    return customFilterMap || PredicateFilterMap;
  },

  _isVisitorRolePredicate(predicate) {
    return (
      get(predicate, 'type') === 'role' &&
      get(predicate, 'comparison') === 'ne' &&
      get(predicate, 'value') === 'user_role'
    );
  },
};
