/* RESPONSIBLE TEAM: team-standalone */

import Helper from '@ember/component/helper';
import type { ZendeskFieldType } from 'embercom/services/fin-standalone-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

const ICONS: Record<ZendeskFieldType, InterfaceIconName> = {
  text: 'small-text',
  textarea: 'small-text',
  checkbox: 'boolean',
  date: 'calendar',
  integer: 'numbers',
  decimal: 'decimal',
  regexp: 'small-text',
  dropdown: 'list',
  multiselect: 'list',
  lookup: 'link',
  tagger: 'list',
};

export default class ZendeskDataIcon extends Helper<{
  Args: {
    Positional: [ZendeskFieldType];
  };
  Return: InterfaceIconName;
}> {
  compute([fieldType]: [ZendeskFieldType]) {
    return ICONS[fieldType];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'standalone/zendesk-data-icon': typeof ZendeskDataIcon;
  }
}
