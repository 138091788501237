/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { deliveryChannels } from 'embercom/models/data/outbound/constants';

const CUSTOM_BOT_REQUIRED_PERMISSION = 'can_create_and_edit_bots';
const OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION = 'can_manage_outbound_custom_bots';

const USER_TARGET = 'user';
const NON_USER_TARGET = 'non-user';
const EVERYONE_TARGET = 'everyone';
const NEW_CONVERSATION_TARGET = 'new-conversation';
const OUTBOUND_TARGET = 'outbound';
const NO_TRIGGER_TARGET = 'no-trigger';
const OUTBOUND_BOT_TYPE = 'Outbound';
const BUTTON_BOT_TYPE = 'Button';
const INBOUND_BOT_TYPE = 'Inbound';
const TRIGGERABLE_BOT_TYPE = 'Triggerable';
const CONVERSATION_STARTED_TRIGGER = 'conversation_started';
const CONVERSATION_STARTED_TARGET = 'conversation-started';
const NEW_PHONE_CALL_TRIGGER = 'new_phone_call';
const NEW_PHONE_CALL_TARGET = 'new-phone-call';
const NEW_COMMENT_TRIGGER = 'new_comment';
const NEW_COMMENT_TARGET = 'new-comment';
const NEW_ADMIN_COMMENT_TRIGGER = 'new_admin_comment';
const NEW_ADMIN_COMMENT_TARGET = 'new-admin-comment';
const CONVERSATION_STATUS_CHANGED_TRIGGER = 'conversation_status_changed';
const CONVERSATION_STATUS_CHANGED_TARGET = 'conversation-status-changed';
const ADMIN_NOTE_CREATED_TRIGGER = 'admin_note_created';
const ADMIN_NOTE_CREATED_TARGET = 'admin-note-created';
const CONVERSATION_ATTRIBUTE_CHANGED_TRIGGER = 'conversation_attribute_changed';
const CONVERSATION_ATTRIBUTE_CHANGED_TARGET = 'conversation-attribute-changed';
const CONVERSATION_ASSIGNMENT_CHANGED_TRIGGER = 'conversation_assignment_changed';
const CONVERSATION_ASSIGNMENT_CHANGED_TARGET = 'conversation-assignment-changed';
const USER_INACTIVITY_TRIGGER = 'user_inactivity';
const USER_INACTIVITY_TARGET = 'user-inactivity';
const ADMIN_INACTIVITY_TRIGGER = 'admin_inactivity';
const ADMIN_INACTIVITY_TARGET = 'admin-inactivity';
const TICKET_CREATED_TRIGGER = 'ticket_created';
const TICKET_CREATED_TARGET = 'ticket-created';
const TICKET_STATE_UPDATED_TRIGGER = 'ticket_state_updated';
const TICKET_STATE_UPDATED_TARGET = 'ticket-state-updated';
const WEBHOOK_RECEIVED_TRIGGER = 'webhook_received';
const WEBHOOK_RECEIVED_TARGET = 'webhook-received';
const AI_AGENT_WORKFLOW_TRIGGER = 'ai_agent_workflow';
const AI_AGENT_WORKFLOW_TARGET = 'ai-agent-workflow';
const DURING_CONVERSATION = 'during_conversation' as const;
const AT_CONVERSATION_START = 'at_conversation_start' as const;
const FROM_ANOTHER_WORKFLOW = 'from_another_workflow' as const;
const TICKET_ONLY = 'ticket_only' as const;
const APP_TEMPLATE_WORKFLOWS_ID = 12 as const;
const NEW_ZENDESK_TICKET_TRIGGER = 'new_zendesk_ticket' as const;
const NEW_ZENDESK_TICKET_TARGET = 'new-zendesk-ticket' as const;

const TRIGGER_CONTEXT_TYPES = [
  AT_CONVERSATION_START,
  DURING_CONVERSATION,
  TICKET_ONLY,
  FROM_ANOTHER_WORKFLOW,
] as const;
const END_USER_EXPERIENCE = {
  id: 8,
  translationKey: 'end-user-experience',
  color: 'orange',
  showcaseIds: ['2012'],
};
const TEAMMATE_EFFICIENCY = {
  id: 9,
  translationKey: 'teammate-efficiency',
  color: 'blue',
  showcaseIds: ['2011', '2006'],
};
const PROACTIVE_SUPPORT = {
  id: 10,
  translationKey: 'proactive-support',
  color: 'mint',
};
const FIN_WORKFLOWS = {
  id: 11,
  translationKey: 'fin-workflows',
  color: 'fin',
};
const APP_TEMPLATE_WORKFLOWS = {
  id: APP_TEMPLATE_WORKFLOWS_ID,
  translationKey: 'app-template-workflows',
  color: 'blue',
};
const TEMPLATE_GROUPS = [
  FIN_WORKFLOWS,
  TEAMMATE_EFFICIENCY,
  END_USER_EXPERIENCE,
  PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS,
];

const NON_OMNICHANNEL_COMPATIBLE_BOT_TARGET = [
  USER_TARGET, // deprecated, not in use, outbound for users
  NON_USER_TARGET, // deprecated, not in use, outbound for non-users
  EVERYONE_TARGET, // button
  NEW_CONVERSATION_TARGET, // inbound
  OUTBOUND_TARGET, // outbound
];

const NON_USER_ROLE_PREDICATE = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['contact_role', 'visitor_role'],
};

const USER_ROLE_PREDICATE = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['user_role'],
};

const OUTBOUND_PREDICATE = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['contact_role', 'visitor_role'],
};

const PHONE_ROLE_PREDICATE = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['contact_role', 'user_role'],
};

const EVERYONE_PREDICATE = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['contact_role', 'visitor_role', 'user_role'],
};

const confirmOptions = {
  title: 'Close without saving?',
  primaryButtonType: 'primary-destructive',
  confirmButtonText: 'Close without saving',
  cancelButtonText: 'Keep editing',
  body: 'Your changes will be lost.',
};

const customBotDeliveryChannels = [
  {
    type: 'web',
    label: 'Web',
    icon: 'message-creation/web',
    preferredDevices: deliveryChannels.web,
  },
  {
    type: 'ios',
    label: 'iOS',
    icon: 'message-creation/ios',
    preferredDevices: deliveryChannels.ios,
  },
  {
    type: 'android',
    label: 'Android',
    icon: 'message-creation/android',
    preferredDevices: deliveryChannels.android,
  },
  {
    type: 'mobile_and_web',
    label: 'Wherever people open your app first',
    icon: 'message-creation/wherever',
    preferredDevices: deliveryChannels.mobileAndWeb,
  },
  {
    type: 'mobile',
    label: 'iOS and Android',
    icon: 'message-creation/mobile',
    preferredDevices: deliveryChannels.mobile,
  },
];

const OPERATOR_OUTBOUND_CUSTOM_BOT_EDITOR_ROUTE = `apps.app.automation.workflows.edit`;

const OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE = `apps.app.automation.workflows-overview`;

const STEP_TYPE_CHAT_MESSAGE = 'chat_message';
const STEP_TYPE_MESSENGER_APP = 'messenger_app';
const STEP_TYPE_TERMINAL = 'terminal';
const STEP_TYPE_FOLLOW_UP_RULES = 'follow_up_rules';
const STEP_TYPE_REPLY_BUTTONS = 'reply_buttons';
const STEP_TYPE_ATTRIBUTE_COLLECTOR = 'attribute_collector';
const STEP_TYPE_CUSTOM_OBJECT_SELECTOR = 'custom_object_selector';
const STEP_TYPE_FREE_INPUT = 'free_input';
const STEP_TYPE_MID_PATH_ACTIONS = 'mid_path_actions';
const STEP_TYPE_ANSWER_BOT = 'answer_bot';
const STEP_TYPE_CONDITIONAL_BRANCHES = 'conditional_branches';
const STEP_TYPE_WORKFLOW_CONNECTOR = 'workflow_connector';
const STEP_TYPE_TRIGGER_WORKFLOW = 'trigger_workflow';
const STEP_TYPE_SEND_TICKET = 'send_ticket';
const STEP_TYPE_START_OVER = 'start_over';
const STEP_TYPE_SET_EXPECTATIONS = 'set_expectations';

const CONTROL_TYPE_SUCCESS = 'success';
const CONTROL_TYPE_FOLLOW_UP = 'follow_up';
const CONTROL_TYPE_FALLBACK = 'fallback';
const CONTROL_TYPE_CONDITIONAL_BRANCH = 'conditional_branch';

const CHANNEL_ANDROID = 'android';
const CHANNEL_IOS = 'ios';
const CHANNEL_EMAIL = 'email';
const CHANNEL_FACEBOOK = 'facebook';
const CHANNEL_INSTAGRAM = 'instagram';
const CHANNEL_SMS = 'sms';
const CHANNEL_TWITTER = 'twitter';
const CHANNEL_WEB = 'web';
const CHANNEL_WHATSAPP = 'whatsapp';
const CHANNEL_PHONE_CALL = 'phone_call';
const CHANNEL_ZENDESK_TICKET = 'zendesk_ticket';

const ALL_CHANNELS = [
  CHANNEL_ANDROID,
  CHANNEL_EMAIL,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_IOS,
  CHANNEL_SMS,
  CHANNEL_TWITTER,
  CHANNEL_WEB,
  CHANNEL_WHATSAPP,
  CHANNEL_PHONE_CALL,
  CHANNEL_ZENDESK_TICKET,
] as const;

const PHONE_CHANNEL_ONLY = [CHANNEL_PHONE_CALL] as const;

const ALL_CHANNELS_EXCEPT_EMAIL = [
  CHANNEL_ANDROID,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_IOS,
  CHANNEL_SMS,
  CHANNEL_TWITTER,
  CHANNEL_WEB,
  CHANNEL_WHATSAPP,
  CHANNEL_PHONE_CALL,
  CHANNEL_ZENDESK_TICKET,
] as const;

const ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL = [
  CHANNEL_ANDROID,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_IOS,
  CHANNEL_SMS,
  CHANNEL_TWITTER,
  CHANNEL_WEB,
  CHANNEL_WHATSAPP,
  CHANNEL_ZENDESK_TICKET,
] as const;

const ALL_CHANNELS_EXCEPT_PHONE_CALL = [
  CHANNEL_ANDROID,
  CHANNEL_EMAIL,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_IOS,
  CHANNEL_SMS,
  CHANNEL_TWITTER,
  CHANNEL_WEB,
  CHANNEL_WHATSAPP,
  CHANNEL_ZENDESK_TICKET,
] as const;

const INTERCOM_CHANNELS = [CHANNEL_WEB, CHANNEL_IOS, CHANNEL_ANDROID] as const;

const SOCIAL_CHANNELS = [
  CHANNEL_WHATSAPP,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_TWITTER,
] as const;

const ONLY_PHONE_CALL_CHANNEL = [CHANNEL_PHONE_CALL] as const;

// In the backend we group SMS under the concept of "Social channels"
const OTHER_CHANNELS = [CHANNEL_SMS, CHANNEL_EMAIL] as const;

type AllChannels = (typeof ALL_CHANNELS)[number];

type AdditionalSearchableData = {
  targetChannels: AllChannels[] | null;
  parallel: boolean | null;
  hasFin: boolean | null;
};

const CHANNEL_GROUP_MAPPING = {
  intercom: INTERCOM_CHANNELS,
  social: SOCIAL_CHANNELS,
  other: OTHER_CHANNELS,
} as const;

const CHANNEL_METADATA_MAPPING = {
  facebook: {
    value: 'facebook',
    icon: 'facebook',
    appId: 'facebook',
    requiresAppStoreInstallation: true,
    channelTypeValue: 'Facebook',
  },
  instagram: {
    value: 'instagram',
    icon: 'instagram',
    appId: 'instagram-pv4',
    requiresAppStoreInstallation: true,
    channelTypeValue: 'Instagram',
  },
  phone_call: {
    value: 'phone_call',
    icon: 'phone',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'Phone Call',
  },
  twitter: {
    value: 'twitter',
    icon: 'twitter',
    appId: 'twitter',
    requiresAppStoreInstallation: true,
    channelTypeValue: 'Twitter',
  },
  whatsapp: {
    value: 'whatsapp',
    icon: 'whatsapp',
    appId: 'whatsapp-fqam',
    requiresAppStoreInstallation: true,
    channelTypeValue: 'WhatsApp',
  },
  sms: {
    value: 'sms',
    icon: 'sms',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'SMS',
  },
  android: {
    value: 'android',
    icon: 'android',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'Android App',
  },
  email: {
    value: 'email',
    icon: 'email-filled',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'Email',
  },
  ios: {
    value: 'ios',
    icon: 'apple',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'iOS App',
  },
  web: {
    value: 'web',
    icon: 'intercom',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'Desktop',
  },
  zendesk_ticket: {
    value: 'zendesk_ticket',
    icon: 'ticket',
    requiresAppStoreInstallation: false,
    channelTypeValue: 'Zendesk Ticket',
  },
} as const;

function allChannels(enableTwitterIntegrationBots: boolean) {
  if (enableTwitterIntegrationBots) {
    return ALL_CHANNELS;
  }
  return ALL_CHANNELS.filter((channel) => {
    return channel !== CHANNEL_TWITTER;
  });
}

function channelGroupMapping(enableTwitterIntegrationBots: boolean) {
  let mapping = JSON.parse(JSON.stringify(CHANNEL_GROUP_MAPPING)) as {
    -readonly [K in keyof typeof CHANNEL_GROUP_MAPPING]: (typeof CHANNEL_GROUP_MAPPING)[K][number][];
  };

  if (enableTwitterIntegrationBots) {
    return mapping;
  }

  mapping.social = SOCIAL_CHANNELS.filter((channel) => {
    return channel !== CHANNEL_TWITTER;
  });

  return mapping;
}

export {
  AllChannels,
  AdditionalSearchableData,
  CUSTOM_BOT_REQUIRED_PERMISSION,
  OUTBOUND_CUSTOM_BOT_REQUIRED_PERMISSION,
  NON_OMNICHANNEL_COMPATIBLE_BOT_TARGET,
  USER_TARGET,
  NON_USER_TARGET,
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  OUTBOUND_TARGET,
  NO_TRIGGER_TARGET,
  CONVERSATION_STARTED_TARGET,
  CONVERSATION_STARTED_TRIGGER,
  NEW_PHONE_CALL_TARGET,
  NEW_PHONE_CALL_TRIGGER,
  NEW_COMMENT_TARGET,
  ADMIN_NOTE_CREATED_TARGET,
  ADMIN_NOTE_CREATED_TRIGGER,
  NEW_COMMENT_TRIGGER,
  NEW_ADMIN_COMMENT_TARGET,
  NEW_ADMIN_COMMENT_TRIGGER,
  CONVERSATION_STATUS_CHANGED_TRIGGER,
  CONVERSATION_STATUS_CHANGED_TARGET,
  CONVERSATION_ATTRIBUTE_CHANGED_TRIGGER,
  CONVERSATION_ATTRIBUTE_CHANGED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TRIGGER,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  NEW_ZENDESK_TICKET_TRIGGER,
  NEW_ZENDESK_TICKET_TARGET,
  USER_INACTIVITY_TRIGGER,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TRIGGER,
  ADMIN_INACTIVITY_TARGET,
  TICKET_CREATED_TRIGGER,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TRIGGER,
  TICKET_STATE_UPDATED_TARGET,
  NON_USER_ROLE_PREDICATE,
  USER_ROLE_PREDICATE,
  OUTBOUND_PREDICATE,
  PHONE_ROLE_PREDICATE,
  EVERYONE_PREDICATE,
  OPERATOR_OUTBOUND_CUSTOM_BOT_EDITOR_ROUTE,
  OPERATOR_CUSTOM_BOT_OVERVIEW_ROUTE,
  confirmOptions,
  customBotDeliveryChannels,
  OUTBOUND_BOT_TYPE,
  BUTTON_BOT_TYPE,
  INBOUND_BOT_TYPE,
  TRIGGERABLE_BOT_TYPE,
  STEP_TYPE_CHAT_MESSAGE,
  STEP_TYPE_MESSENGER_APP,
  STEP_TYPE_TERMINAL,
  STEP_TYPE_FOLLOW_UP_RULES,
  STEP_TYPE_REPLY_BUTTONS,
  STEP_TYPE_ATTRIBUTE_COLLECTOR,
  STEP_TYPE_CUSTOM_OBJECT_SELECTOR,
  STEP_TYPE_FREE_INPUT,
  STEP_TYPE_MID_PATH_ACTIONS,
  STEP_TYPE_ANSWER_BOT,
  STEP_TYPE_CONDITIONAL_BRANCHES,
  STEP_TYPE_WORKFLOW_CONNECTOR,
  STEP_TYPE_TRIGGER_WORKFLOW,
  STEP_TYPE_SEND_TICKET,
  STEP_TYPE_START_OVER,
  STEP_TYPE_SET_EXPECTATIONS,
  CONTROL_TYPE_SUCCESS,
  CONTROL_TYPE_FOLLOW_UP,
  CONTROL_TYPE_FALLBACK,
  CONTROL_TYPE_CONDITIONAL_BRANCH,
  CHANNEL_ANDROID,
  CHANNEL_IOS,
  CHANNEL_EMAIL,
  CHANNEL_FACEBOOK,
  CHANNEL_INSTAGRAM,
  CHANNEL_SMS,
  CHANNEL_TWITTER,
  CHANNEL_WEB,
  CHANNEL_WHATSAPP,
  CHANNEL_PHONE_CALL,
  ALL_CHANNELS,
  ALL_CHANNELS_EXCEPT_EMAIL,
  ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  ALL_CHANNELS_EXCEPT_PHONE_CALL,
  PHONE_CHANNEL_ONLY,
  INTERCOM_CHANNELS,
  SOCIAL_CHANNELS,
  OTHER_CHANNELS,
  ONLY_PHONE_CALL_CHANNEL,
  CHANNEL_METADATA_MAPPING,
  CHANNEL_GROUP_MAPPING,
  AT_CONVERSATION_START,
  DURING_CONVERSATION,
  FROM_ANOTHER_WORKFLOW,
  TICKET_ONLY,
  TRIGGER_CONTEXT_TYPES,
  TEMPLATE_GROUPS,
  END_USER_EXPERIENCE,
  TEAMMATE_EFFICIENCY,
  PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS,
  FIN_WORKFLOWS,
  allChannels,
  channelGroupMapping,
  WEBHOOK_RECEIVED_TRIGGER,
  WEBHOOK_RECEIVED_TARGET,
  AI_AGENT_WORKFLOW_TRIGGER,
  AI_AGENT_WORKFLOW_TARGET,
  APP_TEMPLATE_WORKFLOWS_ID,
};
