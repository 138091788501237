/* RESPONSIBLE TEAM: team-app-security */

import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import formatTimezoneHelper from 'embercom/helpers/format-timezone';
import formatArrayToSentence from 'embercom/helpers/format-array-to-sentence';
import inflectorHelper from 'embercom/helpers/inflector-helper';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';
import Admin from 'embercom/models/admin';

export default class AppAdminEventsHelper {
  @service intl;
  @service appService;
  @service router;
  @service store;

  constructor(owner) {
    setOwner(this, owner);
  }

  get app() {
    return this.appService.app;
  }

  get inboxBaseUrl() {
    return this.router.urlFor(this.app.inboxBaseRoute, this.app);
  }

  // The names of content we get from Rails do not match exactly to the names of content we have
  // in the routes. Ideally we should return the entity enums and not the names from rails.
  // In the meantime, we can handle differing names by extending the list of editor routes here.
  get outboundRoutes() {
    return Object.assign({}, OUTBOUND_EDITOR_ROUTES, { sms_message: 'apps.app.outbound.sms.edit' });
  }

  outboundEditorUrl(event) {
    let contentType = event.value.content_type;
    if (contentType) {
      let route = this.outboundRoutes[contentType];

      if (route) {
        return this.router.urlFor(route, event.value.ruleset_id);
      } else {
        console.error(`No outbound editor route found for [${contentType}]`);
        return this.router.urlFor(`apps.app.outbound`);
      }
    }
  }

  defaultEventParams(event) {
    let teammateName = `${event.value?.admin_first_name} ${event.value?.admin_last_name}`;
    if (event.admin_id === null && event.admin_ip === null) {
      return {
        htmlSafe: true,
        adminHref: 'https://www.intercom.com/',
        eventAdminName: 'Intercom',
        href1: this.inboxBaseUrl,
        appName: this.app.name,
        eventValueName: event.value?.name,
        teammateName,
      };
    }
    return {
      htmlSafe: true,
      adminHref: `/a/apps/${this.app.id}/admins/${event.admin.id}`,
      eventAdminName: event.admin.name,
      href1: this.inboxBaseUrl,
      appName: this.app.name,
      eventValueName: event.value?.name,
      teammateName,
    };
  }

  reportAccessParams(event) {
    let accessAdmin = null;
    if (event.value.access_admin_id) {
      accessAdmin = Admin.peekAndMaybeLoad(this.store, event.value.access_admin_id.toString());
    }
    return {
      teammateName: event.value.access_admin_id ? accessAdmin.name : this.app.name,
      reportId: event.value.report_id,
      reportHref: `/a/apps/${this.app.id}/reports/custom-reports/report/${event.value.report_id}`,
    };
  }

  descriptionHTML(event) {
    if (event.isAdminDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.removed-teammate', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminProvisioned) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-provisioned', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminDeprovisioned) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-deprovisioned', {
        ...this.defaultEventParams(event),
        href: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_name: event.value.admin_name,
      });
    } else if (event.isBillingAddresseesChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.billing-addressees-change',
        this.defaultEventParams(event),
      );
    } else if (event.isBillingAddresseesBounced) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.bounced-email-address',
        this.defaultEventParams(event),
      );
    } else if (event.isRoleDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.role-deletion', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/${event.value.id}`,
        eventValueName: event.value.name,
      });
    } else if (event.isRoleCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.role-creation', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/${event.value.id}`,
        eventValueName: event.value.name,
      });
    } else if (event.isRoleChange) {
      return this.intl.t('table-cells.admin-events.description-cell.role-change', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/${event.value.static.role.id}`,
        eventValueAfterName: event.value.after.name,
      });
    } else if (event.isSeatAssigned && event.isSeatRevoked) {
      return this.intl.t('table-cells.admin-events.description-cell.seat-changed', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_first_name: event.value.admin_first_name,
        eventValueAdmin_last_name: event.value.admin_last_name,
      });
    } else if (event.isSeatAssigned) {
      return this.intl.t('table-cells.admin-events.description-cell.seat-assigned', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_first_name: event.value.admin_first_name,
        eventValueAdmin_last_name: event.value.admin_last_name,
        length: event.value.after.length - event.value.before.length,
        label: event.assignedSeatLabel,
      });
    } else if (event.isSeatRevoked) {
      return this.intl.t('table-cells.admin-events.description-cell.seat-revoked', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_first_name: event.value.admin_first_name,
        eventValueAdmin_last_name: event.value.admin_last_name,
        length: event.value.before.length - event.value.after.length,
        label: event.revokedSeatLabel,
      });
    } else if (event.isSeatChange) {
      return this.intl.t('table-cells.admin-events.description-cell.seat-revoked', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_first_name: event.value.admin_first_name,
        eventValueAdmin_last_name: event.value.admin_last_name,
      });
    } else if (event.isInboxAccessRevoked) {
      return this.intl.t('table-cells.admin-events.description-cell.inbox-access-revoked', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueStaticAdminName: event.value.static.admin_name,
      });
    } else if (event.isInboxAccessGranted) {
      return this.intl.t('table-cells.admin-events.description-cell.inbox-access-granted', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueStaticAdminName: event.value.static.admin_name,
      });
    } else if (event.isAdminInviteRoleChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-role-change', {
        ...this.defaultEventParams(event),
        href1: `mailto:${event.value.static.email}`,
        eventValueStaticEmail: event.value.static.email,
        href2: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.before.role.id}`,
        eventValueBeforeRoleName: event.value.before.role.name,
        href3: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.after.role.id}`,
        eventValueAfterRoleName: event.value.after.role.name,
      });
    } else if (event.isAdminInviteRoleAssign) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-role-assign', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.after.role.id}`,
        eventValueAfterRoleName: event.value.after.role.name,
        href2: `mailto:${event.value.static.email}`,
        eventValueStaticEmail: event.value.static.email,
      });
    } else if (event.isAdminInviteRoleRemove) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-role-remove', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.before.role.id}`,
        eventValueBeforeRoleName: event.value.before.role.name,
        href2: `mailto:${event.value.static.email}`,
        eventValueStaticEmail: event.value.static.email,
      });
    } else if (event.isAdminInviteChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-change', {
        ...this.defaultEventParams(event),
        href1: `mailto:${event.value.static.email}`,
        eventValueStaticEmail: event.value.static.email,
      });
    } else if (event.isAdminInviteCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-creation', {
        ...this.defaultEventParams(event),
        href1: `mailto:${event.value.email}`,
        eventValueEmail: event.value.email,
      });
    } else if (this.isAdminInviteDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-invite-deletion', {
        ...this.defaultEventParams(event),
        href1: `mailto:${event.value.email}`,
        eventValueEmail: event.value.email,
      });
    } else if (event.isAdminRoleChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-role-change', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.static.admin_id}`,
        eventValueStaticAdmin_first_name: event.value.static.admin_first_name,
        eventValueStaticAdmin_last_name: event.value.static.admin_last_name,
        href2: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.before.role.id}`,
        eventValueBeforeRoleName: event.value.before.role.name,
        href3: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.after.role.id}`,
        eventValueAfterRoleName: event.value.after.role.name,
      });
    } else if (event.isAdminRoleAssign) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-role-assign', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.after.role.id}`,
        eventValueAfterRoleName: event.value.after.role.name,
        href2: `/a/apps/${this.app.id}/admins/${event.value.static.admin_id}`,
        eventValueStaticAdmin_first_name: event.value.static.admin_first_name,
        eventValueStaticAdmin_last_name: event.value.static.admin_last_name,
      });
    } else if (event.isAdminRoleRemove) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-role-remove', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/settings/roles/edit/${event.value.before.role.id}`,
        eventValueBeforeRoleName: event.value.before.role.name,
        href2: `/a/apps/${this.app.id}/admins/${event.value.static.admin_id}`,
        eventValueStaticAdmin_first_name: event.value.static.admin_first_name,
        eventValueStaticAdmin_last_name: event.value.static.admin_last_name,
      });
    } else if (event.isAdminPermissionChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-permission-change', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.static.admin_id}`,
        eventValueStaticAdmin_first_name: event.value.static.admin_first_name,
        eventValueStaticAdmin_last_name: event.value.static.admin_last_name,
      });
    } else if (event.isAdminAwayModeChange) {
      if (event.value.update_by && event.admin_id !== event.value.update_by) {
        return this.intl.t(
          'table-cells.admin-events.description-cell.admin-away-mode-change-by-teammate',
          {
            ...this.defaultEventParams(event),
            updateByAdminHref: `/a/apps/${this.app.id}/admins/${event.value.update_by}`,
            updateByAdmin: event.value.update_by_name,
            eventValueAway_mode: event.value.away_mode,
            eventValueReassign_conversations: event.value.reassign_conversations,
          },
        );
      }

      return this.intl.t('table-cells.admin-events.description-cell.admin-away-mode-change', {
        ...this.defaultEventParams(event),
        eventValueAway_mode: event.value.away_mode,
        eventValueReassign_conversations: event.value.reassign_conversations,
      });
    } else if (event.isAdminAwayModeAutoChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-away-mode-auto-change', {
        ...this.defaultEventParams(event),
        eventValueAway_mode: event.value.away_mode,
        eventValueReassign_conversations: event.value.reassign_conversations,
      });
    } else if (event.isAppNameChange) {
      return this.intl.t('table-cells.admin-events.description-cell.app-name-change', {
        ...this.defaultEventParams(event),
        eventValueBefore: event.value.before,
        eventValueAfter: event.value.after,
      });
    } else if (event.isAppTimezoneChange) {
      return this.intl.t('table-cells.admin-events.description-cell.app-timezone-change', {
        ...this.defaultEventParams(event),
        eventValueBefore: formatTimezoneHelper.compute([event.value.before]),
        eventValueAfter: formatTimezoneHelper.compute([event.value.after]),
      });
    } else if (event.isBulkDataExportCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.bulk-data-export-creation', {
        ...this.defaultEventParams(event),
        eventValueBulkDataExport_bulk: event.value.bulk,
        eventValueBulkDataExport_schedule: event.value.schedule,
      });
    } else if (event.isBulkDataExportRun) {
      return this.intl.t('table-cells.admin-events.description-cell.bulk-data-export-run', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isBulkDelete) {
      return this.intl.t('table-cells.admin-events.description-cell.bulk-delete', {
        ...this.defaultEventParams(event),
        eventValueType: inflectorHelper.compute([event.value.type, event.value.count], {}),
        eventValueCount: event.value.count,
      });
    } else if (event.isBulkExport) {
      return this.intl.t('table-cells.admin-events.description-cell.bulk-export', {
        ...this.defaultEventParams(event),
        eventValueType: inflectorHelper.compute([event.value.type, event.value.count], {}),
        eventValueCount: event.value.count,
        columnSelection: event.value.with_all_attributes ? 'all' : 'some',
      });
    } else if (event.isMessageDeletion) {
      if (event.value.message_title) {
        return this.intl.t(
          'table-cells.admin-events.description-cell.message-deletion-with-title',
          {
            ...this.defaultEventParams(event),
            eventValueMessageTitle: event.value.message_title,
          },
        );
      } else {
        return this.intl.t('table-cells.admin-events.description-cell.message-deletion', {
          ...this.defaultEventParams(event),
        });
      }
    } else if (event.isRulesetActivation || event.isRulesetCreation || event.isRulesetDeletion) {
      let params = {
        ...this.defaultEventParams(event),
        rulesetHref: this.outboundEditorUrl(event),
        eventValueRuleset_id: event.value.ruleset_id,
        eventValueContent_type: event.value.content_type ? event.value.content_type : 'message',
        eventValueContent_preview: event.value.content_preview
          ? event.value.content_preview
          : 'no_preview',
      };
      let eventType = event.type.toLowerCase().split('::ruleset')[1];
      if (event.value.ruleset_title) {
        return this.intl.t(`table-cells.admin-events.description-cell.ruleset-${eventType}-title`, {
          ...params,
          eventValueRuleset_title: event.value.ruleset_title,
        });
      } else {
        return this.intl.t(`table-cells.admin-events.description-cell.ruleset-${eventType}`, {
          ...params,
        });
      }
    } else if (event.isAdminLoginSuccess) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.admin-login-success',
        this.defaultEventParams(event),
      );
    } else if (event.isAdminLoginFailure) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-login-failure', {
        ...this.defaultEventParams(event),
        eventValueTimes: event.value.times,
      });
    } else if (event.isAdminLogout) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.admin-logout',
        this.defaultEventParams(event),
      );
    } else if (event.isArticleCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.article-creation', {
        ...this.defaultEventParams(event),
        articleHref: this.router.urlFor(
          'apps.app.articles.articles.article.show',
          event.value.article_id,
        ),
        eventValueArticle_locale: event.value.locale,
      });
    } else if (event.isArticleDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.article-deletion', {
        ...this.defaultEventParams(event),
        eventValueArticle_title: event.value.title,
        eventValueArticle_locale: event.value.locale,
      });
    } else if (event.isArticleContentCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.article-content-creation', {
        ...this.defaultEventParams(event),
        articleHref: this.router.urlFor(
          'apps.app.articles.articles.article.show',
          event.value.article_id,
        ),
        eventValueArticle_locale: event.value.locale,
      });
    } else if (event.isArticleContentUpdate) {
      return this.intl.t('table-cells.admin-events.description-cell.article-content-update', {
        ...this.defaultEventParams(event),
        articleHref: this.router.urlFor(
          'apps.app.articles.articles.article.show',
          event.value.article_id,
        ),
        eventValueArticle_locale: event.value.locale,
        eventValueArticle_title: event.value.title,
      });
    } else if (event.isArticleContentPublished) {
      return this.intl.t('table-cells.admin-events.description-cell.article-content-published', {
        ...this.defaultEventParams(event),
        articleHref: this.router.urlFor(
          'apps.app.articles.articles.article.show',
          event.value.article_id,
        ),
        eventValueArticle_locale: event.value.locale,
        eventValueArticle_title: event.value.title,
      });
    } else if (event.isArticleContentUnpublished) {
      return this.intl.t('table-cells.admin-events.description-cell.article-content-unpublished', {
        ...this.defaultEventParams(event),
        articleHref: this.router.urlFor(
          'apps.app.articles.articles.article.show',
          event.value.article_id,
        ),
        eventValueArticle_locale: event.value.locale,
        eventValueArticle_title: event.value.title,
      });
    } else if (event.isArticleContentDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.article-content-deletion', {
        ...this.defaultEventParams(event),
        eventValueArticle_locale: event.value.locale,
        eventValueArticle_title: event.value.title,
      });
    } else if (event.isMessageStateChange) {
      return this.intl.t('table-cells.admin-events.description-cell.message-state-change', {
        ...this.defaultEventParams(event),
        href1: this.router.urlFor('apps.app.outbound.message', event.value.static.message_id),
        eventValueBefore: event.value.before,
        eventValueAfter: event.value.after,
        eventValueStaticMessage_title: event.value.static.message_title
          ? event.value.static.message_title
          : 'a',
      });
    } else if (event.isCampaignDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.campaign-deletion', {
        ...this.defaultEventParams(event),
        eventValueCampaign_title: event.value.campaign_title ? event.value.campaign_title : 'a',
      });
    } else if (event.isCampaignStateChange) {
      return this.intl.t('table-cells.admin-events.description-cell.campaign-state-change', {
        ...this.defaultEventParams(event),
        eventValueBefore: event.value.before,
        eventValueAfter: event.value.after,
        eventValueCampaign_title: event.value.campaign_title ? event.value.campaign_title : 'a',
      });
    } else if (event.isAppAdminJoin) {
      return this.intl.t('table-cells.admin-events.description-cell.app-admin-join', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAppAuthenticationMethodChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.app-authentication-method-change',
        {
          ...this.defaultEventParams(event),
          eventValueBefore: formatArrayToSentence.compute([event.value.before]),
          eventValueAfter: formatArrayToSentence.compute([event.value.after]),
        },
      );
    } else if (event.isAppDataDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.app-data-deletion', {
        ...this.defaultEventParams(event),
        eventValueType: event.value.type,
      });
    } else if (event.isAppIdentityVerificationChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.app-identity-verification-change',
        {
          ...this.defaultEventParams(event),
          eventValueIdentity_verification: event.value.identity_verification ? 'on' : 'off',
          eventValuePlatform: event.value.platform,
        },
      );
    } else if (event.isAppOutboundAddressChange) {
      return this.intl.t('table-cells.admin-events.description-cell.app-outbound-address-change', {
        ...this.defaultEventParams(event),
        eventValueBefore: event.value.before,
        eventValueAfter: event.value.after,
      });
    } else if (event.isAppPackageTokenRegeneration) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.app-package-token-regeneration',
        {
          ...this.defaultEventParams(event),
          eventValueApp_package_name: event.value.app_package_name,
        },
      );
    } else if (event.isAppWebhookCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.app-webhook-creation', {
        ...this.defaultEventParams(event),
        eventValueWebhook_url: event.value.webhook_url,
        eventValueWebhook_topics: formatArrayToSentence.compute([event.value.webhook_topics]),
      });
    } else if (event.isAppWebhookDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.app-webhook-deletion', {
        ...this.defaultEventParams(event),
        eventValueWebhook_url: event.value.webhook_url,
        eventValueWebhook_topics: formatArrayToSentence.compute([event.value.webhook_topics]),
      });
    } else if (event.isAppGoogleSsoDomainChange) {
      return this.intl.t('table-cells.admin-events.description-cell.app-google-sso-domain-change', {
        ...this.defaultEventParams(event),
        eventValueBefore: formatArrayToSentence.compute([event.value.before]),
        eventValueAfter: formatArrayToSentence.compute([event.value.after]),
      });
    } else if (event.isAppTeamCreation) {
      return this.intl.t(
        this.app.canUseHelpdeskSetup
          ? 'table-cells.admin-events.description-cell.app-team-inbox-creation'
          : 'table-cells.admin-events.description-cell.app-team-creation',
        {
          ...this.defaultEventParams(event),
          eventValueTeam_name: event.value.team_name,
          eventValueTeam_member_count: event.value.team_member_count,
        },
      );
    } else if (event.isAppTeamDeletion) {
      return this.intl.t(
        this.app.canUseHelpdeskSetup
          ? 'table-cells.admin-events.description-cell.app-team-inbox-deletion'
          : 'table-cells.admin-events.description-cell.app-team-deletion',
        {
          ...this.defaultEventParams(event),
          eventValueTeam_name: event.value.team_name,
          eventValueTeam_member_count: event.value.team_member_count,
        },
      );
    } else if (event.isAppTeamMembershipModification) {
      return this.intl.t(
        this.app.canUseHelpdeskSetup
          ? 'table-cells.admin-events.description-cell.app-team-inbox-membership-modification'
          : 'table-cells.admin-events.description-cell.app-team-membership-modification',
        {
          ...this.defaultEventParams(event),
          eventValueTeam_name: event.value.team_name,
        },
      );
    } else if (event.isAppPackageInstallation) {
      return this.intl.t('table-cells.admin-events.description-cell.app-package-installation', {
        ...this.defaultEventParams(event),
        eventValueApp_package_name: event.value.app_package_name,
      });
    } else if (event.isAppPackageUninstallation) {
      return this.intl.t('table-cells.admin-events.description-cell.app-package-uninstallation', {
        ...this.defaultEventParams(event),
        eventValueApp_package_name: event.value.app_package_name,
      });
    } else if (event.isAppDataExport) {
      return this.intl.t('table-cells.admin-events.description-cell.app-data-export', {
        ...this.defaultEventParams(event),
        eventValueType: inflectorHelper.compute([event.value.type, event.value.count], {}),
        eventValueCount: event.value.count,
      });
    } else if (event.isHelpCenterSettingsChange) {
      return this.intl.t('table-cells.admin-events.description-cell.help-center-settings-change', {
        ...this.defaultEventParams(event),
        eventValueTurned_on: event.value.turned_on ? 'on' : 'off',
      });
    } else if (event.isInboundConversationsChange) {
      return this.intl.t('table-cells.admin-events.description-cell.inbound-conversations-change', {
        ...this.defaultEventParams(event),
        eventValueTurned_on: event.value.turned_on ? 'on' : 'off',
        eventValueAudience: event.value.audience,
        eventValueSetting_name: event.value.setting_name
          ? event.value.setting_name
          : 'inboundConversations',
      });
    } else if (event.isMessengerSpacesChange) {
      return this.intl.t('table-cells.admin-events.description-cell.messenger-spaces-change', {
        ...this.defaultEventParams(event),
        eventValueAudience: event.value.audience,
      });
    } else if (event.isWelcomeMessageChange) {
      return this.intl.t('table-cells.admin-events.description-cell.welcome-message-change', {
        ...this.defaultEventParams(event),
        eventValueSetting_name: event.value?.setting_name
          ? event.value.setting_name
          : 'welcomeMessage',
      });
    } else if (event.isLookAndFeelChange) {
      return this.intl.t('table-cells.admin-events.description-cell.look-and-feel-change', {
        ...this.defaultEventParams(event),
        eventValueSetting_name: event.value.setting_name,
      });
    } else if (event.isSearchBrowseEnabledChange) {
      return this.intl.t('table-cells.admin-events.description-cell.search-browse-enabled-change', {
        ...this.defaultEventParams(event),
        eventValueUser_type: event.value.user_type,
      });
    } else if (event.isUpfrontEmailCollectionChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.upfront-email-collection-change',
        {
          ...this.defaultEventParams(event),
          eventValueSetting_name: event.value.setting_name,
        },
      );
    } else if (event.isSearchBrowseRequiredChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.search-browse-required-change',
        {
          ...this.defaultEventParams(event),
          eventValueUser_type: event.value.user_type,
        },
      );
    } else if (event.isTemporaryExpectationChange) {
      return this.intl.t('table-cells.admin-events.description-cell.temporary-expectation-change', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isSecuritySettingChange) {
      return this.intl.t('table-cells.admin-events.description-cell.security-setting-change', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isOfficeHoursChange) {
      return this.intl.t('table-cells.admin-events.description-cell.office-hours-change', {
        ...this.defaultEventParams(event),
        eventValueSchedule_name: event.value.schedule_name,
        isDefault: event.value.is_default,
      });
    } else if (event.isPrivacyPolicyNoticeChange) {
      if (event.value.enabled) {
        return this.intl.t(
          'table-cells.admin-events.description-cell.privacy-policy-notice-enabled',
          {
            ...this.defaultEventParams(event),
          },
        );
      } else {
        return this.intl.t(
          'table-cells.admin-events.description-cell.privacy-policy-notice-disabled',
          {
            ...this.defaultEventParams(event),
          },
        );
      }
    } else if (event.isPrivacyPolicyNoticeContentsChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.privacy-policy-notice-contents-change',
        {
          ...this.defaultEventParams(event),
          eventValueLocales: event.value.locales
            .map((locale) => this.intl.languageNameFromCode(locale))
            .join(', '),
        },
      );
    } else if (event.isTopicCreation) {
      return this.intl.t('table-cells.admin-events.description-cell.topic-creation', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isTopicDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.topic-deletion', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isTopicChange) {
      return this.intl.t('table-cells.admin-events.description-cell.topic-change', {
        ...this.defaultEventParams(event),
        eventValueBeforeName: event.value.before.name,
        eventValueAfterName: event.value.after.name,
        eq: event.value.before.name === event.value.after.name,
      });
    } else if (event.isConversationPartDeletion) {
      return this.intl.t('table-cells.admin-events.description-cell.part-deletion', {
        ...this.defaultEventParams(event),
        partHref: this.router
          .urlFor('apps.app.inbox.inbox.conversation', event.value.conversation_id)
          .concat('#part_id=', event.value.part_identifier),
        eventConversationId: event.value.conversation_id,
        eventPartId: event.value.part_identifier,
      });
    } else if (event.isTwoFactorAuthChange) {
      return this.intl.t('table-cells.admin-events.description-cell.two-factor-auth-change', {
        ...this.defaultEventParams(event),
        eventValueState: event.value.state,
      });
    } else if (event.isAdminImpersonationStart) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-impersonation-start', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminImpersonationEnd) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-impersonation-end', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isEmailSignatureChange) {
      return this.intl.t('table-cells.admin-events.description-cell.email-signature-change', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isRecoveryCodeSent) {
      return this.intl.t('table-cells.admin-events.description-cell.recovery-code-sent', {
        ...this.defaultEventParams(event),
        eventTargetEmail: event.value.target_email_address,
      });
    } else if (event.isAdminUnauthorizedSignInMethod) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.admin-unauthorized-sign-in-method',
        {
          ...this.defaultEventParams(event),
          loginMethods: formatArrayToSentence.compute([event.value.current_sign_in_methods]),
          allowedLoginMethods: event.value.authorized_sign_in_methods,
        },
      );
    } else if (event.isAdminPasswordResetRequest) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-password-reset-request', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminPasswordResetSuccess) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-password-reset-success', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminAssignmentLimitChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.admin-assignment-limit-change',
        {
          ...this.defaultEventParams(event),
          assignmentLimit: event.value.assignment_limit,
          adminNames: event.value.admins
            .map((adminId) => this.app.admins.findBy('id', String(adminId))?.name)
            .join(', '),
        },
      );
    } else if (event.isFinOptIn) {
      return this.intl.t('table-cells.admin-events.description-cell.fin-opt-in', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isIdvReminderDisabled) {
      return this.intl.t('table-cells.admin-events.description-cell.idv-reminder-disabled', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isMessengerPlatformAvailabilityChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.messenger-platform-availability-change',
        {
          ...this.defaultEventParams(event),
          eventStateChange: event.value.state,
          eventPlatform: event.value.platform,
        },
      );
    } else if (event.isMessengerPlatformInactivityDisableEvent) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.messenger-platform-inactivity-disable-event',
        {
          ...this.defaultEventParams(event),
          eventPlatform: event.value.platform,
        },
      );
    } else if (event.isIpAllowlistSettingChange) {
      return this.intl.t('table-cells.admin-events.description-cell.ip-allowlist-setting-change', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isAdminMobileLoginSuccess) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-mobile-login-success', {
        ...this.defaultEventParams(event),
        mobileAppName: event.value.mobile_app_name,
      });
    } else if (event.isAdminMobileLoginFailure) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-mobile-login-failure', {
        ...this.defaultEventParams(event),
        mobileAppName: event.value.mobile_app_name,
      });
    } else if (event.isAdminNameChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-name-change', {
        ...this.defaultEventParams(event),
        before: event.value.before,
        after: event.value.after,
      });
    } else if (event.isAdminEmailChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-email-change', {
        ...this.defaultEventParams(event),
        before: event.value.before,
        after: event.value.after,
      });
    } else if (event.isAdminPasswordChange) {
      return this.intl.t('table-cells.admin-events.description-cell.admin-password-change', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isReportCreatedEvent) {
      return this.intl.t('table-cells.admin-events.description-cell.report-create', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isReportUpdateEvent) {
      return this.intl.t('table-cells.admin-events.description-cell.report-update', {
        ...this.defaultEventParams(event),
        title: event.value.before.title || this.intl.t('reporting.custom-reports.report.untitled'),
        reportHref: this.router.urlFor(
          'apps.app.reports.custom-reports.report.show',
          event.value.report_id,
        ),
      });
    } else if (event.isReportDeleteEvent) {
      return this.intl.t('table-cells.admin-events.description-cell.report-delete', {
        ...this.defaultEventParams(event),
        title: event.value.before.title || this.intl.t('reporting.custom-reports.report.untitled'),
      });
    } else if (event.isReportingFolderCreateEvent) {
      return this.intl.t('table-cells.admin-events.description-cell.reporting-folder-create', {
        ...this.defaultEventParams(event),
        name: event.value.name,
      });
    } else if (event.isReportingFolderDeleteEvent) {
      return this.intl.t('table-cells.admin-events.description-cell.reporting-folder-delete', {
        ...this.defaultEventParams(event),
        name: event.value.name,
      });
    } else if (event.isReportingFolderNameChange) {
      return this.intl.t('table-cells.admin-events.description-cell.reporting-folder-name-change', {
        ...this.defaultEventParams(event),
        before: event.value.before,
        after: event.value.after,
      });
    } else if (event.isReportingFolderPositionChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.reporting-folder-position-change',
        {
          ...this.defaultEventParams(event),
          name: event.value.name,
        },
      );
    } else if (event.isReportingFolderReportsChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.reporting-folder-reports-change',
        {
          ...this.defaultEventParams(event),
          name: event.value.name,
        },
      );
    } else if (event.isReportingFolderPinChange) {
      return this.intl.t('table-cells.admin-events.description-cell.reporting-folder-pin-change', {
        ...this.defaultEventParams(event),
        name: event.value.name,
        before: event.value.before,
        after: event.value.after,
      });
    } else if (event.isReportingCsvExport) {
      return this.intl.t('table-cells.admin-events.description-cell.reporting-csv-export', {
        ...this.defaultEventParams(event),
      });
    } else if (event.isReportAccessCreate) {
      return this.intl.t('table-cells.admin-events.description-cell.report-access-create', {
        ...this.defaultEventParams(event),
        ...this.reportAccessParams(event),
      });
    } else if (event.isReportAccessDelete) {
      return this.intl.t('table-cells.admin-events.description-cell.report-access-delete', {
        ...this.defaultEventParams(event),
        ...this.reportAccessParams(event),
      });
    } else if (event.isReportAccessUpdate) {
      return this.intl.t('table-cells.admin-events.description-cell.report-access-update', {
        ...this.defaultEventParams(event),
        ...this.reportAccessParams(event),
        before: event.value.before,
        after: event.value.after,
      });
    } else if (event.isSeatRevoke) {
      return this.intl.t('table-cells.admin-events.description-cell.seat-revoke', {
        ...this.defaultEventParams(event),
        href1: `/a/apps/${this.app.id}/admins/${event.value.admin_id}`,
        eventValueAdmin_name: event.value.admin_name,
        eventValueRevoked: formatArrayToSentence.compute([event.value.revoked]),
      });
    } else if (event.isAdminChannelChange) {
      let translationKey;
      if (event.value.auto_changed) {
        translationKey = 'table-cells.admin-events.description-cell.admin-channel-auto-change';
      } else if (event.value.update_by && event.value.update_by !== event.admin_id) {
        translationKey = 'table-cells.admin-events.description-cell.admin-channel-change-by-other';
      } else {
        translationKey = 'table-cells.admin-events.description-cell.admin-channel-change';
      }

      return this.intl.t(translationKey, {
        ...this.defaultEventParams(event),
        channel: this.channelAvailabilityToString(event.value.channel_availability),
        updatedBy: event.value.update_by_name,
      });
    } else if (event.isFinPersistedDataContextAttributeChange) {
      return this.intl.t(
        'table-cells.admin-events.description-cell.fin-persisted-data-context-attribute-change',
        {
          ...this.defaultEventParams(event),
          action: event.value.action,
          friendly_attribute_type: event.value.friendly_attribute_type,
          friendly_attribute_identifier: event.value.friendly_attribute_identifier,
        },
      );
    }
  }

  channelAvailabilityToString(channelAvailability) {
    return this.intl.t(
      `table-cells.admin-events.description-cell.channel-availability.${channelAvailability}`,
    );
  }
}
