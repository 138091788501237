/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { SMS_SEGMENTS_SENT_RECEIVED_GROUP } from 'embercom/lib/purchase/constants';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { PricingModelIdentifier } from './billing/pricing-models';

export const PER_PRODUCT_PRICING_PREMIUM_MAR2019_PRICING_MODEL =
  'per_product_pricing_premium_mar2019';
export const CORE_PRICING_MODEL = 'core_with_add_ons';
export const CORE_FEB_2021_PRICING_MODEL = 'core_with_add_ons_feb2021';
export const VALUE_BASED_PRICING_MARCH_2021_PRICING_MODEL = 'value_based_pricing_mar2021';
export const VALUE_BASED_PRICING_FEB2022_PRICING_MODEL = 'value_based_pricing_feb2022';
export const VALUE_BASED_PRICING_MARCH_2021_EARLY_STAGE_PRICING_MODEL =
  'value_based_pricing_mar2021_early_stage';
export const PRICING_5_0_PRICING_MODEL = PricingModelIdentifier.PRICING_5_0;
export const PRICING_5_1_PRICING_MODEL = PricingModelIdentifier.PRICING_5_1;
export const PRICING_5_0_EARLY_STAGE_PRICING_MODEL = PricingModelIdentifier.PRICING_5_0_EARLY_STAGE;
export const PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_0_EARLY_STAGE_FREE;
export const PRICING_5_1_EARLY_STAGE_PRICING_MODEL = PricingModelIdentifier.PRICING_5_1_EARLY_STAGE;
export const PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_1_EARLY_STAGE_FREE;
export const PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE;
export const PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_1_COPILOT_EARLY_STAGE_FREE;
export const PRICING_5_EARLY_STAGE_YEAR_2_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_EARLY_STAGE_YEAR_2;
export const PRICING_5_EARLY_STAGE_YEAR_3_PRICING_MODEL =
  PricingModelIdentifier.PRICING_5_EARLY_STAGE_YEAR_3;

export const VBP_1_X_PRICING_MODELS = [CORE_PRICING_MODEL, CORE_FEB_2021_PRICING_MODEL];
export const VBP_2_X_PRICING_MODELS = [
  VALUE_BASED_PRICING_MARCH_2021_PRICING_MODEL,
  VALUE_BASED_PRICING_FEB2022_PRICING_MODEL,
];
export const VBP_2_X_EARLY_STAGE_PRICING_MODELS = [
  VALUE_BASED_PRICING_MARCH_2021_EARLY_STAGE_PRICING_MODEL,
];
export const VBP_2_X_INCLUDING_EARLY_STAGE_PRICING_MODELS = VBP_2_X_PRICING_MODELS.concat(
  VBP_2_X_EARLY_STAGE_PRICING_MODELS,
);
export const PRICING_5_X_PRICING_MODELS = [
  PRICING_5_0_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_2_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_3_PRICING_MODEL,
];

export const PRICING_5_X_EARLY_STAGE_PRICING_MODELS = [
  PRICING_5_0_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_0_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_PRICING_MODEL,
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_2_PRICING_MODEL,
  PRICING_5_EARLY_STAGE_YEAR_3_PRICING_MODEL,
];

export const INCLUDED_TIERS_FROM_PRICING_ENDPOINT = 250;

// Products
// These are strings because Ember Data forces you to use strings for IDs
export const INBOX_ID = '1';
export const MESSAGES_ID = '2';
export const ARTICLES_ID = '5';
export const OPERATOR_ID = '7';
export const ANSWER_BOT_ID = '8';
export const PRODUCT_TOURS_ID = '9';
export const CORE_ID = '10';
export const LEAD_GENERATION_ID = '11';
export const SUPPORT_AUTOMATION_ID = '12';
export const ADVANCED_CUSTOMER_ENGAGEMENT_ID = '13';
export const CORE_STARTER_ID = '15';
export const CORE_SUPPORT_ID = '16';
export const CORE_ENGAGE_ID = '17';
export const CORE_CONVERT_ID = '18';
export const PROACTIVE_SUPPORT_ID = '19';
export const ADVANCED_WORKSPACE_MANAGEMENT_ID = '20';
export const CUSTOM_API_RATE_LIMIT_ID = '21';
export const PEOPLE_REACHED_ID = '22';
export const WHATSAPP_ID = '23';
export const PREMIER_ONBOARDING_ID = '24';
export const PREMIER_SUCCESS_ID = '25';
export const PREMIER_SUPPORT_ID = '26';
export const SURVEYS_ID = '27';
export const SWITCH_ID = '28';
export const SMS_ID = '29';
export const PRICING_5_X_CORE_ID = '30';
export const PROACTIVE_SUPPORT_PLUS_ID = '31';
export const PREMIER_ONBOARDING_3_TIERS_ID = '33'; // 2023 version of Premier Onboarding with 3 tiers
export const FIN_AI_COPILOT_ID = '34';

export const PRODUCT_KEYS = {
  1: 'inbox',
  2: 'messages',
  5: 'articles',
  7: 'operator',
  8: 'answer',
  9: 'tours',
};

export const PRODUCT_IDS = [
  INBOX_ID,
  MESSAGES_ID,
  ARTICLES_ID,
  OPERATOR_ID,
  ANSWER_BOT_ID,
  PRODUCT_TOURS_ID,
];

// Plans
export const INBOX_ESSENTIAL_ID = '1';
export const INBOX_PRO_ID = '2';
export const INBOX_PREMIUM_ID = '13';
export const MESSAGES_ESSENTIAL_ID = '3';
export const MESSAGES_PRO_ID = '4';
export const MESSAGES_PREMIUM_ID = '14';
export const ARTICLES_ESSENTIAL_ID = '9';
export const OPERATOR_PRO_ID = '12';
export const ANSWER_BOT_ESSENTIAL_ID = '15';
export const PRODUCT_TOURS_ESSENTIAL_ID = '16';
export const ARTICLES_PRO_ID = '17';
export const CORE_GOOD_ID = '18';
export const CORE_GROW_ID = '19';
export const CORE_ACCELERATE_ID = '20';
export const CORE_SCALE_ID = '21';
export const LEAD_GENERATION_BASE_ID = '22';
export const SUPPORT_AUTOMATION_BASE_ID = '23';
export const ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID = '24';
export const CORE_STARTER_BASE_ID = '26';
export const CORE_SUPPORT_PRO_ID = '27';
export const CORE_SUPPORT_PREMIUM_ID = '28';
export const CORE_ENGAGE_PRO_ID = '29';
export const CORE_ENGAGE_PREMIUM_ID = '30';
export const CORE_CONVERT_PRO_ID = '31';
export const PROACTIVE_SUPPORT_BASE_ID = '32';
export const ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID = '33';
export const CUSTOM_API_RATE_LIMIT_BASE_ID = '34';
export const PEOPLE_REACHED_TIER_1_ID = '35';
export const PEOPLE_REACHED_TIER_2_ID = '36';
export const PEOPLE_REACHED_TIER_3_ID = '37';
export const WHATSAPP_BASE_ID = '38';
export const PREMIER_ONBOARDING_BASE_ID = '39';
export const PREMIER_ONBOARDING_PLUS_ID = '40';
export const PREMIER_SUCCESS_BASE_ID = '41';
export const PREMIER_SUPPORT_TIER_1_ID = '42';
export const PREMIER_SUPPORT_TIER_2_ID = '43';
export const PREMIER_SUPPORT_TIER_3_ID = '44';
export const PREMIER_SUPPORT_TIER_4_ID = '45';
export const PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID = '46';
export const PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID = '47';
export const PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID = '48';
export const PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID = '49';
export const SURVEYS_BASE_ID = '50';
export const SWITCH_BASE_ID = '51';
export const SMS_BASE_ID = '52';
// Pricing 5.x Plans
export const PRICING_5_X_CORE_ESSENTIAL_ID = '53'; // Pricing 5.x Core - Good (Tier 1)
export const PRICING_5_X_CORE_ADVANCED_ID = '54'; // Pricing 5.x Core - Better (Tier 2)
export const PRICING_5_X_CORE_EXPERT_ID = '55'; // Pricing 5.x Core - Best (Tier 3)
export const PROACTIVE_SUPPORT_PLUS_BASE_ID = '56'; // Proactive Support Plus
export const PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID = '58'; // Premier Onboarding 3 tiers variation active for 1 month
export const PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID = '59'; // Premier Onboarding 3 tiers variation active for 2 months
export const PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID = '60'; // Premier Onboarding 3 tiers variation active for 3 months
export const FIN_AI_COPILOT_BASE_ID = '61'; // Fin AI Copilot

export const ESSENTIAL_TIER_ID = 1;
export const PRO_TIER_ID = 2;

// Start with all 3
export const ESSENTIAL_BUNDLE = [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID, ARTICLES_ESSENTIAL_ID];

export const PRO_BUNDLE = [INBOX_PRO_ID, MESSAGES_PRO_ID, ARTICLES_ESSENTIAL_ID];

export const FREE_PRODUCT_BUNDLE = [INBOX_ID, MESSAGES_ID];

export const SOLUTION_ANALYTICS_OBJECTS = {
  3: 'onboard_and_engage',
  4: 'capture_and_convert',
  5: 'provide_selfserve_support',
  7: 'support_and_retain',
};

export const UPSELL_PRODUCTS = [OPERATOR_ID, ARTICLES_ID, ANSWER_BOT_ID];
export const EARLY_STAGE_BUNDLE = [
  INBOX_PRO_ID,
  MESSAGES_PRO_ID,
  ARTICLES_PRO_ID,
  OPERATOR_PRO_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
];

export const CORE_EARLY_STAGE_BUNDLE = [CORE_ACCELERATE_ID, PRODUCT_TOURS_ESSENTIAL_ID];
export const CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE = [
  CORE_ACCELERATE_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID,
];

export const VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE = [
  CORE_SUPPORT_PRO_ID,
  CORE_ENGAGE_PRO_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  PEOPLE_REACHED_TIER_2_ID,
];

export const PRICING_5_EARLY_STAGE_BUNDLE = [
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
];

export const CORE_SELF_SERVE_PLAN_IDS = [CORE_GOOD_ID, CORE_GROW_ID];

export const CORE_SALES_LED_PLAN_IDS = [CORE_ACCELERATE_ID, CORE_SCALE_ID];

export const VBP_SELF_SERVE_ADD_ONS = [
  PRODUCT_TOURS_ESSENTIAL_ID,
  WHATSAPP_BASE_ID,
  SURVEYS_BASE_ID,
  SWITCH_BASE_ID,
  SMS_BASE_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
];
export const VBP_SALES_LED_ADD_ONS = [
  LEAD_GENERATION_BASE_ID,
  SUPPORT_AUTOMATION_BASE_ID,
  ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID,
];

export const CORE_PLAN_IDS = [...CORE_SELF_SERVE_PLAN_IDS, ...CORE_SALES_LED_PLAN_IDS];

export const CUSTOMER_INITIATED_TRIALABLE_CORE_PLAN_IDS = [
  CORE_ACCELERATE_ID,
  CORE_SUPPORT_PRO_ID,
  PROACTIVE_SUPPORT_BASE_ID,
];

export const MESSAGES_FEATURE = 'auto_messaging';
export const INBOX_FEATURE = 'inbox';
export const ARTICLES_FEATURE = 'help_center';
export const MULTILINGUAL_ARTICLES_FEATURE = 'multilingual_articles';

// Current solutions
export const VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID = '20'; // VBP 2.0 Early Stage with Support, Engage and Product Tours
export const VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID = '21'; // VBP 2.1 Starter (Self Serve)

// Pricing 5 solutions
export const PRICING_5_0_ESSENTIAL_SOLUTION_ID = '24';
export const PRICING_5_0_ADVANCED_SOLUTION_ID = '25';
export const PRICING_5_X_EARLY_STAGE_SOLUTION_ID = '26';
export const PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID = '27';
export const PRICING_5_1_ESSENTIAL_SOLUTION_ID = '28';
export const PRICING_5_1_ADVANCED_SOLUTION_ID = '29';
export const PRICING_5_1_EXPERT_SOLUTION_ID = '30';

export const PRICING_5_X_ADVANCED_SOLUTION_IDS = [
  PRICING_5_0_ADVANCED_SOLUTION_ID,
  PRICING_5_1_ADVANCED_SOLUTION_ID,
];

export const PRICING_5_X_ESSENTIAL_SOLUTION_IDS = [
  PRICING_5_0_ESSENTIAL_SOLUTION_ID,
  PRICING_5_1_ESSENTIAL_SOLUTION_ID,
];

export const PRICING_5_X_ANNUAL_DISCOUNT_PERCENTAGES = {
  [PRICING_5_X_CORE_ESSENTIAL_ID]: 26,
  [PRICING_5_X_CORE_ADVANCED_ID]: 14,
  [PRICING_5_X_CORE_EXPERT_ID]: 5,
  [FIN_AI_COPILOT_BASE_ID]: 17,
};

// Only some solutions can be selected for annual billing.
export const OFFERED_SELF_SERVE_ANNUALLY_BILLED_SOLUTIONS = [
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
];

export const FIN_PRICE_PER_RESOLUTION = 0.99;

export enum BILLING_PERIODS {
  Monthly = 1,
  Annual = 12,
}

export enum AppPricing5PriceModels {
  PRICING_5_0 = 'pricing5_0',
  PRICING_5_1 = 'pricing5_1',
}

export const VBP_2_X_EARLY_STAGE_SOLUTION_IDS = [VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID];
export const PRICING_5_X_EARLY_STAGE_SOLUTION_IDS = [
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
];

export const EARLY_STAGE_SOLUTION_IDS = [
  ...VBP_2_X_EARLY_STAGE_SOLUTION_IDS,
  ...PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
];

export const CURRENT_EARLY_STAGE_SOLUTION_IDS = [
  ...VBP_2_X_EARLY_STAGE_SOLUTION_IDS,
  ...PRICING_5_X_EARLY_STAGE_SOLUTION_IDS,
];

export const VBP_SOLUTION_IDS = [
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
  ...VBP_2_X_EARLY_STAGE_SOLUTION_IDS,
];

export const PRICING_5_X_SOLUTION_IDS_NON_EARLY_STAGE = [
  PRICING_5_0_ESSENTIAL_SOLUTION_ID,
  PRICING_5_0_ADVANCED_SOLUTION_ID,
  PRICING_5_1_ESSENTIAL_SOLUTION_ID,
  PRICING_5_1_ADVANCED_SOLUTION_ID,
  PRICING_5_1_EXPERT_SOLUTION_ID,
];

export const PRICING_5_SOLUTION_IDS = [
  PRICING_5_0_ESSENTIAL_SOLUTION_ID,
  PRICING_5_0_ADVANCED_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_SOLUTION_ID,
  PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID,
  PRICING_5_1_ESSENTIAL_SOLUTION_ID,
  PRICING_5_1_ADVANCED_SOLUTION_ID,
  PRICING_5_1_EXPERT_SOLUTION_ID,
];

// removed ARTICLES_ESSENTIAL_ID from solution 7 and 8 to fix bug
export const SOLUTION_PLAN_IDS: { [key: number]: string[] } = {
  1: [INBOX_ESSENTIAL_ID, ARTICLES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, ARTICLES_ESSENTIAL_ID]
  2: [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, MESSAGES_PRO_ID],
  3: [MESSAGES_ESSENTIAL_ID], // The legacy pro version had [MESSAGES_PRO_ID]
  4: [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, MESSAGES_PRO_ID],
  5: [ARTICLES_ESSENTIAL_ID],
  7: [INBOX_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID]
  8: [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, MESSAGES_PRO_ID],
  9: [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, MESSAGES_PRO_ID],
  10: EARLY_STAGE_BUNDLE,
  11: [INBOX_ESSENTIAL_ID, MESSAGES_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID, MESSAGES_PRO_ID],
  12: [INBOX_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID],
  13: [INBOX_ESSENTIAL_ID], // The legacy pro version had [INBOX_PRO_ID],
  14: [CORE_GOOD_ID], // The legacy pro version had [CORE_GROW_ID],
  15: CORE_EARLY_STAGE_BUNDLE,
  16: [CORE_GOOD_ID], // The legacy pro version had [CORE_GROW_ID],
  17: CORE_EARLY_STAGE_BUNDLE,
  18: [CORE_STARTER_BASE_ID],
  19: CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE,
  20: VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE,
  21: [CORE_STARTER_BASE_ID],
  22: [CORE_SUPPORT_PRO_ID, PROACTIVE_SUPPORT_BASE_ID, PEOPLE_REACHED_TIER_1_ID],
  24: [PRICING_5_X_CORE_ESSENTIAL_ID],
  25: [PRICING_5_X_CORE_ADVANCED_ID],
  26: PRICING_5_EARLY_STAGE_BUNDLE,
  27: PRICING_5_EARLY_STAGE_BUNDLE,
  28: [PRICING_5_X_CORE_ESSENTIAL_ID],
  29: [PRICING_5_X_CORE_ADVANCED_ID],
  30: [PRICING_5_X_CORE_EXPERT_ID],
};

export const VBP1_DEFAULT_UPGRADE_PATH = [CORE_GOOD_ID, CORE_GROW_ID, CORE_ACCELERATE_ID];

export const VBP2_PLAN_IDS = [
  CORE_STARTER_BASE_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_CONVERT_PRO_ID,
];
export const VBP2_DEFAULT_UPGRADE_PATH = [
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_CONVERT_PRO_ID,
];

export const VBP2_STARTER_UPGRADE_PATH = [
  CORE_STARTER_BASE_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
];

export const PRICING_5_X_ADDON_PLAN_IDS = [PROACTIVE_SUPPORT_PLUS_BASE_ID, FIN_AI_COPILOT_BASE_ID];

export const PRICING_5_X_CORE_PLANS = [
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_EXPERT_ID,
];

export const PRICING_5_X_PLAN_IDS = [...PRICING_5_X_CORE_PLANS, ...PRICING_5_X_ADDON_PLAN_IDS];

// Plans for which the Non-Graduating Trials are available for SFDC contracted customers
export const NGT_ADDON_PLAN_IDS = [
  PRODUCT_TOURS_ESSENTIAL_ID,
  SURVEYS_BASE_ID,
  WHATSAPP_BASE_ID,
  SWITCH_BASE_ID,
  ANSWER_BOT_ESSENTIAL_ID,
  SUPPORT_AUTOMATION_BASE_ID,
];

// Core Plans for which the Non-Graduating Trials are available for non SFDC contracted customers
export const SS_NGT_CORE_PLAN_IDS = [CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SUPPORT_PRO_ID];

export const CORE_SEAT_METRIC = 'latest_daily_admin_count';

// Customer Cohort
export const SALES_SOLD_COHORT = 'Sales-Sold';
export const SALES_MANAGED_COHORT = 'Sales-Managed';

export const CORE_EARLY_STAGE_FREE_COUPON_CODE = 'EarlyStageCoreCouponStarter';
export const CORE_EARLY_STAGE_VBP_1_0_COUPON_CODE = 'EarlyStageCoreCouponStandard';
export const CORE_EARLY_STAGE_VBP_1_2_COUPON_CODE = 'EarlyStageCoreCouponFeb2021Standard';
export const CORE_EARLY_STAGE_VBP_1_2_ENGAGEMENT_COUPON_CODE =
  'EarlyStageCoreCouponFeb2021EngagementStandard';
export const VBP_MAR2021_EARLY_STAGE_SEP2021_COUPON_CODE = 'EarlyStageValueBasedPricingSep2021';
export const STRIPE_MIGRATION_REASON = 'migrating_to_stripe';

export function earlyStageCouponCodeForSolution(solutionId: string, free: boolean) {
  if (free && VBP_2_X_EARLY_STAGE_SOLUTION_IDS.includes(solutionId)) {
    return CORE_EARLY_STAGE_FREE_COUPON_CODE;
  }

  switch (solutionId) {
    case VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID:
      return VBP_MAR2021_EARLY_STAGE_SEP2021_COUPON_CODE;
    default:
      return undefined;
  }
}

export const PEOPLE_REACHED_PLANS = [
  PEOPLE_REACHED_TIER_1_ID,
  PEOPLE_REACHED_TIER_2_ID,
  PEOPLE_REACHED_TIER_3_ID,
];

export const SERVICES_PLANS = [
  PREMIER_ONBOARDING_BASE_ID,
  PREMIER_ONBOARDING_PLUS_ID,
  PREMIER_SUCCESS_BASE_ID,
  PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID,
  PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID,
  PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID,
  PREMIER_SUPPORT_TIER_1_ID,
  PREMIER_SUPPORT_TIER_2_ID,
  PREMIER_SUPPORT_TIER_3_ID,
  PREMIER_SUPPORT_TIER_4_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID,
  PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID,
];

// used to add content in Paywalls, those are not billing features
// this list will be appended to real billing features in Paywalls
export const MARKETING_PLAN_FEATURES_TRANSLATION_KEYS = {
  [ANSWER_BOT_ESSENTIAL_ID]: ['embedded_apps', 'insights_reporting', 'machine_learning_curation'],
  [ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID]: ['premium_integrations'],
  [ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID]: [
    'premium_integrations',
    'restrict_conversation_access_flexible',
  ],
  [ARTICLES_ESSENTIAL_ID]: [
    'article_search',
    'custom_branding',
    'custom_domain',
    'failed_searches',
    'operator_office_hours',
  ],
  [OPERATOR_PRO_ID]: [
    'bot_audience_targeting',
    'bot_button_triggers',
    'bot_followups',
    'bot_preview',
    'bot_reporting',
    'bot_video',
    'bot_templates',
  ],
  [CORE_GROW_ID]: ['restrict_conversation_access'],
  [CORE_ACCELERATE_ID]: [
    'bot_audience_targeting',
    'bot_button_triggers',
    'bot_followups',
    'bot_preview',
    'bot_reporting',
    'bot_video',
    'performance_reports',
    'reactions',
    'bot_templates',
  ],
  [CORE_GOOD_ID]: ['custom_sender_addresses', 'html_editor', 'operator_office_hours'],
  [CORE_ENGAGE_PRO_ID]: [
    'bot_audience_targeting',
    'bot_button_triggers',
    'bot_followups',
    'bot_preview',
    'bot_reporting',
    'bot_video',
    'conversation_feed',
    'custom_sender_addresses',
    'html_editor',
    'bot_templates',
    'operator',
    'reactions',
  ],
  [CORE_ENGAGE_PREMIUM_ID]: ['engage_premium'],
  [CORE_CONVERT_PRO_ID]: [
    'bot_audience_targeting',
    'bot_button_triggers',
    'bot_followups',
    'bot_preview',
    'bot_reporting',
    'bot_video',
    'conversation_feed',
    'operator',
    'operator_office_hours',
    'performance_reports',
    'reactions',
    'restrict_conversation_access',
    'bot_templates',
  ],
  [CORE_SCALE_ID]: [
    'manage_capacity_inbound',
    'manage_capacity_inbound_report',
    'manage_capacity_teammate_routing',
    'restrict_conversation_access_flexible',
    'custom_api_limits',
  ],
  [CORE_STARTER_BASE_ID]: [
    'article_search',
    'conversation_feed',
    'custom_domain',
    'custom_sender_addresses',
    'html_editor',
    'operator',
    'operator_office_hours',
    'restrict_conversation_access',
    'custom_branding',
    'failed_searches',
    'reactions',
  ],
  [CORE_SUPPORT_PRO_ID]: [
    'article_search',
    'bot_audience_targeting',
    'bot_button_triggers',
    'bot_followups',
    'bot_preview',
    'bot_reporting',
    'bot_video',
    'custom_domain',
    'operator',
    'restrict_conversation_access',
    'conversation_feed',
    'custom_branding',
    'bot_templates',
    'failed_searches',
  ],
  [CORE_SUPPORT_PREMIUM_ID]: [
    'manage_capacity_inbound',
    'manage_capacity_inbound_report',
    'manage_capacity_teammate_routing',
    'monitor_agreements',
  ],
  [INBOX_PREMIUM_ID]: [
    'manage_capacity_inbound',
    'manage_capacity_inbound_report',
    'manage_capacity_teammate_routing',
    'monitor_agreements',
    'free_tickets',
    'performance_reports',
    'premium_integrations',
    'restrict_conversation_access_flexible',
    'custom_api_limits',
  ],
  [SUPPORT_AUTOMATION_BASE_ID]: [
    'embedded_apps',
    'insights_reporting',
    'machine_learning_curation',
    'premium_integrations',
  ],
  [MESSAGES_ESSENTIAL_ID]: [
    'conversation_feed',
    'custom_sender_addresses',
    'html_editor',
    'operator_office_hours',
  ],
  [INBOX_ESSENTIAL_ID]: ['conversation_feed', 'operator_office_hours'],
  [MESSAGES_PREMIUM_ID]: ['free_tickets', 'premium_integrations', 'custom_api_limits'],
  [MESSAGES_PRO_ID]: ['reactions'],
  [INBOX_PRO_ID]: ['restrict_conversation_access'],
  [PROACTIVE_SUPPORT_BASE_ID]: ['reactions'],
  [PRICING_5_X_CORE_ESSENTIAL_ID]: ['reactions'],
  [PRICING_5_X_CORE_ADVANCED_ID]: ['reactions'],
  [PRICING_5_X_CORE_EXPERT_ID]: ['reactions'],
  [PROACTIVE_SUPPORT_PLUS_BASE_ID]: ['reactions'],
};

/**
 * The only supported models for non-Early Stage self-serve signups is Pricing 5.
 *
 * When a new sign up comes with an Early Stage solution id we change it to the VBP 2 equivalent
 * Otherwise, if the solution id is not Pricing 5, we redirect to Pricing 5 Essential
 *
 * This can happen when tampering with the solution_id query param in the url or following an old redirect
 */
export function getSolutionIdInActivePricingModel(solutionId: string) {
  if (PRICING_5_SOLUTION_IDS.includes(solutionId)) {
    return solutionId;
  }

  if (solutionId === VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID) {
    return VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID;
  }

  if (solutionId === VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID) {
    return VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID;
  }

  return PRICING_5_0_ESSENTIAL_SOLUTION_ID;
}

export function isEarlyStageSolution(solutionId: string) {
  return EARLY_STAGE_SOLUTION_IDS.includes(solutionId);
}

export function plansFromSolution(solutionId: number) {
  return SOLUTION_PLAN_IDS[solutionId] || [];
}

export function minimumSeatsForSolutionId(solutionId: string) {
  switch (solutionId) {
    case VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID:
      return 2;
    default:
      return 0;
  }
}

const year1Discounts: { [key: string]: number } = {
  [PRICING_5_X_EARLY_STAGE_SOLUTION_ID]: 90,
  [VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID]: 95,
};

export function percentDiscountForSolution(solutionId: string, year = 1) {
  switch (year) {
    case 1:
      return year1Discounts[solutionId] ?? 100;
    case 2:
      return 50;
    case 3:
      return 25;
    default:
      return 0;
  }
}

// Currently we use a mixture of plain strings and translation keys here, which is not ideal. Since we decided we don't want to translate plan names, the plain
// strings and keys ultimately show the same value to users (unless we accidentally translate the names instead of keeping them as proper nouns).
// We should probably remove all use of the translation keys for plan names to avoid accidentally translating them.
interface PlanData {
  name: string;
  nameTranslationKey: string;
  nameWithProduct: string;
  nameWithProductTranslationKey: string;
  [key: string]: any; // All other keys are optional
}

export const PLAN_DATA: { [key: string]: PlanData } = {
  [INBOX_ESSENTIAL_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.name`,
    nameWithProduct: 'Inbox Essential',
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.name_with_product`,
    marketingName: 'Team Inbox - Essential',
    marketingNameTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.marketing_name`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    description: 'Answer customer questions faster through a collaborative, shared Inbox',
    descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.description`,
    breakdown: 'includes 2 seats',
    breakdownTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.breakdown`,
    showPricingPageURL: true,
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.plan_data.fin`,
    },
  },
  [INBOX_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.name`,
    nameWithProduct: 'Inbox Pro',
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.name_with_product`,
    marketingName: 'Team Inbox - Pro',
    marketingNameTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.marketing_name`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    description: 'Answer customer questions faster through a collaborative, shared Inbox',
    descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.description`,
    breakdown: 'includes 2 seats',
    breakdownTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.breakdown`,
    prefix: 'Everything in Essential, plus:',
    prefixTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.prefix`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${INBOX_PRO_ID}.plan_data.marketing_features.bot-rules`,
      `app.lib.billing.${INBOX_PRO_ID}.plan_data.marketing_features.qualification`,
      `app.lib.billing.${INBOX_PRO_ID}.plan_data.marketing_features.conversations`,
    ],
    showPricingPageURL: true,
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.plan_data.fin`,
    },
  },
  [INBOX_PREMIUM_ID]: {
    name: 'Premium',
    nameTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.name`,
    nameWithProduct: 'Inbox Premium',
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.name_with_product`,
    marketingName: 'Team Inbox - Premium',
    marketingNameTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_name`,
    description:
      'Build a world-class sales and support experience with SLAs, workload management, advanced customization, and more',
    descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.description`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    prefix: 'Everything in Pro, plus:',
    prefixTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.prefix`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_features.reporting`,
      `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_features.charts`,
      `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_features.events`,
      `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_features.route-convos`,
      `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.marketing_features.styling`,
    ],
    showPricingPageURL: true,
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.plan_data.fin`,
    },
  },
  [MESSAGES_ESSENTIAL_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.plan_data.name`,
    nameWithProduct: 'Messages Essential',
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.plan_data.name_with_product`,
    marketingName: 'Outbound Messages - Essential',
    marketingNameTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.plan_data.marketing_name`,
    icon: assetUrl('/assets/images/products/id-2-small-2x.png'),
    description:
      'Use targeted email, push, and in-app messages to encourage customers to take action',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.plan_data.description`,
    breakdown: 'includes 200 active people',
    breakdownTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.plan_data.breakdown`,
    showPricingPageURL: true,
  },
  [MESSAGES_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.name`,
    nameWithProduct: 'Messages Pro',
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.name_with_product`,
    marketingName: 'Outbound Messages - Pro',
    marketingNameTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_name`,
    icon: assetUrl('/assets/images/products/id-2-small-2x.png'),
    description:
      'Use targeted email, push, and in-app messages to encourage customers to take action',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.description`,
    breakdown: 'includes 200 active people',
    breakdownTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.breakdown`,
    prefix: 'Everything in Essential, plus:',
    prefixTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.prefix`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_features.ab-testing`,
      `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_features.templates`,
      `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_features.email-delivery`,
      `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_features.scheduled-messages`,
      `app.lib.billing.${MESSAGES_PRO_ID}.plan_data.marketing_features.sms-notifications`,
    ],
    showPricingPageURL: true,
  },
  [MESSAGES_PREMIUM_ID]: {
    name: 'Premium',
    nameTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.name`,
    nameWithProduct: 'Messages Premium',
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.name_with_product`,
    marketingName: 'Outbound Messages - Premium',
    marketingNameTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_name`,
    icon: assetUrl('/assets/images/products/id-2-small-2x.png'),
    description: 'Drive engagement and revenue at scale with ABM and advanced A/B testing',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.description`,
    prefix: 'Everything in Pro, plus:',
    prefixTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.prefix`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.ab-testing`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.beta-programs`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.custom-api-limits`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.customize-messenger`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.dynamic-messages`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.free-events`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.messenger-styling`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.custom-roles`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.unbranded`,
      `app.lib.billing.${MESSAGES_PREMIUM_ID}.plan_data.marketing_features.activity-logs`,
    ],
    showPricingPageURL: true,
  },
  [ARTICLES_ESSENTIAL_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.name`,
    marketingName: 'Help Center Articles - Essential',
    marketingNameTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Articles Essential',
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.name_with_product`,
    uniqueFeatureKey: 'help_center',
    description:
      'Publish articles to a knowledge base to provide your customers with self-service support',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.description`,
    features: [
      'Automatically suggest articles to customers',
      'Report on searches, views, and user feedback',
      'Import content from existing help center',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.features.suggest-articles`,
      `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.features.report`,
      `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.features.import`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_articles.png'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_articles@2x.png'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png'),
    id: ARTICLES_ESSENTIAL_ID,
    key: 'articles',
    marketoFormField: 'Add_On_Articles__c',
    breakdown: 'unlimited articles',
    breakdownTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.plan_data.breakdown`,
  },
  [ARTICLES_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.name`,
    nameWithProduct: 'Articles Pro',
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.name_with_product`,
    marketingName: 'Help Center Articles - Pro',
    marketingNameTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.marketing_name`,
    productId: ARTICLES_ID,
    description:
      'Publish articles to a knowledge base to provide your customers with self-service support',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.description`,
    initialTier: 'unlimited articles',
    addon: true,
    features: [
      'Control who sees your help content',
      'Support customers in 38 languages',
      'Access and share private articles in conversations',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.features.help-content`,
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.features.languages`,
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.features.private-articles`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_articles.png'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_articles@2x.png 2x'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png'),
    id: ARTICLES_PRO_ID,
    key: 'articles_pro',
    marketoFormField: 'Add_On_Articles_Pro__c',
    breakdown: 'unlimited articles',
    breakdownTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.breakdown`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.marketing_features.private-articles`,
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.marketing_features.languages`,
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.marketing_features.help-center`,
      `app.lib.billing.${ARTICLES_PRO_ID}.plan_data.marketing_features.brandless`,
    ],
  },
  [OPERATOR_PRO_ID]: {
    name: 'Workflows',
    nameTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.name`,
    productId: OPERATOR_ID,
    description:
      'Customize your bot in minutes to get more qualified leads and accelerate your sales cycles',
    descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.description`,
    breakdown: 'includes 200 active people',
    breakdownTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.breakdown`,
    marketingName: 'Workflows',
    marketingNameTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Workflows',
    nameWithProductTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.name_with_product`,
    addon: true,
    features: [
      'Point and click bot builder',
      'Pre-built bot templates to get started fast',
      'Bot performance reports',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.features.bot-builder`,
      `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.features.templates`,
      `app.lib.billing.${OPERATOR_PRO_ID}.plan_data.features.reports`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_custombots.png'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_custombots@2x.png 2x'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png'),
    id: OPERATOR_PRO_ID,
    key: 'custom_bots',
    marketoFormField: 'Add_On_Custom_Bots__c',
  },
  [ANSWER_BOT_ESSENTIAL_ID]: {
    name: 'Resolution Bot',
    nameTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.name`,
    marketingName: 'Resolution Bot',
    marketingNameTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Resolution Bot',
    nameWithProductTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.name_with_product`,
    description:
      'A chat bot that intelligently answers common questions so your team doesn’t have to',
    descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.description`,
    addon: true,
    selfTrialAvailableOnContract: true,
    features: [
      'Deliver precise, accurate answers, 24/7',
      'Improve your customer response time by 44%',
      'Free up your team to focus on more productive work',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.features.answers`,
      `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.features.response`,
      `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.features.productive`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_answersbot.png'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_answersbot.png 2x'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png 2x'),
    id: ANSWER_BOT_ESSENTIAL_ID,
    key: 'answer_bot',
    marketoFormField: 'Add_On_Answer_Bots__c',
    breakdown: 'includes 200 active people',
    breakdownTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.breakdown`,
    trialConfirmationModalText: 'Setup your Resolution Bot and set your answers live',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.plan_data.trial-confirmation-modal-text`,
    trialBannerLinkText: 'How to create an effective answer',
    trialBannerLinkUrl:
      'https://www.intercom.com/help/en/articles/2408760-create-resolution-bot-answers',
    productRoute: 'apps.app.automation.resolution-bot.answers',
  },
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    name: 'Product Tours',
    nameTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.name`,
    marketingName: 'Product Tours', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Product Tours', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.name_with_product`,
    productId: PRODUCT_TOURS_ID,
    description: 'Drive adoption with interactive tours inside your product', // deprecated bare string in favour of below TranslationKey field
    descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.description`,
    breakdown: 'includes 200 active people',
    breakdownTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.breakdown`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.marketing_features.tours`,
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.marketing_features.video-tours`,
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.marketing_features.builder`,
    ],
    addon: true,
    planSvg: 'upgrade/product-tours',
    selfTrialAvailableOnContract: true,
    features: ['Targeted guided tours', 'Video tours', 'Easy, code-free builder'],
    featuresTranslationKeys: [
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.features.tours`,
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.features.video-tours`,
      `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.features.builder`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_producttours.svg'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_producttours.svg'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png 2x'),
    id: PRODUCT_TOURS_ESSENTIAL_ID,
    key: 'product_tours',
    marketoFormField: 'Add_On_Product_Tours__c',
    basketTag: 'add-on', // deprecated bare string in favour of below TranslationKey field
    basketTagTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.basket_tag`,
    trialConfirmationModalText: 'Set your first tour live today.',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.trial-confirmation-modal-text`,
    trialBannerLinkText: 'Learn how to design a tour',
    trialBannerLinkUrl:
      'https://www.intercom.com/help/en/articles/2900887-design-your-product-tour',
    productRoute: 'apps.app.tours',
    productVideo: '30a07tqrho',
    strapline: 'Guide customers through your product to help onboard them and see value faster.', // deprecated bare string in favour of below TranslationKey field
    straplineTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.strapline`,
    benefits: [
      {
        title: 'Drive onboarding and adoption', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.drive_onboarding_and_adoption.title`,
        description:
          'Help customers discover their next best step with interactive multi-step tours.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.drive_onboarding_and_adoption.description`,
        icon: 'product-tours-list',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Erase customer pain points', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.erase_customer_pain_points.title`,
        description: 'Automatically send tours to give customers proactive guidance.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.erase_customer_pain_points.description`,
        hasIcIllustrativeIcon: true,
        icon: 'post',
      },
      {
        title: 'Increase customer engagement', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.increase_customer_engagement.title`,
        description: 'Generate up to 7x more engagement with multipage, click-to advance tours.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.increase_customer_engagement.description`,
        icon: 'upgrade/feature-responsetime',
      },
      {
        title: 'Build tours in minutes', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.build_tours_in_min.title`,
        description: 'Easily create engaging, personalized tours with video. All without code', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.plan_data.benefits.build_tours_in_min.description`,
        icon: 'upgrade/tours-in-minutes',
      },
    ],
  },
  [CORE_GOOD_ID]: {
    name: 'Start',
    nameTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.name`,
    marketingName: 'Start plan',
    marketingNameTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Start plan',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/start',
    productId: CORE_ID,
    description: 'Basic live chat and outbound messaging.',
    descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.description`,
    breakdown: 'includes 1 seat',
    breakdownTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.breakdown`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_features.included_seat`,
      `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_features.live_chat`,
      `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_features.targeted_chat`,
      `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_features.inbox`,
      `app.lib.billing.${CORE_GOOD_ID}.plan_data.marketing_features.slack`,
    ],
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.plan_data.fin`,
    },
  },
  [CORE_GROW_ID]: {
    name: 'Grow',
    nameTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.name`,
    marketingName: 'Grow plan',
    marketingNameTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Grow plan',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/grow',
    description: 'Chat, targeted email and self-service support.',
    descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.description`,
    breakdown: 'includes 5 seats',
    breakdownTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.breakdown`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.included_seats`,
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.email`,
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.help_center`,
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.macros_with_actions_billing_feature`,
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.ratings`,
      `app.lib.billing.${CORE_GROW_ID}.plan_data.marketing_features.integrations`,
    ],
    prefix: 'Everything in Start, plus:',
    prefixTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.prefix`,
    trialConfirmationModalTitle: 'Your Grow trial has started',
    trialConfirmationModalText:
      'You now have access to all features included in the Grow plan free for 14 days.',
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.plan_data.fin`,
    },
  },
  [CORE_ACCELERATE_ID]: {
    name: 'Accelerate',
    nameTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.name`,
    marketingName: 'Accelerate plan', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Accelerate plan', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/accelerate',
    description: 'Automated workflows, bots, and reporting.',
    descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.description`,
    breakdown: 'includes 10 seats',
    breakdownTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.breakdown`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.included_seats`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.bots`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.inboxes`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.rules`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.help_center`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.reports`,
      `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.marketing_features.integrations`,
    ],
    prefix: 'Everything in Grow, plus:',
    prefixTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.prefix`,
    trialConfirmationModalTitle: 'Your Accelerate trial has started',
    trialConfirmationModalText:
      'You now have access to all features included in the Accelerate plan free for 14 days.',
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.plan_data.fin`,
    },
  },
  [CORE_SCALE_ID]: {
    name: 'Scale',
    nameTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.plan_data.name`,
    nameWithProduct: 'Scale plan',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/scale',
    description: 'Advanced workflows, permissions and security.',
    descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.plan_data.description`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.included_seats`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.sla_rules`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.workload_management`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.permissions`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.sso_saml`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.api_limits`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.training`,
      `app.lib.billing.${CORE_SCALE_ID}.plan_data.marketing_features.consulatation`,
    ],
    prefix: 'Everything in Accelerate, plus:',
    prefixTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.plan_data.prefix`,
  },
  [LEAD_GENERATION_BASE_ID]: {
    name: 'Advanced Lead Generation',
    nameTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.name`,
    nameWithProduct: 'Advanced Lead Generation',
    nameWithProductTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.name_with_product`,
    marketingName: 'Advanced Lead Generation', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.marketing_name`,
    description: 'Grow your pipeline with ABM and sales integrations.',
    descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.description`,
    addon: true,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.marketing_features.marketing`,
      `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.marketing_features.salesforce`,
      `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.marketing_features.integrations`,
      `app.lib.billing.${LEAD_GENERATION_BASE_ID}.plan_data.marketing_features.send-apps`,
    ],
  },
  [SUPPORT_AUTOMATION_BASE_ID]: {
    name: 'Advanced Support Automation',
    nameTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.name`,
    nameWithProduct: 'Advanced Support Automation',
    nameWithProductTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.name_with_product`,
    marketingName: 'Advanced Support Automation', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.marketing_name`,
    description: 'Provide faster answers and automate actions with Resolution Bot.',
    descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.description`,
    addon: true,
    selfTrialAvailableOnContract: true,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.marketing_features.automate`,
      `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.marketing_features.reporting`,
      `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.marketing_features.send-apps`,
    ],
    trialConfirmationModalText: 'Setup your Resolution Bot and set your answers live',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.plan_data.trial-confirmation-modal-text`,
    trialBannerLinkText: 'How to create an effective answer',
    trialBannerLinkUrl: 'https://www.intercom.com/help/en/articles/7860253-creating-custom-answers',
    productRoute: 'apps.app.automation.resolution-bot.answers',
  },
  [ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID]: {
    name: 'Advanced Customer Engagement',
    nameTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.name`,
    marketingName: 'Advanced Customer Engagement', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Advanced Customer Engagement', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.name_with_product`,
    description: 'Drive growth with automated multichannel campaigns.',
    descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.description`,
    addon: true,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.marketing_features.notifications`,
      `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.marketing_features.series`,
      `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.marketing_features.ab-testing`,
      `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.marketing_features.integrations`,
    ],
    basketTag: 'expansion', // deprecated bare string in favour of below TranslationKey field
    basketTagTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.plan_data.basket_tag`,
  },
  [CORE_STARTER_BASE_ID]: {
    name: 'Starter',
    nameTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.name`,
    productId: CORE_STARTER_ID,
    marketingName: 'Starter',
    marketingNameTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Starter', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.name_with_product`,
    description: 'Support, onboard, and engage customers with Starter. ',
    descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.description`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.seats`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.support`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.email`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.messages`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.inbox`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.help-center`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.routing`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.macros_with_actions_billing_feature`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.behavioral-data`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.dashboards`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.ratings`,
      `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.marketing_features.integrations`,
    ],
    upgradePath: VBP2_DEFAULT_UPGRADE_PATH,
    fromPricePerMonth: 74,
    pricePerSeat: 19,
    badge: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.badge.description`,
    },
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.plan_data.fin`,
    },
  },
  [CORE_SUPPORT_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.plan_data.name`,
    key: 'support_pro',
    productId: CORE_SUPPORT_ID,
    marketingName: 'Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Support Pro', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.plan_data.name_with_product`,
    upgradePath: [PROACTIVE_SUPPORT_BASE_ID, ...VBP2_DEFAULT_UPGRADE_PATH],
    trialConfirmationModalTitle: 'Your Support Pro trial has started',
    trialConfirmationModalText:
      'You now have access to all features included in the Support Pro plan free for 14 days.',
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.plan_data.fin`,
    },
    pricePerMonth: 395,
    pricePerSeat: 79,
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    name: 'Premium',
    nameTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.plan_data.name`,
    key: 'support_premium',
    productId: CORE_SUPPORT_ID,
    marketingName: 'Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Support Premium',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.plan_data.name_with_product`,
    upgradePath: [PROACTIVE_SUPPORT_BASE_ID, ...VBP2_DEFAULT_UPGRADE_PATH],
    finFeatureComponent: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.plan_data.fin`,
    },
  },
  [CORE_ENGAGE_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.plan_data.name`,
    key: 'engage_pro',
    productId: CORE_ENGAGE_ID,
    marketingName: 'Engage', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.plan_data.maketing_name`,
    nameWithProduct: 'Engage Pro', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.plan_data.name_with_product`,
    upgradePath: [CORE_ENGAGE_PREMIUM_ID, ...VBP2_DEFAULT_UPGRADE_PATH],
  },
  [CORE_ENGAGE_PREMIUM_ID]: {
    name: 'Premium',
    nameTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.plan_data.name`,
    key: 'engage_premium',
    productId: CORE_ENGAGE_ID,
    marketingName: 'Engage', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Engage Premium',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.plan_data.name_with_product`,
    upgradePath: VBP2_DEFAULT_UPGRADE_PATH,
  },
  [CORE_CONVERT_PRO_ID]: {
    name: 'Pro',
    nameTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.plan_data.name`,
    key: 'marketing_pro',
    productId: CORE_CONVERT_ID,
    marketingName: 'Convert', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Convert Pro',
    nameWithProductTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.plan_data.name_with_product`,
    upgradePath: VBP2_DEFAULT_UPGRADE_PATH,
  },
  [PROACTIVE_SUPPORT_BASE_ID]: {
    name: 'Proactive Support',
    nameTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.plan_data.name`,
    key: 'proactive_support',
    productId: PROACTIVE_SUPPORT_ID,
    marketingName: 'Proactive Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Proactive Support',
    nameWithProductTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.plan_data.name_with_product`,
  },
  [ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID]: {
    name: 'Advanced Workspace Management',
    nameTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.plan_data.name`,
    key: 'advanced_workspace_management',
    productId: ADVANCED_WORKSPACE_MANAGEMENT_ID,
    marketingName: 'Advanced Workspace Management', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Advanced Workspace Management',
    nameWithProductTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.plan_data.name_with_product`,
  },
  [CUSTOM_API_RATE_LIMIT_BASE_ID]: {
    name: 'Custom API Rate Limit',
    nameTranslationKey: `app.lib.billing.${CUSTOM_API_RATE_LIMIT_BASE_ID}.plan_data.name`,
    productId: CUSTOM_API_RATE_LIMIT_BASE_ID,
    marketingName: 'Custom API Rate Limit', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${CUSTOM_API_RATE_LIMIT_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Custom API Rate Limit',
    nameWithProductTranslationKey: `app.lib.billing.${CUSTOM_API_RATE_LIMIT_BASE_ID}.plan_data.name_with_product`,
  },
  [PEOPLE_REACHED_TIER_1_ID]: {
    name: 'Tier 1',
    nameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_1_ID}.plan_data.name`,
    key: 'people_reached_tier_1',
    productId: PEOPLE_REACHED_ID,
    marketingName: 'People Reached Tier 1', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_1_ID}.plan_data.marketing_name`,
    nameWithProduct: 'People Reached',
    nameWithProductTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_1_ID}.plan_data.name_with_product`,
  },
  [PEOPLE_REACHED_TIER_2_ID]: {
    name: 'Tier 2',
    nameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_2_ID}.plan_data.name`,
    key: 'people_reached_tier_2',
    productId: PEOPLE_REACHED_ID,
    marketingName: 'People Reached Tier 2', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_2_ID}.plan_data.marketing_name`,
    nameWithProduct: 'People Reached',
    nameWithProductTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_2_ID}.plan_data.name_with_product`,
  },
  [PEOPLE_REACHED_TIER_3_ID]: {
    name: 'Tier 3',
    nameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_3_ID}.plan_data.name`,
    key: 'people_reached_tier_3',
    productId: PEOPLE_REACHED_ID,
    marketingName: 'People Reached Tier 3', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_3_ID}.plan_data.marketing_name`,
    nameWithProduct: 'People Reached',
    nameWithProductTranslationKey: `app.lib.billing.${PEOPLE_REACHED_TIER_3_ID}.plan_data.name_with_product`,
  },
  [WHATSAPP_BASE_ID]: {
    name: 'WhatsApp',
    nameTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.name`,
    productId: WHATSAPP_ID,
    marketingName: 'WhatsApp', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'WhatsApp', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.name_with_product`,
    addon: true,
    selfTrialAvailableOnContract: true,
    description: 'Meet your customers where they are with WhatsApp', // deprecated bare string in favour of below TranslationKey field
    descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.description`,
    features: [
      'WhatsApp conversations in your inbox',
      'Seamless messenger-WhatsApp handoff',
      'Fully integrated with reporting',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.features.conversations`,
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.features.seamless-handoff`,
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.features.reporting`,
    ],
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.marketing_features.conversations`,
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.marketing_features.seamless-handoff`,
      `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.marketing_features.reporting`,
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_whatsapp.svg'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_whatsapp.svg'),
    id: WHATSAPP_BASE_ID,
    key: 'whatsapp',
    basketTag: 'add-on', // deprecated bare string in favour of below TranslationKey field
    basketTagTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.basket_tag`,
    pricingPerSeat: true,
    productVideo: 'avraryv9mo',
    strapline: "Support your customers when it's most convenient for them with WhatsApp.", // deprecated bare string in favour of below TranslationKey field
    straplineTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.strapline`,
    benefits: [
      {
        title: 'Keep customers happy', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.keep_customer_happy.title`,
        description: "Give customers an easy way to reach out when it's most convenient for them.", // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.keep_customer_happy.description`,
        icon: 'upgrade/conversation',
      },
      {
        title: 'Make replying to WhatsApp a breeze', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.whatsapp_reply_breeze.title`,
        description: 'Handle all conversations from one place—the Intercom Inbox.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.whatsapp_reply_breeze.description`,
        icon: 'upgrade/goal',
      },
      {
        title: 'From Messenger to WhatsApp', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.from_messenger_to_whatsapp.title`,
        description: 'Move from the Messenger to WhatsApp to keep the conversation going.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.from_messenger_to_whatsapp.description`,
        icon: 'upgrade/optimize-performance',
      },
      {
        title: 'Fully integrated with Reporting', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.fully_integrated_reporting.title`,
        description: 'See how WhatsApp stacks up against your other channels with reports.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.benefits.fully_integrated_reporting.description`,
        icon: 'upgrade/chart',
      },
    ],
    trialConfirmationModalText: 'Install the WhatsApp app, then setup your account to get started!',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.plan_data.trial-confirmation-modal`,
    trialBannerLinkText: 'Finish setting up WhatsApp',
    trialBannerLinkUrl: '/a/apps/_/appstore?app_package_code=whatsapp-fqam&tab=settings',
    trialBannerSecondaryLinkText: 'How to use WhatsApp',
    trialBannerSecondaryLinkUrl: 'https://www.intercom.com/help/en/articles/5454490-whatsapp-app',
  },
  [PREMIER_ONBOARDING_BASE_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_BASE_ID}.plan_data.name`,
    key: 'premier_onboarding_base',
    id: PREMIER_ONBOARDING_BASE_ID,
    productId: PREMIER_ONBOARDING_ID,
    marketingName: 'Premier Onboarding', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Onboarding',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_BASE_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_BASE_ID}.plan_data.basket_tag`,
  },
  [PREMIER_ONBOARDING_PLUS_ID]: {
    name: 'Plus',
    nameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_PLUS_ID}.plan_data.name`,
    key: 'premier_onboarding_plus',
    id: PREMIER_ONBOARDING_PLUS_ID,
    productId: PREMIER_ONBOARDING_ID,
    marketingName: 'Premier Onboarding', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_PLUS_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Onboarding Plus',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_PLUS_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_PLUS_ID}.plan_data.basket_tag`,
  },
  [PREMIER_SUCCESS_BASE_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUCCESS_BASE_ID}.plan_data.name`,
    key: 'premier_success_base',
    id: PREMIER_SUCCESS_BASE_ID,
    productId: PREMIER_SUCCESS_ID,
    marketingName: 'Premier Success', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUCCESS_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Success',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUCCESS_BASE_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_SUCCESS_BASE_ID}.plan_data.basket_tag`,
  },
  [PREMIER_SUPPORT_TIER_1_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_1_ID}.plan_data.name`,
    key: 'premier_support_tier_1',
    id: PREMIER_SUPPORT_TIER_1_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_1_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_1_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_TIER_2_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_2_ID}.plan_data.name`,
    key: 'premier_support_tier_2',
    id: PREMIER_SUPPORT_TIER_2_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support',
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_2_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_2_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_TIER_3_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_3_ID}.plan_data.name`,
    key: 'premier_support_tier_3',
    id: PREMIER_SUPPORT_TIER_3_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_3_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_3_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_TIER_4_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_4_ID}.plan_data.name`,
    key: 'premier_support_tier_4',
    id: PREMIER_SUPPORT_TIER_4_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_4_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_TIER_4_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID}.plan_data.name`,
    key: 'premier_support_rapid_response_tier_1',
    id: PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support Rapid Response',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_1_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID}.plan_data.name`,
    key: 'premier_support_rapid_response_tier_2',
    id: PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support Rapid Response',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_2_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID}.plan_data.name`,
    key: 'premier_support_rapid_response_tier_3',
    id: PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support Rapid Response',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_3_ID}.plan_data.name_with_product`,
  },
  [PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID]: {
    name: 'Base',
    nameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID}.plan_data.name`,
    key: 'premier_support_rapid_response_tier_4',
    id: PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID,
    productId: PREMIER_SUPPORT_ID,
    marketingName: 'Premier Support', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Support Rapid Response',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_SUPPORT_RAPID_RESPONSE_TIER_4_ID}.plan_data.name_with_product`,
  },
  [SURVEYS_BASE_ID]: {
    name: 'Surveys',
    nameTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.name`,
    productId: SURVEYS_ID,
    marketingName: 'Surveys', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Surveys', // deprecated bare string in favour of below TranslationKey field
    nameWithProductTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.name_with_product`,
    addon: true,
    selfTrialAvailableOnContract: true,
    description: 'Seamlessly capture first-party data and act on customer feedback and needs', // deprecated bare string in favour of below TranslationKey field
    descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.description`,
    features: [
      'Simple to set-up and customize to your brand',
      'Connect with customers via multiple channels - in-product, mobile, email, and more',
      'Identify target audiences using your customer and event data',
      'Build responsive workflows for follow-ups, onboarding, sales, and more!',
    ],
    featuresTranslationKeys: [
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.features.customize`,
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.features.multi-channel`,
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.features.target`,
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.features.workflows`,
    ],
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.marketing_features.response`,
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.marketing_features.data`,
      `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.marketing_features.follow-ups`,
    ],
    id: SURVEYS_BASE_ID,
    basketTag: 'add-on', // deprecated bare string in favour of below TranslationKey field
    basketTagTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.basket_tag`,
    key: 'surveys',
    pricingPerSeat: false,
    planSvg: 'upgrade/chart',
    icon: assetUrl('/assets/images/signup-teams/pricing_surveys.svg'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_surveys.svg'),
    productVideo: '4jm263z5t3',
    strapline: 'Seamlessly capture and act on customer feedback and needs', // deprecated bare string in favour of below TranslationKey field
    straplineTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.strapline`,
    benefits: [
      {
        title: 'Put your insights to work', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.put_insights_to_work.title`,
        description:
          "Turn insights into action with workflows to improve your customer's experience.", // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.put_insights_to_work.description`,
        icon: 'upgrade/powerful-workflows',
      },
      {
        title: 'Increase Survey engagement', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.increase_survey_engagement.title`,
        description: 'Increase your response rate by reaching your customers in the moment.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.increase_survey_engagement.description`,
        icon: 'upgrade/increased-response-rate',
      },
      {
        title: 'Leverage existing data', // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.leverage_existing_data.title`,
        description:
          'Reach out to the right customers by using the data you already have in Intercom.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.leverage_existing_data.description`,
        icon: 'upgrade/setup-and-customization',
      },
      {
        title: "Personalize your customer's experience", // deprecated bare string in favour of below TranslationKey field
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.personalize_customer_experience.title`,
        description: 'Drive customer action based on their unique needs and preferences.', // deprecated bare string in favour of below TranslationKey field
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.benefits.personalize_customer_experience.description`,
        icon: 'upgrade/powerful-targeting',
      },
    ],
    trialConfirmationModalText: 'Set your first survey live today.',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.plan_data.trial_confirmation_modal_text`,
    trialBannerLinkText: '6 great ways to use Surveys',
    trialBannerLinkUrl:
      'https://www.intercom.com/help/en/articles/5959219-6-great-ways-to-use-surveys',
    productRoute: 'apps.app.outbound.survey',
    trialOnboardingWistiaId: 'f2b2d3ik5c',
  },
  [SWITCH_BASE_ID]: {
    name: 'Switch',
    nameTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.name`,
    productId: SWITCH_ID,
    marketingName: 'Switch', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Switch',
    nameWithProductTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.name_with_product`,
    description:
      'Reduce wait times by giving your customers the choice to switch to messaging mid-call',
    descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.description`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${SWITCH_BASE_ID}.plan_data.marketing_features.resolution`,
      `app.lib.billing.${SWITCH_BASE_ID}.plan_data.marketing_features.efficiency`,
      `app.lib.billing.${SWITCH_BASE_ID}.plan_data.marketing_features.experiences`,
    ],
    addon: true,
    selfTrialAvailableOnContract: true,
    id: SWITCH_BASE_ID,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.basket_tag`,
    key: 'switch',
    productImage: 'products/switch',
    trialConfirmationModalText: 'Set-up Switch today.',
    trialConfirmationModalTextTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.plan_data.trial_confirmation_modal_text`,
    trialBannerLinkText: 'Install and set up Switch',
    trialBannerLinkUrl:
      'https://www.intercom.com/help/en/articles/5997892-install-and-set-up-switch',
    productRoute: 'apps.app.settings.channels.switch',
  },
  [SMS_BASE_ID]: {
    name: 'SMS',
    nameTranslationKey: `app.lib.billing.${SMS_BASE_ID}.plan_data.name`,
    productId: SMS_ID,
    marketingName: 'SMS', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${SMS_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'SMS',
    nameWithProductTranslationKey: `app.lib.billing.${SMS_BASE_ID}.plan_data.name_with_product`,
    description:
      'Our two-way SMS lets you have back-and-forth customer conversations, and send real-time promotional offers, transactional messaging, and more',
    descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.plan_data.description`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${SMS_BASE_ID}.plan_data.marketing_features.texts`,
      `app.lib.billing.${SMS_BASE_ID}.plan_data.marketing_features.easy`,
      `app.lib.billing.${SMS_BASE_ID}.plan_data.marketing_features.reporting`,
    ],
    addon: true,
    id: SMS_BASE_ID,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${SMS_BASE_ID}.plan_data.basket_tag`,
    key: 'sms',
    productImage: 'products/sms',
  },
  [CORE_EARLY_STAGE_ENGAGEMENT_BUNDLE.toString()]: {
    name: 'Early Stage',
    nameTranslationKey: `app.lib.billing.plan_data.core_early_stage_engagement_bundle.name`,
    idAsNumber: 20,
    marketingName: 'Early Stage', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_name',
    marketingNameTranslationKeyWithYearParam:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_name_with_year',
    nameWithProduct: 'Accelerate, Product Tours and Advanced Customer Engagement',
    nameWithProductTranslationKey:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.name_with_product',
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/accelerate',
    description: 'Automated workflows, bots, and reporting.',
    descriptionTranslationKey:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.description',
    breakdown: 'includes 10 seats',
    breakdownTranslationKey:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.breakdown',
    marketingFeaturesTranslationKeys: [
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.seats',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.bots',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.inboxes',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.rules',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.campaigns',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.reports',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.integrations',
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_features.tours',
    ],
    prefix: 'Everything in Grow, plus:',
    prefixTranslationKey: 'app.lib.billing.plan_data.core_early_stage_engagement_bundle.prefix',
    isEarlyStage: true,
    selfServe: true,
  },
  [VBP_MAR2021_EARLY_STAGE_SEP2021_BUNDLE.toString()]: {
    name: 'Early Stage',
    nameTranslationKey: `app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.name`,
    idAsNumber: 29,
    marketingName: 'Early Stage', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey:
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_name',
    marketingNameTranslationKeyWithYearParam:
      'app.lib.billing.plan_data.core_early_stage_engagement_bundle.marketing_name_with_year',
    nameWithProduct: 'Accelerate, Product Tours and Advanced Customer Engagement',
    nameWithProductTranslationKey:
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.name_with_product',
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/accelerate',
    description: 'Automated workflows, bots, and reporting.',
    descriptionTranslationKey:
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.description',
    breakdown: 'includes 10 seats',
    breakdownTranslationKey:
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.breakdown',
    marketingFeaturesTranslationKeys: [
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.seats',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.bots',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.inboxes',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.rules',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.campaigns',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.reports',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.integrations',
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.marketing_features.tours',
    ],
    prefix: 'Everything in Starter, plus:',
    prefixTranslationKey: 'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_bundle.prefix',
    isEarlyStage: true,
    selfServe: true,
  },
  [PRICING_5_EARLY_STAGE_BUNDLE.toString()]: {
    name: 'Pricing 5 Early Stage',
    nameTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_bundle.name`,
    idAsNumber: 54,
    marketingName: 'Early Stage', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey:
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_name',
    marketingNameTranslationKeyWithYearParam:
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_name',
    nameWithProduct: 'Help Desk, AI Chatbot and Proactive Support',
    nameWithProductTranslationKey:
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.name_with_product',
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    planSvg: 'upgrade/accelerate',
    description: 'Scale the personalized support your customers love as your business matures.',
    descriptionTranslationKey: 'app.lib.billing.plan_data.pricing_5_early_stage_bundle.description',
    breakdown: 'includes 6 seats',
    breakdownTranslationKey: 'app.lib.billing.plan_data.pricing_5_early_stage_bundle.breakdown',
    marketingFeatures: [
      'Includes 10 seats',
      'Custom bots',
      'Multiple team inboxes',
      'Automated assignment rules',
      'Campaign orchestration',
      'Custom reports',
      'Salesforce & Zendesk Support integrations',
      'Product Tours',
    ],
    marketingFeaturesTranslationKeys: [
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.inboxes',
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.macros',
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.workflows',
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.articles',
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.attributes',
      'app.lib.billing.plan_data.pricing_5_early_stage_bundle.marketing_features.seats',
    ],
    prefix: 'Everything in Essential, plus:',
    prefixTranslationKey: 'app.lib.billing.plan_data.pricing_5_early_stage_bundle.prefix',
    isEarlyStage: true,
    selfServe: true,
  },
  [PRICING_5_X_CORE_ESSENTIAL_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Essential',
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    nameWithProductTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    productImage: 'products/sms',
    productId: PRICING_5_X_CORE_ID,
    addon: false,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.description`,
    breakdownTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.breakdown`,
    basketTagTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.basket_tag`,
    pricePerSeat: 39,
    liteSeatLimit: 0,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.messenger`,
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.system`,
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.inbox`,
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.center`,
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.workflows`,
      `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_features.replies`,
    ],
    checkoutTitleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.name`,
    checkoutDescriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.checkout_description`,
  },
  [PRICING_5_X_CORE_ADVANCED_ID]: {
    name: 'Advanced',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Advanced',
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    nameWithProductTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    productImage: 'products/sms',
    productId: PRICING_5_X_CORE_ID,
    addon: false,
    liteSeatLimit: 20,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.description`,
    breakdownTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.breakdown`,
    basketTagTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.basket_tag`,
    pricePerSeat: 99,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.inboxes`,
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.macros_with_actions_billing_feature`,
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.workflows`,
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.articles`,
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.attributes`,
      `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_features.seats`,
    ],
    prefix: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.prefix`,
    prefixTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.prefix`,
    checkoutTitleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.name`,
    checkoutDescriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.checkout_description`,
  },
  [PRICING_5_X_CORE_EXPERT_ID]: {
    name: 'Expert',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Expert',
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    nameWithProductTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    productImage: 'products/sms',
    productId: PRICING_5_X_CORE_ID,
    addon: false,
    liteSeatLimit: 50,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.description`,
    breakdownTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.breakdown`,
    basketTagTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.basket_tag`,
    pricePerSeat: 139,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.workload`,
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.messenger`,
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.saml`,
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.switch`,
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.api`,
      `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.marketing_features.seats`,
    ],
    prefix: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.prefix`,
    checkoutTitleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.checkout_title`,
    checkoutDescriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_EXPERT_ID}.plan_data.checkout_description`,
  },
  [PROACTIVE_SUPPORT_PLUS_BASE_ID]: {
    name: 'Proactive Support Plus',
    nameTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Proactive Support Plus',
    startingFromText: 'paywalls.comparison.plan-cards.starting_from_text',
    nameWithProductTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.name_with_product`,
    icon: assetUrl('/assets/images/products/id-1-small-2x.png'),
    productImage: 'products/sms',
    productId: PROACTIVE_SUPPORT_PLUS_ID,
    addon: true,
    id: PROACTIVE_SUPPORT_PLUS_BASE_ID,
    descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.description`,
    breakdownTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.breakdown`,
    basketTagTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.basket_tag`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.checklists`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.product_tours`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.messages`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.resending`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.webhooks`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.testing`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.series`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.carousels`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.notifications`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.surveys`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.news`,
      `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.plan_data.marketing_features.advanced_testing`,
    ],
    includedFeatures: [
      {
        marketingTranslationKey: 'posts',
        interfaceIcon: 'post-filled',
      },
      {
        marketingTranslationKey: 'product_tours',
        interfaceIcon: 'product-tours',
      },
      {
        marketingTranslationKey: 'checklists',
        interfaceIcon: 'checklist',
      },
      {
        marketingTranslationKey: 'push_notifications',
        interfaceIcon: 'push-notification',
      },
      {
        marketingTranslationKey: 'carousels',
        interfaceIcon: 'carousel-filled',
      },
      {
        marketingTranslationKey: 'surveys',
        interfaceIcon: 'survey-filled',
      },
    ],
  },
  [PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID]: {
    name: '1 month',
    nameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID}.plan_data.name`,
    key: 'premier_onboarding',
    id: PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID,
    productId: PREMIER_ONBOARDING_3_TIERS_ID,
    marketingName: 'Premier Onboarding 30', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Onboarding 30',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_1_MONTH_ID}.plan_data.basket_tag`,
  },
  [PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID]: {
    name: '2 months',
    nameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID}.plan_data.name`,
    key: 'premier_onboarding',
    id: PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID,
    productId: PREMIER_ONBOARDING_3_TIERS_ID,
    marketingName: 'Premier Onboarding 60', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Onboarding 60',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_2_MONTHS_ID}.plan_data.basket_tag`,
  },
  [PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID]: {
    name: '3 months',
    nameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID}.plan_data.name`,
    key: 'premier_onboarding',
    id: PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID,
    productId: PREMIER_ONBOARDING_3_TIERS_ID,
    marketingName: 'Premier Onboarding 90', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Premier Onboarding 90',
    nameWithProductTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID}.plan_data.name_with_product`,
    basketTag: 'add-on',
    basketTagTranslationKey: `app.lib.billing.${PREMIER_ONBOARDING_3_TIERS_3_MONTHS_ID}.plan_data.basket_tag`,
  },
  [FIN_AI_COPILOT_BASE_ID]: {
    addon: true,
    name: 'Fin AI Copilot',
    nameTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.name`,
    key: 'fin_ai_copilot',
    descriptionTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.description`,
    id: FIN_AI_COPILOT_BASE_ID,
    productId: FIN_AI_COPILOT_ID,
    marketingName: 'Fin AI Copilot', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.marketing_name`,
    nameWithProduct: 'Fin AI Copilot',
    nameWithProductTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.name_with_product`,
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    basketTag: 'add-on',
    startingFromText: 'paywalls.comparison.plan-cards.starting_from_copilot',
    basketTagTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.basket_tag`,
    marketingFeaturesTranslationKeys: [
      `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.marketing_features.use_as_required`,
      `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.marketing_features.no_hidden_charges`,
      `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.marketing_features.monitor_usage`,
    ],
    marketingFeaturesHeadingOverride: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.marketing_features.heading`,
    manageSubscriptionSidebarHeader:
      'paywalls.upgrade-modal.sidebar.self-serve.copilot.monthly.heading',
    manageSubscriptionSidebarDescriptionItems: [
      {
        translationString:
          'paywalls.upgrade-modal.sidebar.self-serve.copilot.annual.list.descriptions.get-unlimited-usage',
        includePrice: true,
        includeDiscount: true,
      },
      {
        translationString:
          'paywalls.upgrade-modal.sidebar.self-serve.copilot.annual.list.descriptions.manage-your-subscription',
      },
    ],
    hideSubtitle: true,
    hideBadge: true,
    hideStartingFromText: true,
    alternativeHeader: true,
    planCardHeadingOverride: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.plan_data.plan_card_heading`,
    articleLink: 'https://www.intercom.com/help/en/articles/9121384-fin-ai-copilot-pricing',
  },
};

interface FeatureItem {
  key: string;
  tooltip?: string;
}

interface PlanFeature {
  left: FeatureItem[];
  right: FeatureItem[];
}

export const P5_CORE_PLAN_UPGRADE_FEATURES: { [key: string]: PlanFeature } = {
  [PRICING_5_X_CORE_EXPERT_ID]: {
    left: [
      {
        key: 'multi-help-center',
      },
      {
        key: 'multibrand-msg',
      },
      {
        key: 'realtime-dashboard',
      },
    ],
    right: [
      {
        key: 'workload-managememnt',
      },
      {
        key: 'single-sign-on',
      },
      {
        key: 'lite-seats-50',
        tooltip: 'signup.teams.pricing5.annual-plans.basket.lite-seats-tooltip-50',
      },
    ],
  },
  [PRICING_5_X_CORE_ADVANCED_ID]: {
    left: [
      {
        key: 'team-inboxes',
      },
      {
        key: 'ai-autofill',
      },
      {
        key: 'private-help-center',
      },
      {
        key: 'proactive-support',
      },
      {
        key: 'multi-lng-help-center',
      },
    ],
    right: [
      {
        key: 'ai-summerize',
      },
      {
        key: 'tickets-portal',
      },
      {
        key: 'side-conversations',
      },
      {
        key: 'worklows-advanced',
      },
      {
        key: 'lite-seats-20',
        tooltip: 'signup.teams.pricing5.annual-plans.basket.lite-seats-tooltip-20',
      },
    ],
  },
  [PRICING_5_X_CORE_ESSENTIAL_ID]: {
    left: [
      {
        key: 'shared-inbox',
      },
      {
        key: 'ticketing',
      },
      {
        key: 'macros',
      },
      {
        key: 'public-help-center',
      },
      {
        key: 'basic-automation',
      },
    ],
    right: [
      {
        key: 'fin-agent',
      },
      {
        key: 'fin-copilot',
      },
      {
        key: 'ai-compose',
      },
      {
        key: 'unlimited-articles',
      },
      {
        key: 'proactive-support',
      },
    ],
  },
};

export const PLAN_DATA_CUSTOM_ANSWERS: PlanData = {
  ...PLAN_DATA[ANSWER_BOT_ESSENTIAL_ID],
  name: 'Custom Answers',
  nameTranslationKey: `app.lib.billing.custom_answers.plan_data.name`,
  marketingName: 'Custom Answers',
  marketingNameTranslationKey: `app.lib.billing.custom_answers.plan_data.marketing_name`,
  nameWithProduct: 'Custom Answers',
  nameWithProductTranslationKey: `app.lib.billing.custom_answers.plan_data.name_with_product`,
};

// used to add content in Non-Graduating-Trials Paywalls, those are not billing features
// this list is used to display a list of marketing features for the following plans:
// CORE_GOOD_ID, CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_STARTER_BASE_ID, CORE_SUPPORT_PRO_ID, CORE_ENGAGE_PRO_ID, CORE_CONVERT_PRO_ID
export const MARKETING_PLAN_TRANSLATION_KEYS: { [key: string]: any } = {
  [CORE_GOOD_ID]: {
    descriptionTranslationKey: PLAN_DATA[CORE_GOOD_ID].descriptionTranslationKey,
    startingFromText: 'paywalls.comparison.plan-cards.starting_from_text',
    marketingFeaturesTranslationKeys: PLAN_DATA[CORE_GOOD_ID].marketingFeaturesTranslationKeys,
  },
  [CORE_GROW_ID]: {
    descriptionTranslationKey: PLAN_DATA[CORE_GROW_ID].descriptionTranslationKey,
    prefix: PLAN_DATA[CORE_GROW_ID].prefixTranslationKey,
    startingFromText: 'paywalls.comparison.plan-cards.starting_from_text',
    marketingFeaturesTranslationKeys: PLAN_DATA[CORE_GROW_ID].marketingFeaturesTranslationKeys,
  },
  [CORE_ACCELERATE_ID]: {
    descriptionTranslationKey: PLAN_DATA[CORE_ACCELERATE_ID].descriptionTranslationKey,
    prefix: PLAN_DATA[CORE_ACCELERATE_ID].prefixTranslationKey,
    startingFromText: 'paywalls.comparison.plan-cards.starting_from_text',
    marketingFeaturesTranslationKeys:
      PLAN_DATA[CORE_ACCELERATE_ID].marketingFeaturesTranslationKeys,
  },
  [CORE_STARTER_BASE_ID]: {
    descriptionTranslationKey: `paywalls.comparison.plan-cards.plans.core_starter.description`,
    startingFromText: 'paywalls.comparison.plan-cards.base_from_text',
    marketingFeaturesTranslationKeys: [
      'paywalls.comparison.plan-cards.features.messenger',
      'paywalls.comparison.plan-cards.features.inbox',
      'paywalls.comparison.plan-cards.features.channels',
      'paywalls.comparison.plan-cards.features.help_center',
      'paywalls.comparison.plan-cards.features.routing',
      'paywalls.comparison.plan-cards.features.replies',
    ],
  },
  [CORE_SUPPORT_PRO_ID]: {
    descriptionTranslationKey: `paywalls.comparison.plan-cards.plans.core_support_pro.description`,
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    marketingFeaturesTranslationKeys: [
      'paywalls.comparison.plan-cards.features.unbranded_messenger',
      'paywalls.comparison.plan-cards.features.team_inboxes',
      'paywalls.comparison.plan-cards.features.tickets',
      'paywalls.comparison.plan-cards.features.inbox_rules',
      'paywalls.comparison.plan-cards.features.macros_with_actions_billing_feature',
      'paywalls.comparison.plan-cards.features.custom_inbound_bots',
      'paywalls.comparison.plan-cards.features.salesforce_integration',
    ],
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    descriptionTranslationKey: `paywalls.comparison.plan-cards.plans.core_support_premium.description`,
    startingFromSeatText: 'paywalls.comparison.plan-cards.starting_from_text_with_seats',
    prefix: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.plan_data.prefix`,
    marketingFeaturesTranslationKeys: [
      'paywalls.comparison.plan-cards.features.office_hours',
      'paywalls.comparison.plan-cards.features.sla_rules',
      'paywalls.comparison.plan-cards.features.tickets',
      'paywalls.comparison.plan-cards.features.reports',
      'paywalls.comparison.plan-cards.features.monitoring',
      'paywalls.comparison.plan-cards.features.management',
    ],
  },
  [FIN_AI_COPILOT_BASE_ID]: {
    ...PLAN_DATA[FIN_AI_COPILOT_BASE_ID],
  },
};

export const PER_PRODUCT_PRICING_AB_MODEL = 'per_product_pricing_high';
export const EARLY_STAGE_PRODUCTS = [
  INBOX_ID,
  MESSAGES_ID,
  ARTICLES_ID,
  OPERATOR_ID,
  PRODUCT_TOURS_ID,
];

const TEAMS_SOLUTIONS: { [key: string]: any } = {
  [VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID]: {
    name: 'Starter', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey:
      'app.lib.billing.plan_data.value_based_pricing_feb2022_starter_solution_id.name',
    description: 'The perfect way to support and engage your customers across the lifecycle.', // deprecated bare string in favour of below TranslationKey field
    descriptionTranslationKey:
      'app.lib.billing.plan_data.value_based_pricing_feb2022_starter_solution_id.description_including_fin',
    recommendedAddons: [PRODUCT_TOURS_ESSENTIAL_ID, WHATSAPP_BASE_ID, SURVEYS_BASE_ID],
    pricingModel: VALUE_BASED_PRICING_FEB2022_PRICING_MODEL,
    features: [
      'Chat & email support',
      'Targeted outbound email',
      'In-product messages',
      'Slack integration',
    ],
  },
  [VBP_MAR2021_EARLY_STAGE_SEP2021_SOLUTION_ID]: {
    name: 'Early Stage Program', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey:
      'app.lib.billing.plan_data.vbp_mar2021_early_stage_sep2021_solution_id.name',
    recommendedAddons: [],
    pricingModel: VALUE_BASED_PRICING_MARCH_2021_EARLY_STAGE_PRICING_MODEL,
    features: [],
  },
  [PRICING_5_0_ESSENTIAL_SOLUTION_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.description`,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_0_PRICING_MODEL,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    liteSeatLimit: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].liteSeatLimit,
    features: [
      {
        title: 'AI Help Desk',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_help_desk`,
        pulseInterfaceIcon: 'inbox',
        pill_color: 'teal',
      },
      {
        title: 'AI Bot',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_bot`,
        pulseInterfaceIcon: 'fin',
        pill_color: 'light__blue',
      },
      {
        title: 'Proactive Support',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.proactive_support`,
        pulseIllustrativeIcon: 'outbound',
        pill_color: 'yellow',
      },
    ],
  },
  [PRICING_5_0_ADVANCED_SOLUTION_ID]: {
    name: 'Advanced',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.description`,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_0_PRICING_MODEL,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    liteSeatLimit: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].liteSeatLimit,
    features: [
      {
        title: 'AI Help Desk',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_help_desk`,
        pulseInterfaceIcon: 'inbox',
        pill_color: 'teal',
      },
      {
        title: 'AI Bot',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_bot`,
        pulseInterfaceIcon: 'fin',
        pill_color: 'light__blue',
      },
      {
        title: 'Proactive Support',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.proactive_support`,
        pulseIllustrativeIcon: 'outbound',
        pill_color: 'yellow',
      },
    ],
  },
  [PRICING_5_1_ESSENTIAL_SOLUTION_ID]: {
    name: 'Essential',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.description`,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_1_PRICING_MODEL,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    liteSeatLimit: PLAN_DATA[PRICING_5_X_CORE_ESSENTIAL_ID].liteSeatLimit,
    features: [
      {
        title: 'AI Help Desk',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_help_desk`,
        pulseInterfaceIcon: 'inbox',
        pill_color: 'teal',
      },
      {
        title: 'AI Bot',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_bot`,
        pulseInterfaceIcon: 'fin',
        pill_color: 'light__blue',
      },
      {
        title: 'Proactive Support',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.proactive_support`,
        pulseIllustrativeIcon: 'outbound',
        pill_color: 'yellow',
      },
    ],
  },
  [PRICING_5_1_ADVANCED_SOLUTION_ID]: {
    name: 'Advanced',
    nameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.name`,
    marketingNameTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ADVANCED_ID}.plan_data.description`,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_1_PRICING_MODEL,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    liteSeatLimit: PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID].liteSeatLimit,
    features: [
      {
        title: 'AI Help Desk',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_help_desk`,
        pulseInterfaceIcon: 'inbox',
        pill_color: 'teal',
      },
      {
        title: 'AI Bot',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.ai_bot`,
        pulseInterfaceIcon: 'fin',
        pill_color: 'light__blue',
      },
      {
        title: 'Proactive Support',
        titleTranslationKey: `app.lib.billing.${PRICING_5_X_CORE_ESSENTIAL_ID}.plan_data.features.proactive_support`,
        pulseIllustrativeIcon: 'outbound',
        pill_color: 'yellow',
      },
    ],
  },
  [PRICING_5_1_EXPERT_SOLUTION_ID]: {
    name: 'Expert',
    pricingModel: PRICING_5_1_PRICING_MODEL,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    liteSeatLimit: PLAN_DATA[PRICING_5_X_CORE_EXPERT_ID].liteSeatLimit,
  },
  [PRICING_5_X_EARLY_STAGE_SOLUTION_ID]: {
    name: 'Early Stage Program',
    nameTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_solution_id.name`,
    marketingNameTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_solution_id.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_solution_id.description`,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
    liteSeatLimit: 20,
  },
  [PRICING_5_X_EARLY_STAGE_FREE_SOLUTION_ID]: {
    name: 'Startups Program',
    nameTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_free_solution_id.name`,
    marketingNameTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_free_solution_id.marketing_name`,
    descriptionTranslationKey: `app.lib.billing.plan_data.pricing_5_early_stage_free_solution_id.description`,
    // phone pricing isn't returned from billing
    // adding this here so it can be retrieved per solution even though it's likely not going to change
    phoneMetricFromPrice: 0.0035,
    recommendedAddons: [PROACTIVE_SUPPORT_PLUS_BASE_ID],
    pricingModel: PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
    liteSeatLimit: 20,
  },
};

export const findSolution = function (solutionId: string) {
  return solutionId
    ? TEAMS_SOLUTIONS[solutionId]
    : TEAMS_SOLUTIONS[PRICING_5_1_ESSENTIAL_SOLUTION_ID];
};

const INBOX_INFO = {
  description: 'Manage conversations with leads and customers at scale',
  descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.description`,
  benefits: [
    {
      title: 'Convert leads',
      titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.convert_leads.title`,
      description: 'Chat with, qualify, and close leads on your website in real-time.',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.convert_leads.description`,
      icon: 'upgrade/track-leads',
    },
    {
      title: 'Scale support',
      titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.scale_support.title`,
      description: 'Automate workflows to respond faster and increase satisfaction.',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.scale_support.description`,
      icon: 'upgrade/teammate-workload',
    },
    {
      title: 'Integrate seamlessly',
      titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.integrate_seamlessly.title`,
      description: 'Integrate with Salesforce, Slack, Zendesk, and more.',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.integrate_seamlessly.description`,
      icon: 'upgrade/integrations',
    },
  ],
  productIcon: 'products/2020/July/inbox',
};

const INBOX_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.name_with_product`,
  ...INBOX_INFO,
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_ESSENTIAL_ID],
  ],
};

const INBOX_PRO_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_add_plan_info.name_with_product`,
  ...INBOX_INFO,
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_PRO_ID],
  ],
};

const INBOX_PREMIUM_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_add_plan_info.name_with_product`,
  ...INBOX_INFO,
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_PREMIUM_ID],
  ],
};

const MESSAGES_BENEFITS = {
  UNLIMITED_MESSAGES: {
    title: 'Unlimited messages',
    titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.unlimited_messages.title`,
    description: 'Nurture leads, onboard customers, announce new features and more.',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.unlimited_messages.description`,
    icon: 'upgrade/recent-conversations',
  },
  MULTICHANNEL: {
    title: 'Multichannel',
    titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.multichannel.title`,
    description:
      'Connect with customers and leads using in-app, email, website, and push messages.',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.multichannel.description`,
    icon: 'upgrade/multi-channel',
  },
  AUDIENCE_TARGETING: {
    title: 'Powerful targeting',
    titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.audience_targeting.title`,
    description: 'Send highly personalized messages based on behavior and custom data.',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.audience_targeting.description`,
    icon: 'upgrade/audience-targeting',
  },
  MESSAGE_STATS: {
    title: 'Test and measure',
    titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.message_stats.title`,
    description: 'Get insight into performance to meet your goals and improve over time.',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.message_stats.description`,
    icon: 'upgrade/message-stats',
  },
  SERIES: {
    title: 'Orchestrate all your messages',
    titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.series.title`,
    description: 'Connect your touchpoints to create seamless messaging experiences.',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.benefits.series.description`,
    hasIcIllustrativeIcon: true,
    icon: 'series',
  },
};

const MESSAGES_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.name_with_product`,
  description: 'Send targeted email, in-app and push messages to leads and customers',
  descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_add_plan_info.description`,
  benefits: [
    MESSAGES_BENEFITS.UNLIMITED_MESSAGES,
    MESSAGES_BENEFITS.MULTICHANNEL,
    MESSAGES_BENEFITS.AUDIENCE_TARGETING,
    MESSAGES_BENEFITS.MESSAGE_STATS,
  ],
  productIcon: 'products/2020/July/outbound',
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_ESSENTIAL_ID],
  ],
};

const MESSAGES_PRO_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_add_plan_info.name_with_product`,
  description: 'Send targeted email, in-app and push messages to leads and customers',
  descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_add_plan_info.description`,
  benefits: [
    MESSAGES_BENEFITS.UNLIMITED_MESSAGES,
    MESSAGES_BENEFITS.MULTICHANNEL,
    MESSAGES_BENEFITS.SERIES,
    MESSAGES_BENEFITS.AUDIENCE_TARGETING,
    MESSAGES_BENEFITS.MESSAGE_STATS,
  ],
  productIcon: 'products/2020/July/outbound',
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_PRO_ID],
  ],
};

const MESSAGES_PREMIUM_ADD_INFO = {
  nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_add_plan_info.name_with_product`,
  description: 'Send targeted email, in-app and push messages to leads and customers',
  descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_add_plan_info.description`,
  benefits: [
    MESSAGES_BENEFITS.UNLIMITED_MESSAGES,
    MESSAGES_BENEFITS.MULTICHANNEL,
    MESSAGES_BENEFITS.SERIES,
    MESSAGES_BENEFITS.AUDIENCE_TARGETING,
    MESSAGES_BENEFITS.MESSAGE_STATS,
  ],
  productIcon: 'products/2020/July/outbound',
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_PREMIUM_ID],
  ],
};

export const PRICING_METRIC_METADATA: { [key: string]: any } = {
  quarterly_active_people: {
    description:
      'Leads and customers who received or sent a message, or have logged into your product in the past 90 days.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.quarterly_active_people.description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/4238092-active-people-in-your-intercom-workspace',
    helpLinkText: 'How active people is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.quarterly_active_people.help_link',
    key: 'quarterly_active_people',
    articleId: '4238092',
  },
  monthly_active_people: {
    description:
      'Leads and customers who received or sent a message, or have logged into your product in the past 30 days.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_active_people.description',
    key: 'monthly_active_people',
  },
  people_count: {
    description: 'The total number of leads and users recorded and tracked in Intercom.',
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.people_count.description',
    key: 'people_count',
  },
  inbox_seats: {
    description: 'Teammates that have access to Inbox.',
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.inbox_seats.description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/6604606-how-do-inbox-seats-work',
    helpLinkText: 'How seats work',
    helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.inbox_seats.help_link',
    key: 'inbox_seats',
    articleId: '6604606',
  },
  thirty_day_messaged_contacts: {
    description:
      'Leads and users who in the last 30 days received an Outbound message or engaged with Outbound Custom Bots.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.thirty_day_messaged_contacts.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.thirty_day_messaged_contacts.billing_cycle_description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/4830534-people-reached-in-your-intercom-workspace',
    helpLinkText: 'How people reached is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.thirty_day_messaged_contacts.help_link',
    key: 'thirty_day_messaged_contacts',
    articleId: '4830534',
  },
  fixed_term_charge: {
    description: 'A one time charge has been applied to your invoice for this service.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.fixed_term_charge.description',
    helpLinkUrl: 'http://app.intercom.com/a/apps/_/billing/invoices',
    helpLinkText: 'See invoices',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.fixed_term_charge.help_link',
    key: 'fixed_term_charge',
  },
  [Metric.monthly_surveys_sent]: {
    description:
      'Surveys Sent is a sum of the total number of distinct surveys sent to a user, lead or visitor in the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_surveys_sent.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_surveys_sent.billing_cycle_description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/5948452-Pricing-for-Surveys-Sent-in-your-Intercom-Workspace',
    helpLinkText: 'How monthly surveys sent is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_surveys_sent.help_link',
    key: 'monthly_surveys_sent',
    articleId: '5948452',
  },
  [Metric.monthly_calls_deflected]: {
    description:
      'Calls Deflected is the total number of SMS sent to users from Intercom when they decide to switch a call to messaging.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_calls_deflected.description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/5986443-pricing-for-calls-deflected-in-your-intercom-workspace',
    helpLinkText: 'How calls deflected is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.monthly_calls_deflected.help_link',
    key: 'monthly_calls_deflected',
    articleId: '5986443',
  },
  [Metric.resolutions]: {
    description: 'Resolutions are the amount of conversations resolved by Fin.',
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.resolutions.description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/7837512-fin-pricing',
    helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.resolutions.help_link',
    key: 'resolutions',
    articleId: '7837512',
  },
  [SMS_SEGMENTS_SENT_RECEIVED_GROUP]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/6211963-how-sms-pricing-works',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    key: SMS_SEGMENTS_SENT_RECEIVED_GROUP,
    articleId: '6211963',
  },
  [Metric.sms_segments_sent_received_monthly_us]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_monthly_us,
  },
  [Metric.sms_segments_sent_received_monthly_uk]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
    },
    key: Metric.sms_segments_sent_received_monthly_uk,
  },
  [Metric.sms_segments_sent_received_monthly_ca]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_monthly_ca,
  },
  [Metric.sms_segments_sent_received_monthly_au]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_monthly_au,
  },
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_us]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
  },
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
  },
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
  },
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_au]: {
    description:
      'SMS sent/received is an outbound or inbound SMS sent to or from the messenger within the last month.',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8205735-sms-pricing',
    helpLinkText: 'How SMS sent/received is calculated',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.help_link',
    articleId: '8205735',
    pricing5: {
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_c0ffb9d053',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_help_link',
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_segments_sent_received_monthly.pricing_5_description',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
    },
    key: Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  },
  [Metric.core_seat_count]: {
    description: 'core_seat_count',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.core_seat_count.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.core_seat_count.billing_cycle_description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/8205716-intercom-seats-full-seats-and-lite-seats',
    helpLinkText: 'core_seat_count',
    helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.core_seat_count.help_link',
    key: Metric.core_seat_count,
    articleId: '8205716',
  },
  [Metric.copilot_seat_count]: {
    description: 'copilot_seat_count',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.copilot_seat_count.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.copilot_seat_count.billing_cycle_description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/9121384-fin-ai-copilot-included-and-unlimited-usage',
    helpLinkText: 'copilot_seat_count',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.copilot_seat_count.help_link',
    key: Metric.copilot_seat_count,
    articleId: '9121384',
  },
  [Metric.resolutions_with_custom_answers]: {
    description: 'resolutions_with_custom_answers',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.resolutions_with_custom_answers.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.resolutions_with_custom_answers.billing_cycle_description',
    helpLinkUrl:
      'https://www.intercom.com/help/en/articles/8205718-fin-resolutions-and-custom-answers',
    helpLinkText: 'resolutions_with_custom_answers',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.resolutions_with_custom_answers.help_link',
    key: Metric.resolutions_with_custom_answers,
    articleId: '8205718',
  },
  [Metric.whatsapp_inbound]: {
    description: 'whatsapp_inbound',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_inbound.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_inbound.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_abe05102a5',
    helpLinkText: 'whatsapp_inbound',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_inbound.help_link',
    key: Metric.whatsapp_inbound,
    articleId: '8259544',
    articleHelpCenterSelector: 'h_abe05102a5',
  },
  [Metric.whatsapp_outbound]: {
    description: 'whatsapp_outbound',
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_outbound.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_outbound.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_54dba27dfc',
    helpLinkText: 'whatsapp_outbound',
    helpLinkTextTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_outbound.help_link',
    key: Metric.whatsapp_outbound,
    articleId: '8259544',
    articleHelpCenterSelector: 'h_54dba27dfc',
  },
  [Metric.emails_sent]: {
    description: 'emails_sent',
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.emails_sent.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.emails_sent.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_910f7ea6e7',
    helpLinkText: 'emails_sent',
    helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.emails_sent.help_link',
    key: Metric.emails_sent,
    articleId: '8259544',
    articleHelpCenterSelector: 'h_910f7ea6e7',
  },
  [Metric.messages_sent]: {
    description: 'messages_sent',
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.messages_sent.description',
    billingCycleDescriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.messages_sent.billing_cycle_description',
    helpLinkUrl: 'https://www.intercom.com/help/en/articles/8259544-metered-messages#h_0d402dbb8b',
    helpLinkText: 'messages_sent',
    helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.messages_sent.help_link',
    key: Metric.messages_sent,
    articleId: '8259544',
    articleHelpCenterSelector: 'h_0d402dbb8b',
  },
  [Metric.calling]: {
    descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.calling.description',
    pricing5: {
      descriptionTranslationKey: 'app.lib.billing.pricing_metric_metadata.calling.description',
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-usage-based-channels#h_f0415328dc',
      helpCenterLinkUrlPricing5_1:
        'https://www.intercom.com/help/en/articles/9061703-usage-based-channels#h_0db2029e5e',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_f0415328dc',
      articleIdPricing5_1: '9061703',
      articleHelpCenterSelectorPricing5_1: 'h_0db2029e5e',
      helpLinkTextTranslationKey: 'app.lib.billing.pricing_metric_metadata.calling.help_link',
    },
  },
  sms_all_regions: {
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.sms_all_regions.description',
    pricing5: {
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_all_regions.description',
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-usage-based-channels#h_c0ffb9d053',
      helpCenterLinkUrlPricing5_1:
        'https://www.intercom.com/help/en/articles/9061703-usage-based-channels#h_ee3403f06b',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_c0ffb9d053',
      articleIdPricing5_1: '9061703',
      articleHelpCenterSelectorPricing5_1: 'h_ee3403f06b',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.sms_all_regions.help_link',
    },
  },
  whatsapp_inbound_outbound: {
    descriptionTranslationKey:
      'app.lib.billing.pricing_metric_metadata.whatsapp_inbound_outbound.description',
    pricing5: {
      descriptionTranslationKey:
        'app.lib.billing.pricing_metric_metadata.whatsapp_inbound_outbound.description',
      helpCenterLinkUrl:
        'https://www.intercom.com/help/en/articles/8259544-usage-based-channels#h_54dba27dfc',
      helpCenterLinkUrlPricing5_1:
        'https://www.intercom.com/help/en/articles/9061703-usage-based-channels#h_13e2dcaf16',
      articleId: '8259544',
      articleHelpCenterSelector: 'h_54dba27dfc',
      articleIdPricing5_1: '9061703',
      articleHelpCenterSelectorPricing5_1: 'h_13e2dcaf16',
      helpLinkTextTranslationKey:
        'app.lib.billing.pricing_metric_metadata.whatsapp_inbound_outbound.help_link',
    },
  },
};

/*
 * Note: This property, MODAL_ADD_PLAN_INFO, must not be exported directly due to the fact
 * it is stubbed in tests and the fact that ES Modules are immutable and cannot be stubbed.
 * As a result, this property is explicitly exported in the default object export for this file.
 * For more context on this, see https://github.com/intercom/embercom/pull/76174
 */
// Used in the modal when adding a plan.
const MODAL_ADD_PLAN_INFO: { [key: string]: any } = {
  [ARTICLES_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.name_with_product`,
    description: 'Start a knowledge base of articles and best practices for customer self-service',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: '24/7 customer support',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.customer_support.title`,
        description: 'Help customers help themselves by creating articles that answer FAQs.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.customer_support.description`,
        icon: 'upgrade/write-articles',
      },
      {
        title: 'Automatic article suggestions',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.auto_suggestions.title`,
        description: 'Respond to customers instantly with a bot that sends relevant articles.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.auto_suggestions.description`,
        icon: 'upgrade/smart-suggestions',
      },
      {
        title: 'Integrated knowledge base',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.knowledge_base.title`,
        description: 'Connect your knowledge base and help desk to speed up resolutions.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.benefits.knowledge_base.description`,
        icon: 'upgrade/smart-suggestions-educate',
      },
    ],
    productIcon: 'products/2020/July/articles',
    productIconTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_add_plan_info.product_icon`,
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ARTICLES_ESSENTIAL_ID],
    ],
  },
  [ARTICLES_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.name_with_product`,
    description:
      'Help your different audiences quickly find answers with a tailored knowledge base',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Localize your knowledge base',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.localize_knowledge_base.title`,
        description: 'Create and serve help content to customers in 38 languages.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.localize_knowledge_base.description`,
        icon: 'upgrade/translate',
      },
      {
        title: 'Control who sees your content',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.control_content.title`,
        description:
          'Keep sensitive info out of sight by creating private articles for logged-in users.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.control_content.description`,
        icon: 'upgrade/padlock',
      },
      {
        title: 'Make it easy for your team to provide consistent answers',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.consistent_answers.title`,
        description:
          'Teammates can add private or multilingual articles (coming soon) to conversations, without leaving inbox.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.consistent_answers.description`,
        icon: 'upgrade/feature-multiplemessages',
      },
      {
        title: 'Create multiple Help Centers',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.multiple_help_centers.title`,
        description:
          'Create and customise distinct Help Centers for each of your brands in the same workspace.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_add_plan_info.benefits.multiple_help_centers.description`,
        icon: 'upgrade/feature-multihelpcenters',
      },
    ],
    productIcon: 'products/2020/July/articles',
  },
  [OPERATOR_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.name_with_product`,
    description:
      'Qualify more leads, book more meetings, and triage inbound conversations around the clock',
    descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Unlimited Workflows',
        titleTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.unlimited_bots.title`,
        description: 'Create one workflow or many workflows at no extra cost.',
        descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.unlimited_bots.description`,
        icon: 'upgrade/feature-alwaysonscript',
      },
      {
        title: 'Code-free customization',
        titleTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.code_free_customization.title`,
        description: 'Set up your perfect, on-brand bot in minutes.',
        descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.code_free_customization.description`,
        icon: 'upgrade/feature-codefreecreation',
      },
      {
        title: 'Growth beyond human scale',
        titleTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.scaled_growth.title`,
        description: 'Give everyone an immediate response and great experience.',
        descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_add_plan_info.benefits.scaled_growth.description`,
        icon: 'upgrade/feature-relationshipsatscale',
      },
    ],
    productIcon: 'upgrade/resolution-bot',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[OPERATOR_PRO_ID],
    ],
  },
  [ANSWER_BOT_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.name_with_product`,
    description: `Instantly resolve 33% of your customers’ most common questions with Resolution Bot`,
    descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Instant resolutions, 24/7',
        titleTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.instant_resolutions.title`,
        description: 'Resolve customer questions even when you’re busy or offline.',
        descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.instant_resolutions.description`,
        icon: 'upgrade/feature-responsetime',
      },
      {
        title: 'Consistent & on-brand',
        titleTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.consistent_brand.title`,
        description: 'Intelligent automation that puts you in control.',
        descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.consistent_brand.description`,
        icon: 'upgrade/feature-multiplemessages',
      },
      {
        title: 'Improved support efficiency',
        titleTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.improved_support_efficiency.title`,
        description: 'Free up your team to focus on more valuable tasks.',
        descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_add_plan_info.benefits.improved_support_efficiency.description`,
        icon: 'upgrade/feature-agentefficiency',
      },
    ],
    productIcon: 'upgrade/resolution-bot',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ANSWER_BOT_ESSENTIAL_ID],
    ],
  },
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.name_with_product`,
    description: `Drive onboarding and adoption with guided tours and tooltips`,
    descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.description`,
    productVideo: 'nt1065g2sn',
    hideFeatures: false,
    customFeaturesTitle: true,
    showFeatureBreakdown: true,
    benefits: [
      {
        title: 'Drive adoption',
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.benefits.drive_adoption.title`,
        description:
          'Guide users around your product with interactive multi-step tours and tooltips.',
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.benefits.drive_adoption.description`,
        icon: 'product-tours-list',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Educate your customers',
        titleTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.benefits.educate_customers.title`,
        description:
          'Add contextual information to the exact place in your app that you want to explain or draw attention to.',
        descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_add_plan_info.benefits.educate_customers.description`,
        hasIcIllustrativeIcon: true,
        icon: 'post',
      },
    ],
    productIcon: 'upgrade/product-tours',
  },
  [ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'Drive growth across the customer lifecycle',
    descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.description`,
    hideFeatures: true,
    benefits: [
      {
        title: 'Right message, right time',
        titleTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.right_message.title`,
        description:
          'Create dynamic messaging campaigns targeted based on behavior in your product or with prior messages.',
        descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.right_message.description`,
        hasIcIllustrativeIcon: true,
        icon: 'series',
      },
      {
        title: 'Personalize messaging',
        titleTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.personalize_messaging.title`,
        description: 'Tailor messages with Clearbit Reveal & Marketo data.',
        descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.personalize_messaging.description`,
        icon: 'upgrade/personalized-messaging',
      },
      {
        title: 'Measure results',
        titleTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.measure_results.title`,
        description: 'Optimize message performance with A/B testing and control groups.',
        descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.measure_results.description`,
        icon: 'upgrade/measure-results',
      },
      {
        title: 'Reach mobile users',
        titleTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.reach_mobile_users.title`,
        description: 'Engage mobile app users with push messages and notifications.',
        descriptionTranslationKey: `app.lib.billing.${ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID}.modal_add_plan_info.benefits.reach_mobile_users.description`,
        hasIcIllustrativeIcon: true,
        icon: 'notification-mobile-push',
      },
    ],
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ADVANCED_CUSTOMER_ENGAGEMENT_BASE_ID],
    ],
  },
  [LEAD_GENERATION_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'Grow your pipeline with ABM & chatbots',
    descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.description`,
    hideFeatures: true,
    benefits: [
      {
        title: 'Target high value accounts',
        titleTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.target_accounts.title`,
        description: 'Welcome target accounts and instantly connect them to sales.',
        descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.target_accounts.description`,
        icon: 'upgrade/account-based-marketing',
      },
      {
        title: 'Drive action with bots and apps',
        titleTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.drive_action.title`,
        description: 'Wake up to meetings booked with powerful integrated apps in bots.',
        descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.drive_action.description`,
        icon: 'upgrade/integrations',
      },
      {
        title: 'Test your Salesforce integration',
        titleTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.test_integrations.title`,
        description: 'Test and optimize your Salesforce configuration in Sandbox.',
        descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.test_integrations.description`,
        icon: 'upgrade/salesforce-sandbox',
      },
      {
        title: 'Connect your stack',
        titleTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.connect_stack.title`,
        description: 'Connect Intercom with Marketo and Clearbit Reveal.',
        descriptionTranslationKey: `app.lib.billing.${LEAD_GENERATION_BASE_ID}.modal_add_plan_info.benefits.connect_stack.description`,
        icon: 'upgrade/connect-your-tools',
      },
    ],
  },
  [SUPPORT_AUTOMATION_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'Provide faster answers with automated support',
    descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.description`,
    hideFeatures: true,
    benefits: [
      {
        title: 'Automate answers with bots',
        titleTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.automate_answers.title`,
        description: 'Give customers instant, precise answers to common questions.',
        descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.automate_answers.description`,
        hasIcIllustrativeIcon: true,
        icon: 'operator',
      },
      {
        title: 'Power actions with apps',
        titleTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.power_actions.title`,
        description: 'Provide faster resolutions in the Messenger with apps in bots.',
        descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.power_actions.description`,
        icon: 'app-store',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Create tailored responses',
        titleTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.create_responses.title`,
        description: 'Show different answers to different customers with targeting.',
        descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.create_responses.description`,
        icon: 'upgrade/tailored-responses',
      },
      {
        title: 'Optimize your answers',
        titleTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.optimize_answers.title`,
        description: 'See how your bot is performing and how to optimize your answers.',
        descriptionTranslationKey: `app.lib.billing.${SUPPORT_AUTOMATION_BASE_ID}.modal_add_plan_info.benefits.optimize_answers.description`,
        icon: 'upgrade/message-stats',
      },
    ],
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[SUPPORT_AUTOMATION_BASE_ID],
    ],
  },
  [INBOX_ESSENTIAL_ID]: INBOX_ADD_INFO,
  [INBOX_PRO_ID]: INBOX_PRO_ADD_INFO,
  [INBOX_PREMIUM_ID]: INBOX_PREMIUM_ADD_INFO,
  [MESSAGES_ESSENTIAL_ID]: MESSAGES_ADD_INFO,
  [MESSAGES_PRO_ID]: MESSAGES_PRO_ADD_INFO,
  [MESSAGES_PREMIUM_ID]: MESSAGES_PREMIUM_ADD_INFO,
  [CORE_STARTER_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'The perfect tool to keep you connected across the customer journey',
    descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Support and engage your customers at every step of their journey',
        titleTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.support_and_engage.title`,
        description: 'Basic inbound and outbound messaging capabilities.',
        descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.support_and_engage.description`,
        icon: 'upgrade/feature-relationshipsatscale',
      },
      {
        title: 'Gain insights & improve customer experiences',
        titleTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.gain_insights.title`,
        description:
          'Centralize your customer data to personalize interactions and drive outcomes.',
        descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.gain_insights.description`,
        icon: 'upgrade/event-tracking',
      },
      {
        title: 'Scale without scaling your team',
        titleTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.scale.title`,
        description: 'Let your customers self-serve and make your team more efficient.',
        descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.scale.description`,
        icon: 'upgrade/smart-suggestions-educate',
      },
      {
        title: 'Connect the tools you already use',
        titleTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.connect_tools.title`,
        description: 'Integrate with your existing tech stack.',
        descriptionTranslationKey: `app.lib.billing.${CORE_STARTER_BASE_ID}.modal_add_plan_info.benefits.connect_tools.description`,
        icon: 'upgrade/connect-your-tools',
      },
    ],
    brandColor: '#FEA143',
    productIcon: 'upgrade/starter',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_STARTER_BASE_ID],
    ],
  },
  [CORE_SUPPORT_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.name_with_product`,
    description: 'Assist customers quickly and at scale with personalized, happy-making support',
    descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Human Support',
        titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.benefits.human_support.title`,
        description: 'Provide personalized customer service that’s efficient for your team.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.benefits.human_support.description`,
        icon: 'upgrade/team-inbox',
      },
      {
        title: 'Self-serve support',
        titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.benefits.self_serve_support.title`,
        description: 'Leverage our bots or let your customers self-serve through the Help Center.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PRO_ID}.modal_add_plan_info.benefits.self_serve_support.description`,
        icon: 'upgrade/smart-suggestions-educate',
      },
    ],
    brandColor: '#f2a456',
    productIcon: 'upgrade/support',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_SUPPORT_PRO_ID],
    ],
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.name_with_product`,
    description: 'Assist customers quickly and at scale with personalized, happy-making support',
    descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Human Support',
        titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.human_support.title`,
        description: 'Provide personalized customer service that’s efficient for your team.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.human_support.description`,
        icon: 'upgrade/team-inbox',
      },
      {
        title: 'Self-serve support',
        titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.self_serve_support.title`,
        description: 'Leverage our bots or let your customers self-serve through the Help Center.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.self_serve_support.description`,
        icon: 'upgrade/smart-suggestions-educate',
      },
      {
        title: 'Scale your support without scaling your costs',
        titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.scale_support.title`,
        description:
          'Stay on top of your support funnel and get the tools needed to further streamline it.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.scale_support.description`,
        icon: 'upgrade/conversation-volume',
      },
    ],
    brandColor: '#f2a456',
    extendsPlanId: CORE_SUPPORT_PRO_ID,
    productIcon: 'upgrade/support',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_SUPPORT_PREMIUM_ID],
    ],
  },
  [CORE_ENGAGE_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.name_with_product`,
    description: 'Onboard and activate customers with targeted outbound engagement for growth',
    descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Engagement and adoption',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.engagement_and_adoption.title`,
        description: 'Reach your customers with relevant messages sent to the right media.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.engagement_and_adoption.description`,
        icon: 'upgrade/multi-channel',
      },
      {
        title: 'Optimize performance',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.optimize_performance.title`,
        description: 'Optimize and test your messages so they deliver great outcomes.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.optimize_performance.description`,
        icon: 'upgrade/message-stats',
      },
      {
        title: 'Personalize targeting',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.personalize_targeting.title`,
        description:
          'Proactively reach out to—or guide—your customers with our outbound capabilities.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PRO_ID}.modal_add_plan_info.benefits.personalize_targeting.description`,
        icon: 'upgrade/feature-relationshipsatscale',
      },
    ],
    brandColor: '#ed6e65',
    productIcon: 'upgrade/engage',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_ENGAGE_PRO_ID],
    ],
  },
  [CORE_ENGAGE_PREMIUM_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.name_with_product`,
    description: 'Onboard and activate customers with targeted outbound engagement for growth',
    descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Engagement and adoption',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.engagement_and_adoption.title`,
        description: 'Reach your customers with relevant messages sent to the right media.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.engagement_and_adoption.description`,
        icon: 'upgrade/multi-channel',
      },
      {
        title: 'Optimize performance',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.optimize_performance.title`,
        description: 'Optimize and test your messages so they deliver great outcomes.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.optimize_performance.description`,
        icon: 'upgrade/message-stats',
      },
      {
        title: 'Personalize targeting',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.personalize_targeting.title`,
        description:
          'Proactively reach out to—or guide—your customers with our outbound capabilities.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.personalize_targeting.description`,
        icon: 'upgrade/feature-relationshipsatscale',
      },
      {
        title: 'Take optimization to the next level',
        titleTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.next_level_optimization.title`,
        description: 'Further enhance your outbound messaging efforts.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ENGAGE_PREMIUM_ID}.modal_add_plan_info.benefits.next_level_optimization.description`,
        icon: 'upgrade/measure-results',
      },
    ],
    brandColor: '#ed6e65',
    extendsPlanId: CORE_ENGAGE_PRO_ID,
    productIcon: 'upgrade/engage',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_ENGAGE_PREMIUM_ID],
    ],
  },
  [CORE_CONVERT_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.name_with_product`,
    description:
      'Increase website conversions with chatbots, and grow sales through real-time conversations',
    descriptionTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Website conversion',
        titleTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.website_conversion.title`,
        description: 'Turn website visitors into qualified leads with our messenger.',
        descriptionTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.website_conversion.description`,
        icon: 'upgrade/visitors',
      },
      {
        title: 'Manage leads',
        titleTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.manage_leads.title`,
        description: 'The tools your team needs to manage the conversation volume.',
        descriptionTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.manage_leads.description`,
        icon: 'upgrade/lead-profiles',
      },
      {
        title: 'Prospect data',
        titleTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.prospect_data.title`,
        description: 'Integrate with your current sales and marketing stack.',
        descriptionTranslationKey: `app.lib.billing.${CORE_CONVERT_PRO_ID}.modal_add_plan_info.benefits.prospect_data.description`,
        icon: 'upgrade/padlock',
      },
    ],
    brandColor: '#5367bc',
    productIcon: 'upgrade/marketing',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_CONVERT_PRO_ID],
    ],
  },
  [PROACTIVE_SUPPORT_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'Proactively reach out to your customers',
    descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Send announcements on your site or within your product',
        titleTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.benefits.send_announcements.title`,
        description: 'Reach your customers with relevant messages sent to the right media.',
        descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.benefits.send_announcements.description`,
        icon: 'upgrade/announcements',
      },
      {
        title: 'Personalize targeting',
        titleTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.benefits.personalize_targeting.title`,
        description: 'Put your customer data to work and send targeted and relevant messages.',
        descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_BASE_ID}.modal_add_plan_info.benefits.personalize_targeting.description`,
        icon: 'upgrade/feature-relationshipsatscale',
      },
    ],
    productIcon: 'upgrade/proactive-support',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[PROACTIVE_SUPPORT_BASE_ID],
    ],
  },
  [ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.modal_add_plan_info.name_with_product`,
    description:
      'Enterprise-grade security, advanced permissions and extensibility to meet your team’s needs',
    descriptionTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Manage your teammates and permissions at scale',
        titleTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.modal_add_plan_info.benefits.manage_permissions.title`,
        description:
          'Use your identity provider to login and bulk manage your team’s permissions with roles.',
        descriptionTranslationKey: `app.lib.billing.${ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID}.modal_add_plan_info.benefits.manage_permissions.description`,
        icon: 'upgrade/teammate-workload',
      },
    ],
    productIcon: 'upgrade/scale',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ADVANCED_WORKSPACE_MANAGEMENT_BASE_ID],
    ],
  },
  [WHATSAPP_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.name_with_product`,
    description: 'Provide a seamless messaging experience for customers with WhatsApp',
    descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Customers can start conversations via WhatsApp',
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.customer_conversation.title`,
        description:
          'Let your customers reach you on WhatsApp for a seamless messaging experience across channels.',
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.customer_conversation.description`,
        icon: 'upgrade/conversation',
      },
      {
        title: 'All your conversations in one place',
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.conversations_one_place.title`,
        description:
          'Increase team efficiency by managing WhatsApp conversations right from Intercom.',
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.conversations_one_place.description`,
        icon: 'upgrade/goal',
      },
      {
        title: 'From Messenger to WhatsApp',
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.messenger_to_whatsapp.title`,
        description:
          'If your customers need to leave your website or app they can seamlessly move the conversation to WhatsApp for convenience.',
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.messenger_to_whatsapp.description`,
        icon: 'upgrade/optimize-performance',
      },
      {
        title: 'Fully integrated with Reporting',
        titleTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.integrated_with_reporting.title`,
        description:
          'Report on every support interaction across channels – including WhatsApp – to optimize your omnichannel support.',
        descriptionTranslationKey: `app.lib.billing.${WHATSAPP_BASE_ID}.modal_add_plan_info.benefits.integrated_with_reporting.description`,
        icon: 'upgrade/chart',
      },
    ],
    productIcon: 'upgrade/whatsapp-logo',
  },
  [SMS_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.name_with_product`,
    multiplan: true,
    // Additional pricing charge metrics to show:
    allowedPricingMetricsCharge: [
      'active_phone_numbers_count_us',
      'active_phone_numbers_count_ca',
      'active_phone_numbers_count_uk',
    ],
    customTooltipContent: {
      text: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.custom_tooltip_content.text`,
      subtext: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.custom_tooltip_content.subtext`,
      link: {
        name: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.custom_tooltip_content.link.name`,
        path: 'https://www.intercom.com/help/en/articles/6211963-how-sms-pricing-works',
      },
    },
    productIcon: 'sms',
    hasIcIllustrativeIcon: true,
    customFeaturesTitle: true,
    showFeatureBreakdown: true,
    allowTrials: false,
    description:
      'Increase engagement and encourage customers to act with our truly two-way SMS - connecting you to your customers anytime, anywhere.',
    descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'High engagement rates',
        titleTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.high_engagement.title`,
        description:
          'SMS has an open rate of 98% - getting your message in front of more customers.',
        descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.high_engagement.description`,
        hasIcIllustrativeIcon: true,
        icon: 'activity',
      },
      {
        title: 'Two-way SMS',
        titleTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.two_way_sms.title`,
        description:
          'A truly conversational experience connecting customers directly to your sales, marketing, and support teams.',
        descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.two_way_sms.description`,
        hasIcIllustrativeIcon: true,
        icon: 'conversation',
      },
      {
        title: 'Targeted and Personalized',
        titleTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.targeted_personalized.title`,
        description:
          "Engage customers with the right message at the right time by leveraging Intercom's rich customer data to identify your target audience and segments.",
        descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.targeted_personalized.description`,
        hasIcIllustrativeIcon: true,
        icon: 'goal',
      },
      {
        title: 'Create flexible workflows',
        titleTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.flexible_workflows.title`,
        description:
          'Use Series to design workflows for transactional messaging, promotional offers, onboarding, re-engagement and more!',
        descriptionTranslationKey: `app.lib.billing.${SMS_BASE_ID}.modal_add_plan_info.benefits.flexible_workflows.description`,
        hasIcIllustrativeIcon: true,
        icon: 'series',
      },
    ],
  },
  [SURVEYS_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.name_with_product`,
    description:
      'Capture and act on first-party data to drive personalized and responsive customer experiences.',
    descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.description`,
    productIcon: 'survey-filled',
    hasIcIllustrativeIcon: true,
    customFeaturesTitle: true,
    showFeatureBreakdown: true,
    pricingArticleLink:
      'https://www.intercom.com/help/en/articles/5948452-pricing-for-surveys-sent-in-your-intercom-workspace',
    benefits: [
      {
        title: 'Ease of set-up & customization',
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.easy_setup.title`,
        description:
          "It's quick and easy to set-up and design surveys that match your brand's look and feel.",
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.easy_setup.description`,
        icon: 'upgrade/powerful-workflows',
      },
      {
        title: 'Increased response rate',
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.increased_response_rate.title`,
        description:
          "Engage customers via the channel that's right for them, in-product, mobile, email, and more.",
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.increased_response_rate.description`,
        icon: 'upgrade/increased-response-rate',
      },
      {
        title: 'Powerful targeting',
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.powerful_targeting.title`,
        description:
          "Connect with the right customers at the right time by leveraging Intercom's rich customer data to identify your target audience and segments.",
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.powerful_targeting.description`,
        icon: 'upgrade/setup-and-customization',
      },
      {
        title: 'Build responsive workflows',
        titleTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.responsive_workflows.title`,
        description:
          "Combine survey responses and Intercom's rich customer data to automate follow-ups, customize the support experience, tailor onboarding and more.",
        descriptionTranslationKey: `app.lib.billing.${SURVEYS_BASE_ID}.modal_add_plan_info.benefits.responsive_workflows.description`,
        icon: 'upgrade/powerful-targeting',
      },
    ],
  },
  [SWITCH_BASE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.name_with_product`,
    productIcon: 'switch',
    hasIcIllustrativeIcon: true,
    hideFeatures: true,
    description:
      'Ditch the long hold times and give customers the choice to move to Messenger for faster answers.',
    descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        title: 'Faster resolution times',
        titleTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.faster_resolution_times.title`,
        description:
          'With Switch, your customers can quickly get the help they need by switching to the Messenger instead of waiting on hold.',
        descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.faster_resolution_times.description`,
        icon: 'upgrade/messenger',
      },
      {
        title: 'More team efficiency',
        titleTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.more_team_efficiency.title`,
        description:
          'Plus, Switch lets your agents message more than one customer at a time, scaling the team you have right now.',
        descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.more_team_efficiency.description`,
        icon: 'upgrade/conversation',
      },
      {
        title: 'Automated answers',
        titleTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.automated_answers.title`,
        description:
          'Our bots take a lot of burden off your support team by automatically answering common questions too.',
        descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.automated_answers.description`,
        icon: 'upgrade/operator',
      },
      {
        title: 'Personalized experiences',
        titleTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.personalized_experiences.title`,
        description:
          'Switch seamlessly identifies your customers by their phone numbers, so when they reach the Messenger they can continue where they left off.',
        descriptionTranslationKey: `app.lib.billing.${SWITCH_BASE_ID}.modal_add_plan_info.benefits.personalized_experiences.description`,
        icon: 'upgrade/lead-qualification',
      },
    ],
  },
  [PROACTIVE_SUPPORT_PLUS_BASE_ID]: {
    productIcon: 'switch',
    hasIcIllustrativeIcon: true,
    hideFeatures: false,

    nameWithProductTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.name_with_product`,
    descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        titleTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.benefits.high_engagement.title`,
        descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.benefits.high_engagement.description`,
        icon: 'upgrade/messenger',
      },
      {
        titleTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.benefits.two_way_sms.title`,
        descriptionTranslationKey: `app.lib.billing.${PROACTIVE_SUPPORT_PLUS_BASE_ID}.modal_add_plan_info.benefits.two_way_sms.description`,
        icon: 'upgrade/conversation',
      },
    ],
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[PROACTIVE_SUPPORT_PLUS_BASE_ID],
    ],
  },
  [FIN_AI_COPILOT_BASE_ID]: {
    productIcon: 'switch',
    hasIcIllustrativeIcon: true,
    hideFeatures: false,
    nameWithProductTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.modal_add_plan_info.name_with_product`,
    descriptionTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.modal_add_plan_info.description`,
    benefits: [
      {
        titleTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.modal_add_plan_info.benefits.inbox_integration.title`,
        descriptionTranslationKey: `app.lib.billing.${FIN_AI_COPILOT_BASE_ID}.modal_add_plan_info.benefits.inbox_integration.description`,
        icon: 'upgrade/conversation',
      },
    ],
  },
};

const INBOX_MODAL_ADD_PLAN_FIN = {
  description: 'The only customer service solution optimised for AI and human support',
  descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.description-fin`,
  benefits: [
    {
      title: "Maximize team productivity with the world's fastest shared Inbox",
      titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.maximize_team_productivity.title`,
      description:
        'Our AI-enhanced Inbox is lightning fast, easy-to-use, and optimized for efficiency.',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.maximize_team_productivity.description`,
      icon: 'people',
      hasIcIllustrativeIcon: true,
    },
    {
      title: 'Resolve 50% of support questions with AI. Instantly.',
      titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.ai_support.title`,
      description:
        'Fin is our breakthrough AI agent that automatically answers support questions based on your Help Centre.',
      descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.ai_support.description`,
      icon: 'visitor-auto-message',
      hasIcIllustrativeIcon: true,
      comingSoon: [
        {
          icon: 'fin',
          description: 'Fin AI agent',
          descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_add_plan_info.benefits.ai_support.coming-soon.fin`,
        },
      ],
    },
  ],
};

const CORE_SUPPORT_MODAL_ADD_PLAN_FIN = {
  description: 'The only solution optimised for AI, automation and human support',
  descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.description-fin`,
  benefits: [
    {
      title: 'Resolve 50% of conversations with AI-powered automation',
      titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.ai_support.title`,
      description:
        'Reduce support volumes and increase customer satisfaction with our AI-powered chatbots and automation tools.',
      descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.ai_support.description`,
      icon: 'visitor-auto-message',
      hasIcIllustrativeIcon: true,
      comingSoon: [
        {
          icon: 'fin',
          description: 'Fin AI Agent',
          descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.ai_support.coming-soon.fin`,
        },
      ],
    },
    {
      title: 'Free up your team to focus on high-value interactions',
      titleTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.free_up_your_team.title`,
      description:
        'Take your support team from cost center to value driver using the most collaborative and efficient inbox ever.',
      descriptionTranslationKey: `app.lib.billing.${CORE_SUPPORT_PREMIUM_ID}.modal_add_plan_info.benefits.free_up_your_team.description`,
      icon: 'people',
      hasIcIllustrativeIcon: true,
    },
  ],
};

export const MODAL_ADD_PLAN_INFO_WITH_FIN = {
  ...MODAL_ADD_PLAN_INFO,
  [INBOX_ESSENTIAL_ID]: {
    ...MODAL_ADD_PLAN_INFO[INBOX_ESSENTIAL_ID],
    ...INBOX_MODAL_ADD_PLAN_FIN,
  },
  [INBOX_PREMIUM_ID]: {
    ...MODAL_ADD_PLAN_INFO[INBOX_PREMIUM_ID],
    ...INBOX_MODAL_ADD_PLAN_FIN,
  },
  [INBOX_PRO_ID]: {
    ...MODAL_ADD_PLAN_INFO[INBOX_PRO_ID],
    ...INBOX_MODAL_ADD_PLAN_FIN,
  },
  [CORE_SUPPORT_PRO_ID]: {
    ...MODAL_ADD_PLAN_INFO[CORE_SUPPORT_PRO_ID],
    ...CORE_SUPPORT_MODAL_ADD_PLAN_FIN,
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    ...MODAL_ADD_PLAN_INFO[CORE_SUPPORT_PREMIUM_ID],
    ...CORE_SUPPORT_MODAL_ADD_PLAN_FIN,
  },
};

// This should replace ANSWER_BOT_ESSENTIAL_ID in MODAL_ADD_PLAN_INFO once Custom Answers are released to all
export const MODAL_ADD_PLAN_INFO_CUSTOM_ANSWERS = {
  nameWithProductTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.name_with_product`,
  descriptionTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.description`,
  benefits: [
    {
      titleTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.instant_resolutions.title`,
      descriptionTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.instant_resolutions.description`,
      icon: 'upgrade/feature-responsetime',
    },
    {
      titleTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.consistent_brand.title`,
      descriptionTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.consistent_brand.description`,
      icon: 'upgrade/feature-multiplemessages',
    },
    {
      titleTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.improved_support_efficiency.title`,
      descriptionTranslationKey: `app.lib.billing.custom_answers.modal_add_plan_info.benefits.improved_support_efficiency.description`,
      icon: 'upgrade/feature-agentefficiency',
    },
  ],
  paywallMarketingFeaturesTranslationKeys: [
    ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ANSWER_BOT_ESSENTIAL_ID],
  ],
  hasIcIllustrativeIcon: false,
  hasIcInterfaceIcon: true,
  productIcon: 'fin',
};

// Used in the modal when upgrading a plan
// Not used in paywalls / checkout / upgrade / downgrade anymore
export const MODAL_UPGRADE_PLAN_INFO = {
  // Inbox
  [INBOX_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Collaborate as a team on conversations with leads and customers',
    descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Communicate at scale',
        titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.scaled_communication.title`,
        description: 'Manage and reply to sales and support conversations as a team.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.scaled_communication.description`,
        icon: 'upgrade/team-inbox',
      },
      {
        title: 'Streamline workflows',
        titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.streamlined_workflows.title`,
        description: 'Helpful automation to respond and increase satisfaction.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.streamlined_workflows.description`,
        icon: 'upgrade/assignment-rules-2',
      },
      {
        title: 'Powerful integrations',
        titleTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.powerful_integrations.title`,
        description: 'Integrate with 100+ apps like Slack, HubSpot and more.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.powerful_integrations.description`,
        icon: 'upgrade/integrations',
      },
    ],
    productIcon: 'products/2020/July/inbox',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_ESSENTIAL_ID],
    ],
  },
  [INBOX_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Optimize and automate your Inbox workflows',
    descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Automate',
        titleTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.automate.title`,
        description: 'Automate conversation assignment and workflows.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.automate.description`,
        icon: 'upgrade/round-robin',
      },
      {
        title: 'Connect',
        titleTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.connect.title`,
        description: 'Integrate conversations from social channels.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.connect.description`,
        icon: 'upgrade/facebook',
      },
      {
        title: 'Target',
        titleTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.target.title`,
        description: 'Control who sees the Messenger and when.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.target.description`,
        icon: 'upgrade/messenger-visibility',
      },
      {
        title: 'Integrate',
        titleTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.integrate.title`,
        description: 'Integrate with Salesforce, Zendesk, GitHub and more.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PRO_ID}.modal_upgrade_plan_info.benefits.integrate.description`,
        icon: 'upgrade/clearbit-integration',
      },
    ],
    productIcon: 'products/2020/July/inbox',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_PRO_ID],
    ],
  },
  [INBOX_PREMIUM_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.name_with_product`,
    description:
      'Build a world-class sales and support experience with SLAs, workload management, advanced customization, and more',
    descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Manage high chat volume',
        titleTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.manage_chat.title`,
        description: 'Set assignment limits and auto-route conversations based on availability.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.manage_chat.description`,
        icon: 'upgrade/teams',
      },
      {
        title: 'Set and monitor SLAs',
        titleTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.set_slas.title`,
        description: 'Monitor service-level agreements for responsiveness.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.set_slas.description`,
        icon: 'upgrade/notes',
      },
      {
        title: 'Rich reporting',
        titleTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.reporting.title`,
        description: 'Measure team performance and key revenue metrics.',
        descriptionTranslationKey: `app.lib.billing.${INBOX_PREMIUM_ID}.modal_upgrade_plan_info.benefits.reporting.description`,
        icon: 'upgrade/event-tracking',
      },
    ],
    productIcon: 'products/2020/July/inbox',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[INBOX_PREMIUM_ID],
    ],
  },
  //Messages
  [MESSAGES_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Send targeted messages to the right people at the right time',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Messaging at scale',
        titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.scale_messaging.title`,
        description: 'Nurture leads, onboard customers, announce new features and more.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.scale_messaging.description`,
        icon: 'upgrade/recent-conversations',
      },
      {
        title: 'Multichannel',
        titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.multichannel.title`,
        description:
          'Connect with customers and leads using in-app, email, website and push messages.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.multichannel.description`,
        icon: 'upgrade/multi-channel',
      },
      {
        title: 'Powerful targeting',
        titleTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.targeting.title`,
        description: 'Send highly personal messages based on behavior and custom data.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.targeting.description`,
        icon: 'upgrade/audience-targeting',
      },
    ],
    productIcon: 'products/2020/July/outbound',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_ESSENTIAL_ID],
    ],
  },
  [MESSAGES_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Optimize and automate your messaging campaigns to leads and customers',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Optimize with testing',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.optimize.title`,
        description: 'Improve your campaigns by A/B testing messaging and communication channels.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.optimize.description`,
        icon: 'upgrade/ab-testing',
      },
      {
        title: 'Orchestrate all your messages',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.orchestrate.title`,
        description: 'Connect your touchpoints to create seamless messaging experiences.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.orchestrate.description`,
        hasIcIllustrativeIcon: true,
        icon: 'series',
      },
      {
        title: 'Tailored to your brand',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.tailor.title`,
        description: 'Customize your email templates and domain to represent your brand.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PRO_ID}.modal_upgrade_plan_info.benefits.tailor.description`,
        icon: 'upgrade/custom-html-template',
      },
    ],
    productIcon: 'products/2020/July/outbound',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_PRO_ID],
    ],
  },
  [MESSAGES_PREMIUM_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Drive engagement and revenue at scale with ABM and advanced A/B testing',
    descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Win more business',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.win_business.title`,
        description: 'Convert target prospects with account-based campaigns.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.win_business.description`,
        icon: 'upgrade/total-leads',
        pending: true,
      },
      {
        title: 'Optimize performance',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.optimize.title`,
        description: 'Increase ROI on messages with advanced A/B testing.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.optimize.description`,
        icon: 'upgrade/ab-testing',
      },
      {
        title: 'Enrich and automate',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.enrich.title`,
        description: 'Integrate Salesforce sandbox, Marketo and Clearbit Reveal.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.enrich.description`,
        icon: 'upgrade/salesforce-leads',
      },
      {
        title: 'Increase data throughput',
        titleTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.throughput.title`,
        description: 'Expand usage of our APIs with custom rate limits.',
        descriptionTranslationKey: `app.lib.billing.${MESSAGES_PREMIUM_ID}.modal_upgrade_plan_info.benefits.throughput.description`,
        icon: 'upgrade/conversation-volume',
      },
    ],
    productIcon: 'products/2020/July/outbound',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[MESSAGES_PREMIUM_ID],
    ],
  },
  //Articles
  [ARTICLES_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Start a knowledge base of articles and best practices for customer self-service',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.description`,
    productId: ARTICLES_ID,
    name: 'Articles Essential',
    benefits: [
      {
        title: '24/7 customer support',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.customer_support.title`,
        description: 'Help customers help themselves by creating articles that answer FAQs.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.customer_support.description`,
        icon: 'upgrade/write-articles',
      },
      {
        title: 'Automatic article suggestions',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.auto_suggestions.title`,
        description: 'Respond to customers instantly with a bot that sends relevant articles.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.auto_suggestions.description`,
        icon: 'upgrade/smart-suggestions',
      },
      {
        title: 'Integrated knowledge base',
        titleTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.knowledge_base.title`,
        description: 'Connect your knowledge base and help desk to speed up resolutions.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_ESSENTIAL_ID}.modal_upgrade_plan_info.benefits.knowledge_base.description`,
        icon: 'upgrade/smart-suggestions-educate',
      },
    ],
    productIcon: 'products/2020/July/articles',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ARTICLES_ESSENTIAL_ID],
    ],
  },
  [ARTICLES_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.name_with_product`,
    productId: ARTICLES_ID,
    name: 'Articles Pro',
    description:
      'Help your different customers find answers quickly with multilingual & private articles',
    descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Localize your knowledge base',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.localize_knowledge_base.title`,
        description: 'Create and serve help content to customers in 38 languages.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.localize_knowledge_base.description`,
        icon: 'upgrade/translate',
      },
      {
        title: 'Control who sees your content',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.control_content.title`,
        description:
          'Keep sensitive info out of sight by creating private articles for logged-in users.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.control_content.description`,
        icon: 'upgrade/padlock',
      },
      {
        title: 'Make it easy for your team to provide consistent answers',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.consistent_answers.title`,
        description:
          'Teammates can add private or multilingual articles (coming soon) to conversations, without leaving inbox.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.consistent_answers.description`,
        icon: 'upgrade/feature-multiplemessages',
      },
      {
        title: 'Create multiple Help Centers',
        titleTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.multiple_help_centers.title`,
        description:
          'Create and customise distinct Help Centers for each of your brands in the same workspace.',
        descriptionTranslationKey: `app.lib.billing.${ARTICLES_PRO_ID}.modal_upgrade_plan_info.benefits.multiple_help_centers.description`,
        icon: 'upgrade/feature-multihelpcenters',
      },
    ],
    productIcon: 'products/2020/July/articles',
  },
  [OPERATOR_PRO_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_upgrade_plan_info.name_with_product`,
    description:
      'Qualify more leads, book more meetings and triage support questions around the clock',
    descriptionTranslationKey: `app.lib.billing.${OPERATOR_PRO_ID}.modal_upgrade_plan_info.description`,
    benefits: {},
    productId: OPERATOR_ID,
    name: 'Workflows',
    productIcon: 'upgrade/resolution-bot',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[OPERATOR_PRO_ID],
    ],
  },
  //Resolution Bot
  [ANSWER_BOT_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_upgrade_plan_info.name_with_product`,
    description: `Instantly resolve 33% of your customers' most common questions with Resolution Bot`,
    descriptionTranslationKey: `app.lib.billing.${ANSWER_BOT_ESSENTIAL_ID}.modal_upgrade_plan_info.description`,
    benefits: {},
    productId: ANSWER_BOT_ID,
    name: 'Resolution Bot',
    productIcon: 'upgrade/resolution-bot',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[ANSWER_BOT_ESSENTIAL_ID],
    ],
  },
  //Product Tours
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_upgrade_plan_info.name_with_product`,
    description: `Increase product and feature adoption by guiding customers through the steps needed to realize value faster`,
    descriptionTranslationKey: `app.lib.billing.${PRODUCT_TOURS_ESSENTIAL_ID}.modal_upgrade_plan_info.description`,
    benefits: {},
    productIcon: 'upgrade/product-tours',
  },
  [CORE_GOOD_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Reach prospects and customers with targeted email',
    descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Messaging at scale',
        titleTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.scale_messaging.title`,
        description: 'Nurture leads, onboard customers, announce new features and more.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.scale_messaging.description`,
        icon: 'upgrade/recent-conversations',
      },
      {
        title: 'Multichannel',
        titleTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.multichannel.title`,
        description:
          'Connect with customers and leads using in-app, email, website and push messages.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.multichannel.description`,
        icon: 'upgrade/multi-channel',
      },
      {
        title: 'Powerful targeting',
        titleTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.powerful_targeting.title`,
        description: 'Send highly personal messages based on behavior and custom data.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GOOD_ID}.modal_upgrade_plan_info.benefits.powerful_targeting.description`,
        icon: 'upgrade/audience-targeting',
      },
    ],
    productIcon: 'upgrade/starter',
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_GOOD_ID],
    ],
  },
  [CORE_GROW_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Scale with automated team workflows',
    descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Messaging at scale',
        titleTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.scale_messaging.title`,
        description: 'Nurture leads, onboard customers, announce new features and more.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.scale_messaging.description`,
        icon: 'upgrade/recent-conversations',
      },
      {
        title: 'Multichannel',
        titleTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.multichannel.title`,
        description:
          'Connect with customers and leads using in-app, email, website and push messages.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.multichannel.description`,
        icon: 'upgrade/multi-channel',
      },
      {
        title: 'Powerful targeting',
        titleTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.powerful_targeting.title`,
        description: 'Send highly personal messages based on behavior and custom data.',
        descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.benefits.powerful_targeting.description`,
        icon: 'upgrade/audience-targeting',
      },
    ],
    productIcon: 'upgrade/grow',
    comingSoonFeature: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.coming_soon.description`,
      badgeTranslationKey: `app.lib.billing.${CORE_GROW_ID}.modal_upgrade_plan_info.coming_soon.badge`,
    },
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_GROW_ID],
    ],
  },
  [CORE_ACCELERATE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Scale with advanced workflows and security',
    descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Collaborate as a team',
        titleTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.collaborate.title`,
        description: 'Drive faster responses with shared team inboxes.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.collaborate.description`,
        icon: 'people',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Automate team workflows',
        titleTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.automate.title`,
        description: 'Leverage powerful assignment rules to automate workflows.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.automate.description`,
        icon: 'rule',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Reduce workload with bots',
        titleTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.reduce_workload.title`,
        description: 'Automate actions with customizable conversational bots.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.reduce_workload.description`,
        icon: 'operator',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Provide global support',
        titleTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.global_support.title`,
        description: 'Support customers with a multilingual help center.',
        descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.benefits.global_support.description`,
        icon: 'collection',
        hasIcIllustrativeIcon: true,
      },
    ],
    productIcon: 'upgrade/accelerate',
    comingSoonFeature: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.coming_soon.description`,
      badgeTranslationKey: `app.lib.billing.${CORE_ACCELERATE_ID}.modal_upgrade_plan_info.coming_soon.badge`,
    },
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_ACCELERATE_ID],
    ],
  },
  [CORE_SCALE_ID]: {
    nameWithProductTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.name_with_product`,
    description: 'Scale with advanced workflows and security',
    descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.description`,
    benefits: [
      {
        title: 'Prioritize high-value conversations',
        titleTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.prioratize.title`,
        description: 'Manage conversation volume with SLAs and workload management.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.prioratize.description`,
        icon: 'upgrade/prioritize-conversations',
      },
      {
        title: 'Safeguard access',
        titleTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.safeguard.title`,
        description: 'Control Intercom access with custom role-based permissions.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.safeguard.description`,
        icon: 'upgrade/safeguard-access',
      },
      {
        title: 'Advanced security (coming soon)',
        titleTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.security.title`,
        description: 'Leverage enhanced security with SSO and SAML integrations.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.security.description`,
        icon: 'upgrade/advanced-security',
      },
      {
        title: 'Higher API limits',
        titleTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.higher_api_limits.title`,
        description: 'Customize Intercom with expanded API rate limits to suit your needs.',
        descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.benefits.higher_api_limits.description`,
        icon: 'upgrade/higher-api-limits',
      },
    ],
    productIcon: 'upgrade/scale',
    comingSoonFeature: {
      icon: 'fin',
      descriptionTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.coming_soon.description`,
      badgeTranslationKey: `app.lib.billing.${CORE_SCALE_ID}.modal_upgrade_plan_info.coming_soon.badge`,
    },
    paywallMarketingFeaturesTranslationKeys: [
      ...MARKETING_PLAN_FEATURES_TRANSLATION_KEYS[CORE_SCALE_ID],
    ],
  },
};

// Used in the modal
export const MODAL_FEATURE_INFO = {
  [ARTICLES_FEATURE]: {
    description: 'Provide a self-service help center',
    descriptionTranslationKey: 'app.lib.billing.modal_feature_info.articles_feature.description',
    benefits: [
      {
        title: 'Always on help',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.help.title',
        description: 'Provide 24/7 answers, even when your team is away',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.help.description',
        icon: 'delivery-window',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Deliver help in context',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.deliver_help.title',
        description: 'Serve content on your site, mobile app, in-product, chat and more',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.deliver_help.description',
        icon: 'notification-inapp',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Automate suggestions',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.suggestions.title',
        description: 'Use a bot to auto-send relevant articles to customers',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.suggestions.description',
        icon: 'operator',
        hasIcIllustrativeIcon: true,
      },
      {
        title: 'Integrated with Inbox',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.inbox_integration.title',
        description: 'View and share articles from Inbox for faster resolutions',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.articles_feature.benefits.inbox_integration.description',
        hasIcIllustrativeIcon: true,
        icon: 'saved-reply',
      },
    ],
    productIcon: 'products/2020/July/articles',
  },
  ['email_messages']: {
    description: 'Reach prospects and customers with targeted email',
    descriptionTranslationKey: 'app.lib.billing.modal_feature_info.email_messages.description',
    benefits: [
      {
        title: 'Automate engagement',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.email_messages.benefits.engagement.title',
        description: 'Re-engage people with automated, personalized emails',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.email_messages.benefits.engagement.description',
      },
      {
        title: 'Trigger based on behavior',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.email_messages.benefits.behavior.title',
        description: 'Increase engagement with action-based targeting',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.email_messages.benefits.behavior.description',
      },
    ],
    productIcon: 'products/2020/July/outbound',
  },
  ['post_in-app_message']: {
    description: 'Drive user action with announcement posts',
    descriptionTranslationKey: 'app.lib.billing.modal_feature_info.post_in-app_message.description',
    benefits: [
      {
        title: 'Drive awareness',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.post_in-app_message.benefits.awareness.title',
        description: 'Highlight important announcements with posts inside your product',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.post_in-app_message.benefits.awareness.description',
      },
      {
        title: 'Capture attention',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.post_in-app_message.benefits.attention.title',
        description: 'Choose different sizes for your post depending on the announcement',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.post_in-app_message.benefits.attention.description',
      },
    ],
    productIcon: 'products/2020/July/outbound',
  },
  ['note_in-app_message']: {
    description: 'Drive user action with announcement posts',
    descriptionTranslationKey: 'app.lib.billing.modal_feature_info.note_in-app_message.description',
    benefits: [
      {
        title: 'Drive awareness',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.note_in-app_message.benefits.awareness.title',
        description: 'Highlight important announcements with posts inside your product',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.note_in-app_message.benefits.awareness.description',
      },
      {
        title: 'Capture attention',
        titleTranslationKey:
          'app.lib.billing.modal_feature_info.note_in-app_message.benefits.attention.title',
        description: 'Choose different sizes for your post depending on the announcement',
        descriptionTranslationKey:
          'app.lib.billing.modal_feature_info.note_in-app_message.benefits.attention.description',
      },
    ],
    productIcon: 'products/2020/July/outbound',
  },
};

export const PRODUCTS = {
  [INBOX_ID]: {
    uniqueFeatureKey: 'assignment',
    optionClass: 'o__respond',
    liteFeatures: ['Team inboxes', 'Operator bot', 'Reporting'],

    standardFeatures: [
      'Customer satisfaction surveys',
      'Assignment rules',
      'Salesforce integration',
    ],
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    perProductDescription:
      'Manage, reply, and resolve conversations with leads and customers as a team.',
    perProductPriceMetric: 'agents',
    toggledPerProductDescription:
      'Manage and reply to conversations with leads and customers as a team.',
    toggledFeatures: [
      'Email, social, chat',
      'Operator bot',
      'Macros',
      'Assign conversations',
      'Office hours',
    ],
    toggledProFeatures: [
      'Round robin assignment',
      'Meeting scheduling',
      'Reporting',
      'Salesforce Integration',
      'Satisfaction surveys',
    ],
    longDescription:
      'Assign, reply, and close conversations across your website, in your apps, email, and social.',
    shortDescription: 'Reply to conversations at scale',
    videoId: 'phw4415oux',
    route: 'apps.app.inbox',
    modalHeaderDescription: 'Manage conversations with leads and customers at scale.',
    modalHeaderDescriptionAction: 'responding via a team inbox',
    key: 'inbox',
    isInbox: true,
  },
  [ARTICLES_ID]: {
    marketingName: 'Help Center Articles - Essential', // deprecated bare string in favour of below TranslationKey field
    marketingNameTranslationKey: 'app.lib.billing.plan_data.articles_id.marketing_name',
    nameWithProduct: 'Articles Essential',
    uniqueFeatureKey: 'help_center',
    optionClass: 'o__educate',
    liteFeatures: [
      'Public help center',
      'Custom branding',
      'Operator bot',
      'Search analytics',
      'Article reactions',
      'Smart suggestions',
    ],
    standardFeatures: [],
    productImage: `products/id-${ARTICLES_ID}-brand-refresh`,
    perProductDescription: 'Create and publish help content to a public knowledge base.',
    perProductPriceMetric: 'fixed',
    toggledPerProductDescription: 'Create and publish help content to a public knowledge base.',
    toggledFeatures: [
      'Public help center',
      'Custom branding',
      'Content importer',
      'Search analytics',
      'Article suggestions',
    ],
    toggledProFeatures: [
      'Public help center',
      'Custom branding',
      'Content importer',
      'Search analytics',
      'Article suggestions',
    ],
    longDescription: 'Help people get answers to their questions and get started with your app.',
    shortDescription: 'Create help content',
    videoId: '9ccju7h4i7',
    route: 'apps.app.articles',
    modalHeaderDescription:
      'Start a knowledge base of articles and best practices for customer self-service.',
    modalHeaderDescriptionAction: 'writing an article',
    price: 49,
    description:
      'Publish articles to a knowledge base to provide your customers with self-service support',
    features: [
      'Automatically suggest articles to customers',
      'Report on searches, views, and user feedback',
      'Import content from existing help center',
    ],
    icon: assetUrl('/assets/images/signup-teams/pricing_articles.png'),
    icon2x: assetUrl('/assets/images/signup-teams/pricing_articles@2x.png'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png'),
    id: ARTICLES_ESSENTIAL_ID,
    key: 'articles',
    marketoFormField: 'Add_On_Articles__c',
    isArticles: true,
  },
  [MESSAGES_ID]: {
    uniqueFeatureKey: 'auto_messaging',
    optionClass: 'o__engage',
    liteFeatures: ['Outbound messaging', 'Lead and user targeting', 'Message scheduling'],
    standardFeatures: ['A/B testing', 'Unbranded messaging', 'Email delivery windows'],
    productImage: `products/id-${MESSAGES_ID}-brand-refresh`,
    perProductDescription: 'Send targeted email, in-app and push messages to leads and customers.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription:
      'Send targeted email, in-app & push messages to leads & customers.',
    toggledFeatures: [
      'Auto and manual messages',
      'Audience targeting',
      'Message scheduling',
      'Trigger times',
      'Message customization',
    ],
    toggledProFeatures: [
      'A/B testing',
      'Smart campaigns',
      'Custom email templates',
      'Email delivery windows',
      'Custom branding',
    ],
    longDescription:
      'Encourage leads and users to take action with targeted email, push, and in-app messages.',
    shortDescription: 'Onboard and retain',
    videoId: 'xc97dof2ca',
    route: 'apps.app.outbound',
    modalHeaderDescription: 'Send targeted email, in-app and push messages to leads and customers.',
    modalHeaderDescriptionAction: 'writing a message',
    key: 'messages',
  },
  [OPERATOR_ID]: {
    uniqueFeatureKey: 'bot_workflows',
    optionClass: '',
    liteFeatures: [],
    standardFeatures: [],
    productImage: `products/id-${OPERATOR_ID}-brand-refresh`,
    perProductDescription:
      'Qualify more leads, book more meetings and triage support questions around the clock.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription:
      'Qualify more leads, book more meetings and triage support questions around the clock.',
    toggledProFeatures: [],
    longDescription:
      'Qualify more leads, book more meetings and triage support questions around the clock.',
    shortDescription: '',
    videoId: '2qqf1jla9f',
    route: 'apps.app.automation.workflows-overview',
    modalHeaderDescription:
      'Qualify more leads, book more meetings and triage support questions around the clock.',
    modalHeaderDescriptionAction: 'writing a message',
    key: 'operator',
  },
  [ANSWER_BOT_ID]: {
    uniqueFeatureKey: 'answer_workflows',
    optionClass: '',
    liteFeatures: [],
    standardFeatures: [],
    productImage: `products/id-${ANSWER_BOT_ID}-brand-refresh`,
    perProductDescription:
      'Start instantly resolving 33% of your customers’ most common questions with Resolution Bot.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription:
      'Start instantly resolving 33% of your customers’ most common questions with Resolution Bot.',
    toggledProFeatures: [],
    longDescription:
      'Start instantly resolving 33% of your customers’ most common questions with Resolution Bot.',
    shortDescription: '',
    videoId: 'q3y3i6o67a',
    route: 'apps.app.automation.resolution-bot.answers',
    modalHeaderDescription:
      'Start instantly resolving 33% of your customers’ most common questions with Resolution Bot.',
    modalHeaderDescriptionAction: 'writing a message',
    key: 'answer_bot',
  },
  [PRODUCT_TOURS_ID]: {
    uniqueFeatureKey: 'product_tours',
    optionClass: '',
    liteFeatures: [],
    standardFeatures: [],
    productImage: `products/id-${PRODUCT_TOURS_ID}-brand-refresh`,
    perProductDescription:
      'Increase product and feature adoption by guiding customers through the steps needed to realize value faster.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription:
      'Introduce customers to your product with guided experiences that drive adoption.',
    toggledProFeatures: [],
    longDescription:
      'Increase retention by guiding customers through the steps needed to see value faster.',
    shortDescription: 'Accelerate onboarding',
    videoId: 'nt1065g2sn',
    route: 'apps.app.tours',
    modalHeaderDescription:
      'Increase retention by guiding customers through the steps needed to see value faster.',
    modalHeaderDescriptionAction: '',
    price: '119',
    description: 'Drive adoption with interactive tours inside your product.',
    features: ['Onboard customers faster', 'Boost new feature adoption', 'Simple and code-free'],
    icon: assetUrl('/assets/images/signup-teams/pricing_tours.svg'),
    sidebarIcon: assetUrl('/assets/images/signup-teams/pricing_addon.png'),
    sidebarIcon2x: assetUrl('/assets/images/signup-teams/pricing_addon@2x.png'),
    id: PRODUCT_TOURS_ESSENTIAL_ID,
    key: 'product_tours',
  },
  [CORE_ID]: {
    optionClass: 'o__respond',
    liteFeatures: ['Team inboxes', 'Operator bot', 'Reporting'],

    standardFeatures: [
      'Customer satisfaction surveys',
      'Assignment rules',
      'Salesforce integration',
    ],
    productImage: `products/id-${CORE_ID}-brand-refresh`,
    perProductDescription:
      'Manage, reply, and resolve conversations with leads and customers as a team.',
    perProductPriceMetric: 'agents',
    toggledPerProductDescription:
      'Manage and reply to conversations with leads and customers as a team.',
    toggledFeatures: [
      'Email, social, chat',
      'Operator bot',
      'Macros',
      'Assign conversations',
      'Office hours',
    ],
    toggledProFeatures: [
      'Round robin assignment',
      'Meeting scheduling',
      'Reporting',
      'Salesforce Integration',
      'Satisfaction surveys',
    ],
    longDescription:
      'Assign, reply, and close conversations across your website, in your apps, email, and social.',
    shortDescription: 'Reply to conversations at scale',
    videoId: 'phw4415oux',
    route: 'apps.app.inbox',
    modalHeaderDescription: 'Manage conversations with leads and customers at scale.',
    modalHeaderDescriptionAction: 'responding via a team inbox',
    key: 'core',
  },
  [SUPPORT_AUTOMATION_ID]: {
    optionClass: '',
    liteFeatures: [],
    standardFeatures: [],
    productImage: `products/id-${ANSWER_BOT_ID}-brand-refresh`,
    perProductDescription: 'Deliver instant resolutions & self-service support with bots.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription: 'Deliver instant resolutions & self-service support with bots.',
    toggledProFeatures: [],
    longDescription: 'Deliver instant resolutions & self-service support with bots.',
    shortDescription: '',
    videoId: 'q3y3i6o67a',
    route: 'apps.app.automation.resolution-bot.answers',
    modalHeaderDescription: 'Deliver instant resolutions & self-service support with bots.',
    modalHeaderDescriptionAction: 'writing a message',
    key: 'answer_bot',
  },
  [LEAD_GENERATION_ID]: {
    optionClass: 'o__respond',
    liteFeatures: ['Team inboxes', 'Operator bot', 'Reporting'],

    standardFeatures: [
      'Customer satisfaction surveys',
      'Assignment rules',
      'Salesforce integration',
    ],
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    perProductDescription:
      'Manage, reply, and resolve conversations with leads and customers as a team.',
    perProductPriceMetric: 'agents',
    toggledPerProductDescription:
      'Qualify leads and book meetings with targeted chat and sales bots.',
    toggledFeatures: [
      'Email, social, chat',
      'Operator bot',
      'Macros',
      'Assign conversations',
      'Office hours',
    ],
    toggledProFeatures: [
      'Round robin assignment',
      'Meeting scheduling',
      'Reporting',
      'Salesforce Integration',
      'Satisfaction surveys',
    ],
    longDescription:
      'Assign, reply, and close conversations across your website, in your apps, email, and social.',
    shortDescription: 'Reply to conversations at scale',
    videoId: 'phw4415oux',
    route: 'apps.app.inbox',
    modalHeaderDescription: 'Manage conversations with leads and customers at scale.',
    modalHeaderDescriptionAction: 'responding via a team inbox',
    key: 'inbox',
  },
  [ADVANCED_CUSTOMER_ENGAGEMENT_ID]: {
    optionClass: 'o__engage',
    liteFeatures: ['Outbound messaging', 'Lead and user targeting', 'Message scheduling'],
    standardFeatures: ['A/B testing', 'Unbranded messaging', 'Email delivery windows'],
    productImage: `products/id-${MESSAGES_ID}-brand-refresh`,
    perProductDescription: 'Send targeted email, in-app and push messages to leads and customers.',
    perProductPriceMetric: 'maps',
    toggledPerProductDescription:
      'Activate, upsell, & retain customers with multichannel campaigns.',
    toggledFeatures: [
      'Auto and manual messages',
      'Audience targeting',
      'Message scheduling',
      'Trigger times',
      'Message customization',
    ],
    toggledProFeatures: [
      'A/B testing',
      'Smart campaigns',
      'Custom email templates',
      'Email delivery windows',
      'Custom branding',
    ],
    longDescription:
      'Encourage leads and users to take action with targeted email, push, and in-app messages.',
    shortDescription: 'Onboard and retain',
    videoId: 'xc97dof2ca',
    route: 'apps.app.outbound',
    modalHeaderDescription: 'Send targeted email, in-app and push messages to leads and customers.',
    modalHeaderDescriptionAction: 'writing a message',
    key: 'messages',
  },
  [CORE_SUPPORT_ID]: {
    productImage: 'upgrade/support',
    toggledPerProductDescription: '',
    shortDescription: '',
    standardFeatures: [],
    key: 'core',
    route: 'apps.app.inbox',
  },
  [CORE_ENGAGE_ID]: {
    productImage: 'upgrade/engage',
    toggledPerProductDescription: '',
    shortDescription: '',
    standardFeatures: [],
    key: 'core',
    route: 'apps.app.outbound',
  },
  [CORE_CONVERT_ID]: {
    productImage: 'upgrade/marketing',
    toggledPerProductDescription: '',
    shortDescription: '',
    standardFeatures: [],
    key: 'core',
    route: 'apps.app.outbound',
  },
  [WHATSAPP_ID]: {
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    toggledPerProductDescription:
      'Provide a seamless messaging experience for customers with WhatsApp',
    key: 'whatsapp',
  },
  [PREMIER_ONBOARDING_ID]: {
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    toggledPerProductDescription:
      'Leverage our Customer Onboarding Specialists to set up your workspace and team for success.',
  },
  [PREMIER_SUCCESS_ID]: {
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    toggledPerProductDescription:
      'Leverage our Customer Success Managers on a regular basis to ensure your team is getting the most out of your Intercom engagement.',
  },
  [PREMIER_SUPPORT_ID]: {
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    toggledPerProductDescription:
      'Leverage First Response Times from Intercom’s Customer Support team, additional availability, incident management and support over additional channels.',
  },
  [SURVEYS_ID]: {
    productImage: `products/id-${MESSAGES_ID}-brand-refresh`,
    toggledPerProductDescription: '',
    key: 'surveys',
    route: 'apps.app.outbound.survey',
  },
  [SWITCH_ID]: {
    productImage: `products/switch`,
    toggledPerProductDescription: '',
    key: 'switch',
    route: 'apps.app.settings.channels.switch',
  },
  [SMS_ID]: {
    productImage: `products/sms`,
    toggledPerProductDescription: '',
    key: 'sms',
    route: 'apps.app.outbound.sms',
  },
  [PREMIER_ONBOARDING_3_TIERS_ID]: {
    productImage: `products/id-${INBOX_ID}-brand-refresh`,
    toggledPerProductDescription:
      'Leverage our Customer Onboarding Specialists to set up your workspace and team for success.',
  },
};

export function getProductInfo(planId: any, prices: any, includePrice: boolean) {
  return {
    ...PLAN_DATA[planId],
    price:
      includePrice && prices.find((price: any) => price.hasSamePlans([planId]))?.formattedAmount,
  };
}

export function defaultSolutionId(planId: string) {
  switch (planId) {
    case PRICING_5_X_CORE_ESSENTIAL_ID:
      return PRICING_5_1_ESSENTIAL_SOLUTION_ID;
    case PRICING_5_X_CORE_ADVANCED_ID:
      return PRICING_5_1_ADVANCED_SOLUTION_ID;
    case PRICING_5_X_CORE_EXPERT_ID:
      return PRICING_5_1_EXPERT_SOLUTION_ID;
    default:
      throw new Error('Invalid planId provided - no solutionId available for this plan');
  }
}

export function findLatestVBP2PlanId(sortedPlanIdsByLatest: any) {
  return sortedPlanIdsByLatest.find(
    (sortedPlanIdByLatest: any) =>
      sortedPlanIdByLatest.toString() === CORE_STARTER_BASE_ID ||
      sortedPlanIdByLatest.toString() === CORE_SUPPORT_PRO_ID ||
      sortedPlanIdByLatest.toString() === CORE_SUPPORT_PREMIUM_ID,
  );
}

export function findLatestVBP2PlanData(sortedPlanIdsByLatest: any) {
  let latestTrialId = findLatestVBP2PlanId(sortedPlanIdsByLatest);
  if (latestTrialId) {
    return PLAN_DATA[latestTrialId];
  }

  return PLAN_DATA[CORE_SUPPORT_PRO_ID];
}

export function findLatestPricing5PlanId(sortedPlanIdsByLatest: any) {
  return sortedPlanIdsByLatest.find(
    (sortedPlanIdByLatest: any) =>
      sortedPlanIdByLatest.toString() === PRICING_5_X_CORE_ESSENTIAL_ID ||
      sortedPlanIdByLatest.toString() === PRICING_5_X_CORE_ADVANCED_ID ||
      sortedPlanIdByLatest.toString() === PRICING_5_X_CORE_EXPERT_ID,
  );
}

export function findLatestPricing5PlanData(sortedPlanIdsByLatest: any) {
  let latestTrialId = findLatestPricing5PlanId(sortedPlanIdsByLatest);
  if (latestTrialId) {
    return PLAN_DATA[latestTrialId];
  }

  return PLAN_DATA[PRICING_5_X_CORE_ADVANCED_ID];
}

/*
 * Note: This property, MODAL_ADD_PLAN_INFO, must not be exported directly due to the fact
 * it is stubbed in tests and the fact that ES Modules are immutable and cannot be stubbed.
 * As a result, this property is explicitly exported in the default object export for this file.
 * For more context on this, see https://github.com/intercom/embercom/pull/76174
 */
export default {
  MODAL_ADD_PLAN_INFO,
};
