/* RESPONSIBLE TEAM: team-reporting */
import FlexibleDonutChartConfig from 'embercom/lib/reporting/flexible/default-donut-chart-config';
import { type ViewConfig } from 'embercom/services/reporting-chart-service';
import { type PointLabelObject, type SeriesPieOptions } from 'highcharts';
import type Range from 'embercom/models/reporting/range';
import { SERIES_COLORS } from 'embercom/lib/reporting/flexible/serieschart-builder';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import { buildColorsForSegments } from 'embercom/lib/reporting/custom/view-config-builder-helpers';

export default class DonutChartBuilder {
  range: Range;
  viewConfig: ViewConfig;
  dataConfig: any;
  seriesColors: string[];
  width?: string;
  heightType: string;
  chartData: SeriesPieOptions[];
  chartType: string;
  formatter: any;
  app: any;

  constructor(
    range: Range,
    viewConfig: ViewConfig,
    dataConfig: any,
    width = '',
    heightType: string,
    chartData: SeriesPieOptions[],
    app: any,
    seriesColors?: string[],
  ) {
    this.range = range;
    this.viewConfig = viewConfig;
    this.dataConfig = dataConfig;
    this.seriesColors = seriesColors || SERIES_COLORS;
    this.width = width;
    this.heightType = heightType;
    this.chartData = chartData;
    this.chartType = 'pie'; //Highcharts names this as pie chart?;
    this.app = app;

    let FormatterClass: any = Formatters[this.viewConfig.formatUnit.unit as keyof Formatters];
    this.formatter = new FormatterClass(this.viewConfig.formatUnit.displayUnit);
  }
  buildTheme(): Record<string, any> {
    let config = new FlexibleDonutChartConfig(this.range.timezone);
    config.setChartType(this.chartType);
    if (this.viewConfig.heightType) {
      config.setHeight(this.heightType);
    } else {
      config.config.chart.height = null;
    }

    config.setTooltipFormatter(this.viewConfig.tooltipFormatter || this.tooltipFormatter);

    this._setConsistentColors(config);

    if (this.viewConfig.useDarkTooltips) {
      config.useDarkTooltips();
    }

    if (this.viewConfig.disableLegend) {
      config.disableLegend();
    }

    if (this.viewConfig.showDataLabels) {
      config.enableDataLabel(this.tooltipFormatter);
    }

    return config.config;
  }

  _setConsistentColors(config: FlexibleDonutChartConfig) {
    if (this.dataConfig.xAxis?.type === 'temporal') {
      config.setColors(this.seriesColors);
    } else {
      buildColorsForSegments(this.chartData[0].data, this.dataConfig.xAxis?.data.property);
    }
  }

  get tooltipFormatter() {
    let formatter = this.formatter;
    let showPercentages = this.viewConfig.showPercentages;

    return function (this: PointLabelObject) {
      let descriptionPart = this.point.name; // dates already come in form of (Jan 1 - Jan 7)

      let valuePart = '';
      if (this.y) {
        valuePart = formatter.formatTooltip(this.y, undefined, this.series.name);
        if (showPercentages) {
          valuePart = ` ${Math.round(this.percentage * 100) / 100}% (${valuePart})`;
        }
      }

      let cssClass = 'reporting__highcharts-tooltip';

      if (descriptionPart) {
        return `<div class='${cssClass}'><strong>${valuePart}</strong><br/>(${descriptionPart})</div>`;
      } else {
        return `<div class='${cssClass}'><strong>${valuePart}</strong></div>`;
      }
    };
  }
}
