/* RESPONSIBLE TEAM: team-channels */

export const statuses = {
  active: 'active',
  inactive: 'inactive',
  beta: 'beta',
  installed: 'installed',
};

export const INBOUND_EMAIL_SUPPORTED_TAGS = [
  'a',
  'abbr',
  'b',
  'bdo',
  'blockquote',
  'br',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'dd',
  'del',
  'dfn',
  'div',
  'dl',
  'dt',
  'em',
  'figcaption',
  'figure',
  // 'font', // Temporarily disabled due to https://github.com/intercom/intercom/issues/304886
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'hr',
  'i',
  'img',
  'ins',
  'kbd',
  'li',
  'mark',
  'ol',
  'p',
  'pre',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strike',
  'strong',
  'sub',
  'sup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'time',
  'tr',
  'u',
  'ul',
  'var',
  'wbr',
];

export const INBOUND_EMAIL_SUPPORTED_ATTRIBUTES = {
  all: ['dir', 'height', 'lang', 'title', 'width', 'style'],
  a: ['href', 'name', 'target'],
  blockquote: ['cite'],
  col: ['span', 'valign'],
  colgroup: ['span', 'valign'],
  del: ['cite', 'datetime'],
  font: ['face', 'size'],
  img: ['align', 'alt', 'src', 'title', 'height'],
  ins: ['cite', 'datetime'],
  ol: ['reversed', 'start', 'type'],
  q: ['cite'],
  table: ['align', 'border', 'cellpadding', 'cellspacing', 'summary'],
  tbody: ['valign'],
  td: ['abbr', 'align', 'axis', 'colspan', 'rowspan', 'valign'],
  th: ['abbr', 'axis', 'colspan', 'rowspan', 'scope', 'valign'],
  thead: ['valign'],
  time: ['datetime', 'pubdate'],
  ul: ['type'],
};

export const EMAIL_FORWARDING_FEEDBACK_SURVEY = 42802600;
